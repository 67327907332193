import React from 'react';
import {List} from 'immutable';
import {useBreakpoints} from 'modern-famly';

import {type IEmployee} from 'signin-app/entities/staff/model';
import {type IChild} from 'signin-app/entities/children/model';
import {type PinChild, type PinEmployee} from 'signin-app/pin/models';
import {type RecordInstance} from 'web-app/react/entities/factory/reducer-factory';
import generateUUID from 'web-app/util/uuid';
import {
    GRID_COLUMN_GAP,
    GRID_PADDING_HORIZONTAL,
    GRID_ROW_GAP,
    MIN_GRID_CARD_SIZE,
} from 'signin-app/components/group-grid';

const CARD_HEIGHT_SMALL = 136;
const CARD_HEIGHT_LARGE = 156;

export const useElementDimensions = () => {
    const [dimensions, setDimensions] = React.useState({
        width: 0,
        height: 0,
    });

    const previousObserver = React.useRef<ResizeObserver | null>(null);

    const ref = React.useCallback((node: SVGElement | HTMLElement | null) => {
        if (previousObserver.current) {
            previousObserver.current.disconnect();
            previousObserver.current = null;
        }

        if (node?.nodeType === Node.ELEMENT_NODE) {
            const observer = new ResizeObserver(([entry]) => {
                if (entry && entry.borderBoxSize) {
                    const {inlineSize: width, blockSize: height} = entry.borderBoxSize[0];

                    setDimensions({width, height});
                } else if (entry && entry.contentRect) {
                    // Fallback for browsers that don't support borderBoxSize
                    setDimensions({
                        width: entry.contentRect.width,
                        height: entry.contentRect.height,
                    });
                }
            });

            observer.observe(node);
            previousObserver.current = observer;
        }
    }, []);

    return {ref, dimensions};
};

type Props = {
    items: List<
        RecordInstance<IChild> | RecordInstance<PinChild> | RecordInstance<IEmployee> | RecordInstance<PinEmployee>
    >;
    width: number;
    height: number;
};

export const useGetPages = (props: Props) => {
    const {items, width, height} = props;
    const {isTabletPortraitAndLarger} = useBreakpoints();
    const cardHeight = React.useMemo(
        () => (isTabletPortraitAndLarger ? CARD_HEIGHT_LARGE : CARD_HEIGHT_SMALL),
        [isTabletPortraitAndLarger],
    );

    const pages = React.useMemo(() => {
        const numColumns = Math.floor((width - GRID_PADDING_HORIZONTAL) / (MIN_GRID_CARD_SIZE + GRID_COLUMN_GAP));
        const numRows = Math.floor(height / (cardHeight + GRID_ROW_GAP));
        const maxNumOfCards = numColumns * numRows;
        const maxLength = Math.ceil(items.size / maxNumOfCards);
        return maxNumOfCards
            ? Array.from({length: maxLength}, (_, index) => {
                  const pageId = generateUUID();
                  const _items = List(items.slice(index * maxNumOfCards, (index + 1) * maxNumOfCards));

                  return {
                      pageId,
                      items: _items,
                  };
              })
            : [];
    }, [cardHeight, height, items, width]);

    return {
        pages,
    };
};
