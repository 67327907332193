import React from 'react';
import styled, {css, keyframes} from 'styled-components';

// eslint-disable-next-line no-restricted-syntax
export enum CheckmarkColor {
    default = 'default',
    white = 'white',
}
interface InputProps {
    animateCircle?: boolean;
    className?: string;
    color?: CheckmarkColor;
}

const StyledSVG = styled('svg')<{color?: CheckmarkColor}>`
    border-radius: 50%;
    display: block;
    stroke-width: 4px;
    stroke: #999;
    stroke-miterlimit: 10;
    box-shadow: inset 0 0 0 30px transparent;
    height: 100%;
    width: 100%;

    ${props =>
        props.color === CheckmarkColor.white
            ? css`
                  stroke: white;
              `
            : ''}
`;

const StrokeAnimation = keyframes`
    100% {
        stroke-dashoffset: 0;
    }
`;
const StyledCircle = styled('circle')<{animateCircle?: boolean}>`
    stroke-dasharray: 166;
    stroke-dashoffset: 166px;
    stroke-width: 4px;
    stroke-miterlimit: 10;
    stroke: transparent;
    fill: none;
    height: 100%;
    width: 100%;

    ${props =>
        props.animateCircle
            ? css`
                  animation: ${StrokeAnimation} 0.3s ${props => props.theme.timings.functions.default} 0.2s forwards;
              `
            : ''}
`;

const StyledCheckmark = styled.path`
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48px;
    animation: ${StrokeAnimation} 0.3s ${props => props.theme.timings.functions.default} 0.2s forwards;
`;

const AnimatedCheckmark: React.FC<React.PropsWithChildren<InputProps>> = props => {
    return (
        <StyledSVG
            color={props.color}
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
        >
            <StyledCircle animateCircle={props.animateCircle} cx="26" cy="26" r="25" fill="none" />
            <StyledCheckmark fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </StyledSVG>
    );
};

export default AnimatedCheckmark;
