import React from 'react';
import moment from 'moment-timezone';
import {Box} from 'modern-famly';

import {compose} from 'web-app/util/typescript';
import {connectState} from 'signin-app/helpers/connect-state';
import {type RootState} from 'signin-app/redux/main-reducer';
import lifecycle from 'web-app/react/components/higher-order/lifecycle';
import {getFormattedTime} from 'signin-app/helpers/time';
import {ListTimes} from 'signin-app/components/list-times/list-times';
import * as GroupsSelectors from 'signin-app/groups/selectors';

import * as Selectors from './selectors';
import {selectLeaveHours, selectLeaveMinutes} from './actions';

interface StateProps {
    timezone: string;
    selectedHours?: number;
    selectedMinutes?: number;
    checkoutTimeOptions: ReturnType<typeof Selectors.checkoutTimeOptions>;
}

interface LeaveTimeProps {
    selectedLeaveTime: string | null;
    checkedIn: boolean;
}

type Props = StateProps & LeaveTimeProps;

const LeaveTime: React.FC<Props> = props => {
    const {selectedHours, selectedMinutes, checkoutTimeOptions} = props;

    return (
        <Box>
            <ListTimes
                times={checkoutTimeOptions}
                selectedHours={selectedHours}
                selectedMinutes={selectedMinutes}
                displayMinutes={getFormattedTime}
                onHoursChange={selectLeaveHours.dispatch}
                onMinutesChange={selectLeaveMinutes.dispatch}
                e2eHourUnit="employee-leave-hour-unit"
                isEmployee={true}
            />
        </Box>
    );
};

const mapStateToProps = (state: RootState): StateProps => ({
    selectedHours: Selectors.selectedHours(state),
    selectedMinutes: Selectors.selectedMinutes(state),
    timezone: GroupsSelectors.timezone(state),
    checkoutTimeOptions: Selectors.checkoutTimeOptions(state),
});

const initializeFromProps = (props: Props) => {
    const {selectedLeaveTime, checkedIn, timezone} = props;

    if (selectedLeaveTime && checkedIn && timezone) {
        const leaveTime = moment(selectedLeaveTime).tz(timezone);
        selectLeaveHours.dispatch(leaveTime.hour());
        selectLeaveMinutes.dispatch(leaveTime.minute());
    }
};

export default compose(
    connectState<LeaveTimeProps, StateProps, React.ComponentType<LeaveTimeProps & StateProps>>(mapStateToProps),
)
    .with(lifecycle(initializeFromProps))
    .apply(LeaveTime);
