import {Stack} from 'modern-famly';
import React from 'react';
import styled, {css} from 'styled-components';

import {getSpacing, s3, s2, s1} from 'web-app/styleguide/spacing';
import {Base, type BaseProps} from 'web-app/react/components/layout/layout';
import {Caption} from 'web-app/react/components/text/text';
import IconSwitcher from 'web-app/react/components/icons/legacy/ember-icon-switcher';

interface Legend {
    title: string | React.ReactNode;
    color?: string;
    colorSecondary?: string;
    // Ideally icon should be typed as IconName | React.ReactNode but we can't type the icon names dynamically
    icon?: string | React.ReactNode;
}

interface Props extends BaseProps {
    legends: Legend[];
    horizontal?: boolean;
}

const LegendLeft = styled.div`
    height: 12px;
    width: 12px;
    margin-right: ${getSpacing(s2)};
    position: relative;
    flex-shrink: 0;
`;

/*
    The background has important tags to allow printing the colors
 */
export const LegendColor = styled.div<{legend: Legend}>`
    height: 100%;
    width: 100%;
    border-radius: 3px;
    ${props =>
        props.legend.colorSecondary
            ? css`
                  background-image: linear-gradient(
                      135deg,
                      ${props.legend.colorSecondary} 0%,
                      ${props.legend.colorSecondary} 49.9%,
                      ${props.legend.color} 50%,
                      ${props.legend.color} 100%
                  ) !important;
              `
            : css`
                  background-color: ${props.legend.color} !important;
              `}
`;

export const LegendItem = styled(Base)<BaseProps & {horizontal?: boolean}>`
    ${props =>
        props.horizontal
            ? css`
                  margin: ${getSpacing(s3)};
              `
            : css`
                  ${LegendItem} + && {
                      margin-top: ${getSpacing(s1)};
                  }
              `}
`;

const Icon = styled(IconSwitcher)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const LegendWrapper = styled(Base)<BaseProps & {horizontal?: boolean}>`
    ${props =>
        props.horizontal
            ? css`
                  display: flex;
                  flex-wrap: wrap;
              `
            : ''}
`;

export const LegendRenderer: React.FC<React.PropsWithChildren<{legend: Legend; horizontal?: boolean}>> = ({
    legend,
    horizontal,
}) => (
    <LegendItem horizontal={horizontal}>
        <Stack alignItems="center">
            <LegendLeft>
                {legend.color ? <LegendColor legend={legend} /> : null}
                {legend.icon && typeof legend.icon === 'string' ? (
                    <Icon size={20} icon={legend.icon} transparent />
                ) : null}
                {legend.icon && typeof legend.icon !== 'string' ? legend.icon : null}
            </LegendLeft>
            {typeof legend.title === 'string' ? <Caption secondary>{legend.title}</Caption> : legend.title}
        </Stack>
    </LegendItem>
);

const Legend: React.FC<React.PropsWithChildren<Props>> = ({legends, horizontal, ...rest}) => (
    <LegendWrapper horizontal={horizontal} {...rest}>
        {legends.map((legend, i) => (
            <LegendRenderer key={i} legend={legend} horizontal={horizontal} />
        ))}
    </LegendWrapper>
);

export default Legend;
