import React from 'react';
import {Box, Stack} from 'modern-famly';
import styled from 'styled-components';

import {Initials} from './initials';

export const IMAGE_PROPS = {
    sm: {size: '64px', borderRadius: '16px'},
    md: {size: '80px', borderRadius: '20px'},
    lg: {size: '96px', borderRadius: '24px'},
    xl: {size: '108px', borderRadius: '32px'},
};

const StyledImage = styled.img<{borderRadius?: string}>`
    width: 100%;
    border-radius: ${props => props.borderRadius};
    overflow: hidden;
`;

export type ImageSize = 'sm' | 'md' | 'lg' | 'xl';

interface PersonImageProps {
    image: string | undefined;
    initials: string;
    hasOverlay?: boolean;
    size?: ImageSize;
}

const Overlay = () => {
    return <Box position="absolute" backgroundColor="n500" opacity={0.3} height="100%" width="100%" />;
};

export const PersonImage: React.FC<PersonImageProps> = ({image, initials, hasOverlay, size = 'md'}) => {
    return (
        <Stack
            position="relative"
            width={IMAGE_PROPS[size].size}
            height={IMAGE_PROPS[size].size}
            flexShrink={0}
            borderRadius={IMAGE_PROPS[size].borderRadius}
            overflow="hidden"
        >
            {hasOverlay && <Overlay />}
            {image ? (
                <StyledImage src={image} />
            ) : (
                <Initials
                    compact={size === 'sm' || size === 'md'}
                    initials={initials}
                    wrapperProps={{
                        borderRadius: IMAGE_PROPS[size].borderRadius,
                    }}
                />
            )}
        </Stack>
    );
};
