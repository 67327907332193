export const makeInputSize = size => Math.round(SIZE_MAP[size] * (20 / 24));
export const makeMarginSize = size => (SIZE_MAP[size] - makeInputSize(size)) / 2;

// eslint-disable-next-line no-restricted-syntax
export enum SIZES {
    small = 'small',
    regular = 'regular',
    large = 'large',
}

export const SIZE_MAP = {
    [SIZES.small]: 20,
    [SIZES.regular]: 20,
    [SIZES.large]: 24,
};
