import {Stack} from 'modern-famly';
import React, {type ReactNode} from 'react';
import {FormSection} from 'redux-form';
import styled, {css} from 'styled-components';

import {Flex, type FlexProps} from 'web-app/react/components/layout/layout';
import {Body, Subheader} from 'web-app/react/components/text/text';
import Arrow from 'web-app/react/components/icons/legacy/arrows';
import {s1, s2, s4} from 'web-app/styleguide/spacing';
import {getProperty} from 'web-app/util/typescript';

const SectionSubtitle = styled(Body)`
    margin-top: 4px;
    margin-bottom: 4px;
`;

const StyledSection = styled.div<{css: ReturnType<typeof css> | undefined}>`
    ${props => (props.css ? props.css : '')};
`;

const RightSectionElement = styled.div`
    margin-left: 12px;
`;

export const AfterTitleElement = styled.div`
    margin-left: 8px;
`;

export const SectionTitle = styled(Flex)<FlexProps>`
    border-bottom: 'none';

    ${props =>
        props.onClick
            ? css`
                  cursor: pointer;

                  &:hover {
                      background: ${props => props.theme.backgroundHover};
                  }
              `
            : ''}
`;

const ExpandArrow = styled(Arrow)`
    vertical-align: middle;
`;

interface SectionProps {
    name?: string;
    className?: string;
    id?: string;
    title: ReactNode;
    subtitle?: string;
    rightTitleElement?: ReactNode;
    afterTitleElement?: ReactNode;
    addHeaderMargin?: boolean;
    collapsible?: boolean;
    css?: ReturnType<typeof css>;
    isInitiallyExpanded?: boolean;
    'data-e2e-id'?: string;
}

type Props = SectionProps;

interface State {
    expanded: boolean;
}

class Section extends React.Component<React.PropsWithChildren<Props>, State> {
    constructor(props) {
        super(props);

        this.state = {
            expanded: props.collapsible ? Boolean(props.isInitiallyExpanded) : true,
        };

        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    public render() {
        const {
            className,
            title,
            subtitle,
            children,
            rightTitleElement,
            name,
            afterTitleElement,
            addHeaderMargin,
            collapsible,
            css,
        } = this.props;

        const {expanded} = this.state;

        const content = (
            <StyledSection data-e2e-id={getProperty(this.props, 'data-e2e-id')} className={className} css={css}>
                <SectionTitle
                    marginTop={s4}
                    paddingTop={s1}
                    paddingBottom={s1}
                    marginBottom={addHeaderMargin ? s2 : undefined}
                    align="center"
                    justify="space-between"
                    onClick={collapsible ? this.toggleExpanded : undefined}
                >
                    <Stack alignItems="center">
                        <Subheader emphasized>{title}</Subheader>
                        {afterTitleElement ? <AfterTitleElement>{afterTitleElement}</AfterTitleElement> : null}
                    </Stack>
                    {rightTitleElement || collapsible ? (
                        <RightSectionElement>
                            {rightTitleElement}
                            {collapsible ? <ExpandArrow size={24} direction={expanded ? 'south' : 'east'} /> : null}
                        </RightSectionElement>
                    ) : null}
                </SectionTitle>
                {subtitle ? <SectionSubtitle>{subtitle}</SectionSubtitle> : null}
                <div>{expanded ? children : null}</div>
            </StyledSection>
        );

        if (name) {
            return <FormSection name={name}>{content}</FormSection>;
        }
        return content;
    }

    private toggleExpanded() {
        this.setState({
            expanded: !this.state.expanded,
        });
    }
}

export default Section;
