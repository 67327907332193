export interface AmPmHours {
    amPm: 'am' | 'pm';
    hours: number;
}
// converts military time to AM/PM
export const convertToAmPmHours = (hours: number): AmPmHours => {
    const amPm = hours >= 12 ? 'pm' : 'am';
    const hoursAmPm = hours % 12;

    return {
        amPm,
        hours: hoursAmPm ? hoursAmPm : 12,
    };
};

const addLeadingZero = (timeUnit: number) => {
    return `0${timeUnit}`.slice(-2);
};

export interface Time {
    time: string;
    fullTime: string;
    amPm: string;
    minutes: string;
}

export const getFormattedTime = (
    hours: number = 0,
    minutes: number = 0,
    locale: string,
    leadingHourZero?: boolean,
): Time => {
    const amPmHours = convertToAmPmHours(hours);

    const localeHours = locale === 'en_US' ? amPmHours.hours : hours;
    const formattedHours = leadingHourZero ? addLeadingZero(localeHours) : localeHours;

    const formattedMinutes = addLeadingZero(minutes);

    const formmatedTime = `${formattedHours}:${formattedMinutes}`;
    const amPmResult = locale === 'en_US' ? amPmHours.amPm : '';

    return {
        time: formmatedTime,
        fullTime: `${formmatedTime}${amPmResult}`,
        amPm: amPmResult,
        minutes: formattedMinutes,
    };
};
