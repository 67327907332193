import React from 'react';
import {Box, Text} from 'modern-famly';
import {useDispatch} from 'react-redux';
import moment from 'moment-timezone';

import {useTypedSelector} from 'signin-app/components/hooks';
import * as GlobalEventActions from 'signin-app/global-event/actions';
import * as ChildCheckinActions from 'signin-app/child/check-in/actions';
import * as LoginSelectors from 'signin-app/login/selectors';
import * as ChildSelectors from 'signin-app/child/selectors';
import {Card, CardsContainer} from 'signin-app/groups/group-item';
import translate from 'signin-app/helpers/translate';
import * as GroupsSelectors from 'signin-app/groups/selectors';
import {ActionOnClickButton, BottomBarContainer} from 'signin-app/components/action-buttons';

import {useCheckOutChild} from './check-out-child-context';

export const RoomSwitcher = () => {
    const {child, displayName} = useCheckOutChild();

    const childId = child.id;
    const dispatch = useDispatch();
    const timezone = useTypedSelector(GroupsSelectors.timezone);
    const groups = useTypedSelector(GroupsSelectors.groups);
    const accessToken = useTypedSelector(LoginSelectors.accessToken);
    const currentChildGroupId = useTypedSelector(state => ChildSelectors.currentChildGroupId(state, {id: childId}));
    const [selectedRoomId, setSelectedRoomId] = React.useState(() => currentChildGroupId);

    const selectedRoom = React.useMemo(() => {
        return groups.find(group => group.groupId === selectedRoomId);
    }, [groups, selectedRoomId]);

    const handleChildSwitchRoom = React.useCallback(() => {
        if (child.lastCheckIn?.pickupTime && timezone) {
            const parsedPickupTime = moment(child.lastCheckIn.pickupTime).tz(timezone);
            const currentTime = moment.tz(timezone);

            if (parsedPickupTime < currentTime) {
                dispatch(GlobalEventActions.updateError.action(translate('pickupBeforeNowError'), false, 6000));
                return;
            }
        }

        if (child && child.lastCheckIn && timezone) {
            const successMessage = translate('hasMovedRoom', {personName: displayName});
            const parsedPickupTime = child.lastCheckIn.pickupTime
                ? moment(child.lastCheckIn.pickupTime).tz(timezone)
                : null;
            dispatch(
                ChildCheckinActions.checkInChild.action({
                    accessToken,
                    childId: child.id,
                    pickupRelationId: child.lastCheckIn.pickupRelationId,
                    selectedHours: parsedPickupTime ? parsedPickupTime.hours() : null,
                    selectedMinutes: parsedPickupTime ? parsedPickupTime.minutes() : null,
                    successMessage,
                    groupId: selectedRoomId,
                }),
            );
        }
    }, [selectedRoomId, child, timezone, accessToken, dispatch, displayName]);

    return (
        <>
            <Box mx={4} my={3}>
                <Text variant="h6" color="n400" emphasized>
                    {translate('moveToRoomQuestion', {childName: child.name.firstName})}
                </Text>
                <CardsContainer mt={2}>
                    {groups.map(group => (
                        <Card
                            alignment="vertical"
                            key={group.groupId}
                            image={group.image.large}
                            displayTitle={group.title}
                            onClick={() => setSelectedRoomId(group.groupId)}
                            selected={group.groupId === selectedRoomId}
                        />
                    ))}
                </CardsContainer>
            </Box>
            <BottomBarContainer>
                <ActionOnClickButton
                    backgroundColor="g400"
                    color="n0"
                    borderColor="g400"
                    text={`Move ${displayName} to ${selectedRoom?.title}`}
                    onClick={handleChildSwitchRoom}
                />
            </BottomBarContainer>
        </>
    );
};
