import React from 'react';
import {type RouteComponentProps} from 'react-router-dom';
import {Text, Box, Stack, useBreakpoints} from 'modern-famly';
import {useParams} from 'react-router-dom';

import translate from 'signin-app/helpers/translate';
import {accessToken, isPinApp} from 'signin-app/login/selectors';
import {limitedData} from 'signin-app/child/check-out/selectors';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {PinHeader} from 'signin-app/child/pin-header';
import {PageBodyContainer} from 'signin-app/components/action-buttons';
import PersonSelect from 'signin-app/components/person-select';
import Header from 'signin-app/components/header';
import {useTypedSelector} from 'signin-app/components/hooks';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {HeaderPerson} from 'signin-app/components/header-person';
import * as EmployeeSelectors from 'signin-app/employee/selectors';
import {ChildOverview} from 'signin-app/child/check-out/child-overview';
import {hasValue} from 'web-app/util/typescript';
import {useQueryParams} from 'web-app/react/hooks/use-query-params';
import {PageContainer} from 'signin-app/components/containers';
import {selectedPickupRelationId} from 'signin-app/child/check-in/selectors';
import {HeaderChild} from 'signin-app/components/header-child';
import {getInitials} from 'signin-app/util/get-initials';
import {type PinChild} from 'signin-app/pin/models';
import {type RecordInstance} from 'web-app/react/entities/factory/reducer-factory';
import {type IChild} from 'signin-app/entities/children/model';
import {useBindDispatch} from 'web-app/react/hooks/use-bind-dispatch';

import ChildRelations from './child-relations';
import {GoingHomeWith} from './going-home-with';
import PickupTime from './pickup-time';
import {CheckinSubmit} from './checkin-submit';
import {AddPickupPerson} from './add-pickup-person';
import * as ChildSelectors from '../selectors';
import * as Selectors from './selectors';
import * as Actions from './actions';
import * as ChildActions from '../actions';

const ChildInfo = ({child}: {child: PinChild | RecordInstance<IChild>}) => {
    const isDataLimited = useTypedSelector(limitedData);

    const childName = React.useMemo(
        () => (isDataLimited ? child.name.firstName : child.name.fullName),
        [child.name.firstName, child.name.fullName, isDataLimited],
    );
    const initials = React.useMemo(() => getInitials(childName), [childName]);

    return <HeaderPerson image={child.image.small} initials={initials} name={childName} />;
};

type CheckInRouteProps = RouteComponentProps<{childId: string}>;

export const ChildCheckIn = (props: CheckInRouteProps) => {
    const {update} = useQueryParams(['update']);
    const isUpdatePage = hasValue(update);
    const pickupTimeRef = React.useRef<HTMLDivElement | null>(null);
    const [shouldScrollDown, setShouldScrollDown] = React.useState(true);
    const {isTabletPortraitAndLarger} = useBreakpoints();

    const isFetching = useTypedSelector(ChildSelectors.isFetching);
    const child = useTypedSelector(state => Selectors.child(state, props));
    const token = useTypedSelector(accessToken);
    const isDataLimited = useTypedSelector(limitedData);
    const canSelectOtherChildsParent = useTypedSelector(state =>
        ChildSelectors.canSelectOtherChildsParent(state, props),
    );
    const {childId: childIdFromParams} = useParams<{childId: string}>();
    const requirePickupInfo = useTypedSelector(state =>
        ChildSelectors.requirePickupInfo(state, {childId: childIdFromParams}),
    );
    const showPickupInfoScreen = useTypedSelector(state => Selectors.showPickupInfoScreen(state, props));
    const pinApp = useTypedSelector(isPinApp);
    const pickupText = !requirePickupInfo ? translate('optionalPickupText') : translate('whoPicksUp');
    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));
    const pinChildren = useTypedSelector(ChildSelectors.pinChildren);
    const pinEmployees = useTypedSelector(EmployeeSelectors.pinEmployees);
    const selectedPickupPersonId = useTypedSelector(selectedPickupRelationId);
    const fetchChildAction = useBindDispatch(ChildActions.fetchChild);
    const resetPickupAction = useBindDispatch(Actions.resetPickup);

    React.useEffect(() => {
        if (!pinApp) {
            fetchChildAction(token, childIdFromParams);
        }
        return () => {
            resetPickupAction();
        };
    }, [childIdFromParams, fetchChildAction, pinApp, resetPickupAction, token]);

    // Show child info header only if PersonSelect will not be shown
    const shouldShowChildInfoOnPin = React.useMemo(() => {
        if (!pinApp || (pinEmployees && pinEmployees.size > 0) || (pinChildren && pinChildren.size > 1)) {
            return false;
        }
        return true;
    }, [pinApp, pinChildren, pinEmployees]);

    const scrollDown = React.useCallback(() => {
        if (pickupTimeRef.current && shouldScrollDown) {
            pickupTimeRef.current.scrollIntoView({behavior: 'smooth'});
            setShouldScrollDown(false);
        }
    }, [shouldScrollDown]);

    if (isFetching || !child) {
        return (
            <PageContainer>
                {pinApp ? <PinHeader whitelabelConfiguration={whitelabelConfiguration} /> : <Header />}
                <Spinner centered />
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            {pinApp ? (
                <PinHeader whitelabelConfiguration={whitelabelConfiguration} />
            ) : (
                <Header centerElement={<HeaderChild child={child} />} />
            )}
            <Box>
                {showPickupInfoScreen || isUpdatePage ? (
                    <>
                        <PageBodyContainer mt={16}>
                            {shouldShowChildInfoOnPin && (
                                <Stack justifyContent="center" mb={14}>
                                    <ChildInfo child={child} />
                                </Stack>
                            )}

                            <PersonSelect activeChildId={childIdFromParams} />
                            <Stack flexDirection={{base: 'column', tabletLandscape: 'row'}} gap={12}>
                                <Box flex={1}>
                                    <Text variant="h6" color="n400" emphasized>
                                        {pickupText}
                                    </Text>
                                    <ChildRelations
                                        selectedRelationId={child.pickupRelationId}
                                        checkedIn={child.checkedIn}
                                        childId={child.id}
                                        handleRelationClick={scrollDown}
                                    >
                                        {canSelectOtherChildsParent ? <GoingHomeWith child={child} /> : null}
                                        {!pinApp ? (
                                            <AddPickupPerson
                                                childName={child.name.firstName}
                                                isExistingRelationSelected={Boolean(selectedPickupPersonId)}
                                                size={isTabletPortraitAndLarger ? 'lg' : 'md'}
                                            />
                                        ) : null}
                                    </ChildRelations>
                                </Box>
                                <Box flex={1}>
                                    <div ref={pickupTimeRef}>
                                        <PickupTime />
                                    </div>
                                </Box>
                            </Stack>
                        </PageBodyContainer>
                        <CheckinSubmit accessToken={token} child={child} isUpdatePage={isUpdatePage} />
                    </>
                ) : (
                    <ChildOverview child={child} limitedData={isDataLimited} />
                )}
            </Box>
        </PageContainer>
    );
};
