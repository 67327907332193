import {css} from 'styled-components';

import {getSpacing, s2, s4, s6} from 'web-app/styleguide/spacing';
import * as Breakpoints from 'web-app/styleguide/breakpoint-helpers';

/**
 * Provides reusable Elevation styling for cases in which it doesn't make sense
 * to use the TableContainer component
 */
export const ContainerElevationStyles = css`
    background-color: ${props => props.theme.invertedText};
    border-radius: ${props => props.theme.containerConfiguration.borderRadius};
    border: 1px solid ${props => props.theme.mf.colorPalette.n50};
    box-shadow: ${props => props.theme.elevation.one};
    padding: ${getSpacing(s2)};
`;

export const ElevationContainerPaddingStyles = css`
    ${Breakpoints.mobileLandscapeAndLarger`
        padding: ${getSpacing(s4)};
    `}

    ${Breakpoints.tabletPortraitAndLarger`
        padding: ${getSpacing(s6)};
    `}
`;

/**
 * Provides nice scrollbar styles for Firefox and Chrome
 */
export const ScrollbarStyles = css`
    &::-webkit-scrollbar {
        background: transparent;
        height: 8px;
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        border: none;
        box-shadow: none;
        background: #dadce0;
        border-radius: 6px;
        min-height: 8px;
    }

    scrollbar-color: #dadce0 transparent;
    scrollbar-width: thin;
`;

/**
 * Exact same properties as above, but in a format that
 * react-select v3 supports.
 */
export const ScrollbarStylesForReactSelect = {
    '::-webkit-scrollbar': {
        background: 'transparent',
        height: '8px',
        width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
        border: 'none',
        boxShadow: 'none',
        background: '#dadce0',
        borderRadius: '6px',
        minHeight: '8px',
    },
    scrollbarColor: '#dadce0 transparent',
    scrollbarWidth: 'thin' as const,
};
