/* eslint-disable default-case */
/* eslint-disable consistent-return */
import React from 'react';
import moment, {type Moment} from 'moment-timezone';
import i18next from 'i18next';
import {type DayPickerProps, type Modifiers} from 'react-day-picker';
import styled, {css} from 'styled-components';

import {compose, hasValue} from 'web-app/util/typescript';
import formPrepare from 'web-app/react/components/higher-order/form-prepare';
import deriveProps from 'web-app/react/components/higher-order/derive-props';
import TextField from 'web-app/react/components/form-elements/text-field/text-field';
import responsiveState from 'web-app/react/components/higher-order/responsive-state';
import {waitForFrames} from 'web-app/util/animation';
import Button, {ButtonAppearance, ButtonSize} from 'web-app/react/components/form-elements/button/button';
import Close from 'web-app/react/components/icons/legacy/lazy-icons/close';
import {Body, Caption} from 'web-app/react/components/text/text';
import {Colors} from 'web-app/styleguide';
import {getDateValidationRegex} from 'web-app/setup/moment-setup';
import LocalStorageService from 'web-app/services/local-storage';
import {PortalModal} from 'web-app/react/components/modals/modals';
import {media} from 'web-app/styleguide/utils';
import PlatformHelper from 'web-app/helpers/platform-helper';
import {getSpacing, s6} from 'web-app/styleguide/spacing';
import {getElementGeometry} from 'web-app/util/layout';
import Portal from 'web-app/react/components/portals/portal';
import {PortalRootId} from 'web-app/react/components/portals/constants';

import DayPicker, {Day} from './daypicker';
import * as Constants from './constants';
import * as Selectors from './selectors';
import {dayPickerInputPortalContentClassName} from './day-picker-input-portal-class-name';

type DisabledDayFunction = (day: Date) => boolean;
type DisabledDays = Date | Date[] | DisabledDayFunction;
type MaybeMoment = string | Moment;
const dateIsInDisabledDays = (date: Date | Moment, disabledDays?: DisabledDays) => {
    if (!disabledDays) {
        return false;
    }

    if (typeof disabledDays === 'function') {
        return disabledDays(moment.isMoment(date) ? date.toDate() : date);
    } else if (disabledDays instanceof Array) {
        return disabledDays.some(disabledDay => dateIsInDisabledDays(date, disabledDay));
    } else if (disabledDays instanceof Date) {
        return moment(date).isSame(disabledDays, 'days');
    }
};
interface ValidationError {
    isDisabled: boolean;
    beforeMin: boolean;
    afterMax: boolean;
}
const validationErrorToString = (error: ValidationError, dateFormat: string, min?: MaybeMoment, max?: MaybeMoment) => {
    if (error.afterMax && max) {
        return i18next.t('dateMaxError', {max: moment.isMoment(max) ? max.format(dateFormat) : max});
    }
    if (error.beforeMin && min) {
        return i18next.t('dateMinError', {min: moment.isMoment(min) ? min.format(dateFormat) : min});
    }
    if (error.isDisabled) {
        return i18next.t('dateGenericInvalid');
    }
};
// eslint-disable-next-line no-restricted-syntax
export enum DayPickerPosition {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    TOP_LEFT = 'TOP_LEFT',
    TOP_RIGHT = 'TOP_RIGHT',
    /**
     * Positions the DayPicker below the input field if it's in the top half of the screen.
     * Positions the DayPicker above the input field if it's in the bottom half of the screen.
     */
    AUTO = 'AUTO',
}

export interface CustomDayPickerInputFieldProps {
    value?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    passive?: boolean;
    invalid?: boolean;
    autoFocus?: boolean;
    id?: string;
    className?: string;
    readOnly?: boolean;
    onClick?: () => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    selectedDays: BaseDayPickerInputProps['selectedDays'];
    'data-e2e-id'?: string;
    'data-e2e-class'?: string;
}
interface BaseDayPickerInputProps {
    value?: MaybeMoment;
    clearable?: boolean;
    onChange: (value?: string) => void;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    min?: MaybeMoment; // YYYY-MM-DD
    max?: MaybeMoment; // YYYY-MM-DD
    valueFormat?: string;
    passive?: boolean;
    /*
        Had to add ispassive to make it compatible with our FormField component 🙄 WHY IS THIS EVEN A THING
     */
    ispassive?: 'passive' | '';
    invalid?: boolean;
    disabledDays?: DisabledDays;
    onFocus?: (e?: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
    id?: string;
    className?: string;
    containerClassName?: string;
    customInput?: React.ComponentType<React.PropsWithChildren<any>>;
    position?: DayPickerPosition;
    modifiers?: Partial<Modifiers>;
    'data-e2e-id'?: string;
    'data-e2e-class'?: string;
    'data-intercom-target'?: string;
    hoverEffectOnWeek?: boolean;
    locale?: string;
    selectedDays?: DayPickerProps['selectedDays'];
    handleDayClick?: (date: Date) => void;
    handleMouseEnter?: (date: Date) => void;
    autohide?: boolean;
}

type PortalIdProps =
    | {portalId?: PortalRootId; dynamicPortalId?: undefined}
    | {portalId?: undefined; dynamicPortalId?: string};

export type DayPickerInputProps = BaseDayPickerInputProps & PortalIdProps;
export interface InnerProps {
    showDayPicker: boolean;
    showCalendar: () => void;
    hideCalendar: () => void;
}
interface ResponsiveProps {
    isTablet?: boolean;
}
interface DerivedProps {
    formattedValueSelector: ReturnType<typeof Selectors.makeMemoizedFormattedValue>;
    modifiersSelector: ReturnType<typeof Selectors.makeModifiersSelector>;
}
interface MemoizedProps {
    formattedValue: ReturnType<ReturnType<typeof Selectors.makeMemoizedFormattedValue>>;
    formattedModifiers: ReturnType<ReturnType<typeof Selectors.makeModifiersSelector>>;
}
type Props = DayPickerInputProps & ResponsiveProps & InnerProps & DerivedProps & MemoizedProps;
interface InnerState {
    focus: boolean;
    error?: string;
    showError: boolean;
    month: Date;
    inputDate: string;
    keyboardDate?: Date;
}
// eslint-disable-next-line no-restricted-syntax
enum KEY_CODE {
    ENTER = 13,
    ESC = 27,
    LEFT = 37,
    UP = 38,
    RIGHT = 39,
    DOWN = 40,
}

const modifyDateDays = (date: Date, modifier: number) => {
    const returnDate = new Date();
    returnDate.setTime(date.getTime() + modifier * 24 * 3600000);
    return returnDate;
};

const noOpFocus = e => {
    e.preventDefault();
    e.stopPropagation();
};
class InnerDayPickerInput extends React.PureComponent<Props, InnerState> {
    public static defaultProps = {
        clearable: false,
    };

    private containerRef = React.createRef<HTMLDivElement>();
    private portalRef = React.createRef<HTMLDivElement>();

    private currentMonth: Date;
    private defaultValueFormat = Constants.DEFAULT_DATE_VALUE_FORMAT;
    private defaultDateFormat = moment.localeData().longDateFormat(Constants.DEFAULT_DATE_FORMAT);
    private defaultPosition = DayPickerPosition.LEFT;
    private validationRegex = getDateValidationRegex(LocalStorageService.getFromLocalStorage('locale'));

    constructor(props) {
        super(props);
        this.currentMonth = moment().startOf('month').toDate();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.clear = this.clear.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.disabledDays = this.disabledDays.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.getDateErrors = this.getDateErrors.bind(this);
        this.isDateValid = this.isDateValid.bind(this);
        this.handleShowCalendar = this.handleShowCalendar.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.nodeIsContained = this.nodeIsContained.bind(this);
        this.state = {
            month: this.initialMonth,
            inputDate: '',
            focus: false,
            showError: false,
            keyboardDate: undefined,
        };
    }
    get initialMonth(): Date {
        if (this.props.value) {
            const initialMonth = moment(this.props.value, this.valueFormat).startOf('month');
            if (!initialMonth.isValid()) {
                throw new Error(
                    `Day picker value is not a valid date when parsed with the given value format! value: ${this.props.value}, format: ${this.props.valueFormat}`,
                );
            }
            return initialMonth.toDate();
        }
        return this.currentMonth;
    }
    get rawDayPickerCalendar() {
        return (
            <StyledDayPicker
                noTab
                tabIndex={-1}
                handleDayClick={this.handleDayClick}
                handleDayMouseOver={this.handleMouseEnter}
                month={this.state.month}
                numberOfMonths={1}
                withYearPicker
                selectedDays={this.selectedDays}
                disabledDates={this.disabledDays}
                fixedWeeks
                modifiers={this.props.formattedModifiers}
                onMonthChange={this.onMonthChange}
                hoverEffectOnWeek={this.props.hoverEffectOnWeek}
                locale={this.props.locale}
            />
        );
    }
    get dayPickerCalendar() {
        if (this.disableKeyboardInteraction) {
            return (
                <MobileDayPickerWrapper
                    innerRef={this.portalRef}
                    onClose={this.props.hideCalendar}
                    portalId={this.props.portalId || this.props.dynamicPortalId}
                >
                    {this.rawDayPickerCalendar}
                </MobileDayPickerWrapper>
            );
        }
        const containerGeometry = hasValue(this.containerRef.current)
            ? getElementGeometry(this.containerRef.current)
            : null;

        const calculatePosition = (windowHeight: number, top: number) => {
            const showOnTop = top > windowHeight / 2;
            return showOnTop ? DayPickerPosition.TOP_LEFT : DayPickerPosition.LEFT;
        };

        if (hasValue(containerGeometry)) {
            const calculatedPosition =
                this.props.position === DayPickerPosition.AUTO
                    ? calculatePosition(window.innerHeight, containerGeometry.top)
                    : this.position;

            return (
                <DayPickerCalendarPortal
                    innerRef={this.portalRef}
                    geometry={containerGeometry}
                    portalId={this.props.portalId}
                    dynamicPortalId={this.props.dynamicPortalId}
                >
                    <DayPickerCalendarContainer position={calculatedPosition}>
                        {this.rawDayPickerCalendar}
                    </DayPickerCalendarContainer>
                </DayPickerCalendarPortal>
            );
        }
        return null;
    }
    get position() {
        return this.props.position || this.defaultPosition;
    }
    get valueFormat() {
        return this.props.valueFormat || this.defaultValueFormat;
    }
    get dateFormat() {
        return this.defaultDateFormat;
    }
    get displayValue() {
        if (this.state.inputDate) {
            return this.state.inputDate;
        } else if (this.props.value) {
            return this.props.formattedValue;
        } else {
            return '';
        }
    }
    get passive() {
        return this.props.passive || this.props.ispassive === 'passive';
    }
    get clearButton() {
        if (!this.props.clearable || this.props.disabled || this.passive || !this.props.value) {
            return null;
        }
        return (
            <StyledRemoveButton
                onFocus={noOpFocus}
                tabIndex={-1}
                disabled={this.props.disabled}
                onClick={this.clear}
                appearance={ButtonAppearance.icon}
            >
                <Close size={12} />
            </StyledRemoveButton>
        );
    }
    get disableKeyboardInteraction() {
        return PlatformHelper.isMobileApp() || this.props.isTablet;
    }
    get customInputProps(): CustomDayPickerInputFieldProps {
        return {
            ...this.inputProps,
            selectedDays: this.selectedDays,
        };
    }
    get inputProps(): Omit<CustomDayPickerInputFieldProps, 'selectedDays'> {
        return {
            value: this.displayValue,
            onChange: this.handleInputChange,
            onKeyDown: this.handleKeyDown,
            placeholder: this.props.placeholder,
            disabled: this.props.disabled,
            required: this.props.required,
            passive: this.passive,
            invalid: this.props.invalid,
            autoFocus: this.props.autoFocus,
            id: this.props.id,
            className: this.props.className,
            readOnly: this.disableKeyboardInteraction,
            onClick: this.disableKeyboardInteraction ? this.handleShowCalendar : undefined,
            'data-e2e-id': this.props['data-e2e-id'],
            'data-e2e-class': this.props['data-e2e-class'],
        };
    }
    get selectedDays() {
        if (this.state.keyboardDate) {
            return this.state.keyboardDate;
        }

        // Let the modifiers choose what days should be marked as selected
        if (this.props.formattedModifiers.selected) {
            return undefined;
        }

        if (this.props.selectedDays) {
            return this.props.selectedDays;
        }

        if (this.props.value) {
            return moment(this.props.value, this.valueFormat).toDate();
        }
    }

    get autohide() {
        return this.props.autohide ?? true;
    }

    public componentDidUpdate(prevProps) {
        if (this.state.keyboardDate && this.state.keyboardDate.getMonth() !== this.state.month.getMonth()) {
            this.handleMonthChange(this.state.keyboardDate);
        } else if (this.props.value !== prevProps.value) {
            this.handleMonthChange(
                moment(this.props.value || undefined, this.props.valueFormat)
                    .startOf('month')
                    .toDate(),
            );
        }
    }
    public render() {
        const CustomInput = this.props.customInput;

        const content = (
            <div ref={this.containerRef} data-intercom-target={this.props?.['data-intercom-target']}>
                {!this.passive && !CustomInput && !this.props.disabled ? (
                    <ValidationContainer active={this.state.showError}>
                        <Caption color={Colors.Accent3}>{this.state.error}</Caption>
                    </ValidationContainer>
                ) : null}
                {CustomInput ? <CustomInput {...this.customInputProps} /> : <StyledTextField {...this.inputProps} />}
                {this.clearButton}
                {this.props.showDayPicker ? this.dayPickerCalendar : null}
            </div>
        );
        return (
            <DayPickerInputContainer className={this.props.containerClassName}>
                <div
                    onFocus={this.disableKeyboardInteraction ? undefined : this.onFocus}
                    onBlur={this.disableKeyboardInteraction ? undefined : this.onBlur}
                >
                    {content}
                </div>
            </DayPickerInputContainer>
        );
    }
    private disabledDays(day: Date) {
        return (
            (this.props.disabledDays && dateIsInDisabledDays(day, this.props.disabledDays)) ||
            (this.props.min && moment(this.props.min, 'YYYY-MM-DD').isAfter(day, 'day')) ||
            (this.props.max && moment(this.props.max, 'YYYY-MM-DD').isBefore(day, 'day'))
        );
    }
    private handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.value) {
            this.setState({
                inputDate: '',
                error: undefined,
            });
            this.props.onChange(e.target.value);
        }
        if (this.validationRegex.test(e.target.value)) {
            const momentDate = moment(e.target.value, this.dateFormat, true);
            if (momentDate.isValid()) {
                const errors = this.getDateErrors(momentDate);
                const hasErrors = errors ? Object.values(errors).some(hasError => hasError) : false;
                if (errors && hasErrors) {
                    this.setState({
                        showError: true,
                        error: validationErrorToString(errors, this.dateFormat, this.props.min, this.props.max),
                    });
                } else {
                    this.setState({
                        inputDate: '',
                        showError: false,
                        error: undefined,
                    });
                    this.props.onChange(momentDate.format(this.valueFormat));
                }
            } else {
                this.setState({
                    inputDate: e.target.value,
                    showError: true,
                    error: i18next.t('dateFormatValidationError', {format: this.dateFormat}),
                });
            }
        } else {
            this.setState({
                inputDate: e.target.value,
                error: i18next.t('dateFormatValidationError', {format: this.dateFormat}),
            });
        }
    }
    private handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.keyCode) {
            case KEY_CODE.ENTER:
                if (this.state.keyboardDate && !this.state.error) {
                    e.preventDefault();
                    this.props.onChange(moment(this.state.keyboardDate).format(this.valueFormat));
                    this.setState({
                        keyboardDate: undefined,
                        inputDate: '',
                        error: undefined,
                        showError: false,
                    });
                    this.props.hideCalendar();
                } else if (this.state.error && this.state.inputDate) {
                    e.preventDefault();
                    this.setState({
                        inputDate: '',
                        showError: true,
                    });
                    this.props.onChange('');
                } else if (this.props.showDayPicker) {
                    e.preventDefault();
                    this.props.hideCalendar();
                }
                break;
            case KEY_CODE.ESC:
                if (this.state.error) {
                    this.setState({
                        inputDate: '',
                    });
                }
                if (this.props.showDayPicker) {
                    this.props.hideCalendar();
                    this.setState({
                        keyboardDate: undefined,
                    });
                }
                break;
            case KEY_CODE.UP:
                if (this.props.showDayPicker) {
                    e.preventDefault();
                    const newDate = this.state.keyboardDate
                        ? modifyDateDays(this.state.keyboardDate, -7)
                        : moment(this.props.value || undefined)
                              .subtract(7, 'days')
                              .toDate();
                    if (this.isDateValid(newDate)) {
                        this.setState({
                            keyboardDate: newDate,
                        });
                    }
                }
                break;
            case KEY_CODE.DOWN:
                if (this.props.showDayPicker) {
                    if (!this.state.keyboardDate && !this.props.value) {
                        // Purely because of UX reasons we select the current date if you press DOWN when there's no selected value present
                        this.setState({
                            keyboardDate: new Date(),
                        });
                    } else {
                        const newDate = this.state.keyboardDate
                            ? modifyDateDays(this.state.keyboardDate, 7)
                            : moment(this.props.value || undefined)
                                  .add(7, 'days')
                                  .toDate();
                        if (this.isDateValid(newDate)) {
                            this.setState({
                                keyboardDate: newDate,
                            });
                        }
                    }
                } else {
                    this.props.showCalendar();
                }
                break;
            case KEY_CODE.LEFT:
                if (this.props.showDayPicker) {
                    e.preventDefault();
                    const newDate = this.state.keyboardDate
                        ? modifyDateDays(this.state.keyboardDate, -1)
                        : moment(this.props.value || undefined)
                              .subtract(1, 'days')
                              .toDate();
                    if (this.isDateValid(newDate)) {
                        this.setState({
                            keyboardDate: newDate,
                        });
                    }
                }
                break;
            case KEY_CODE.RIGHT:
                if (this.props.showDayPicker) {
                    e.preventDefault();
                    const newDate = this.state.keyboardDate
                        ? modifyDateDays(this.state.keyboardDate, 1)
                        : moment(this.props.value || undefined)
                              .add(1, 'days')
                              .toDate();
                    if (this.isDateValid(newDate)) {
                        this.setState({
                            keyboardDate: newDate,
                        });
                    }
                }
                break;
        }
    }
    private getDateErrors(date: Date | Moment): undefined | ValidationError {
        if (!this.props.min && !this.props.max && !this.props.disabledDays) {
            return undefined;
        }
        const momentDate = moment(date);

        const isInDisabledDate = dateIsInDisabledDays(date, this.props.disabledDays);
        const isBeforeMin = this.props.min ? momentDate.isBefore(this.props.min, 'days') : false;
        const isAfterMax = this.props.max ? momentDate.isAfter(this.props.max, 'days') : false;

        return {
            isDisabled: isInDisabledDate,
            beforeMin: isBeforeMin,
            afterMax: isAfterMax,
        };
    }
    private isDateValid(date: Date | Moment): boolean {
        const errors = this.getDateErrors(date);
        return errors ? Object.values(errors).every(hasError => !hasError) : true;
    }
    private handleDayClick(date: Date, modifiers: any) {
        if (modifiers.disabled) {
            return;
        }

        if (this.props.handleDayClick) {
            this.props.handleDayClick(date);
        }

        this.setState({
            inputDate: '',
            showError: false,
            error: undefined,
        });

        this.handleChange(date);
    }

    private handleMouseEnter(date: Date) {
        if (this.props.handleMouseEnter) {
            this.props.handleMouseEnter(date);
        }
    }

    private handleChange(value?: Date) {
        if (value) {
            this.props.onChange(moment(value).format(this.valueFormat));
        } else {
            this.clear();
        }

        if (this.autohide) {
            this.props.hideCalendar();
        }
    }
    private clear() {
        if (this.props.clearable) {
            this.props.onChange('');
        }
    }
    private handleMonthChange(month: Date) {
        this.setState({month});
    }
    private handleShowCalendar() {
        this.setState({
            month: this.initialMonth,
        });
        this.props.showCalendar();
    }
    private onFocus(e: React.FocusEvent<HTMLInputElement>) {
        /*
            Only handle focus if the originating target comes from outside input or daypicker

            Addendum:
            relatedTarget is ("for security reasons") sometimes set to null. This seems to be true
            when the focus event originated outside the containing ref. Luckily for us, this is actually
            the case we want to catch
            https://developer.mozilla.org/en-US/docs/Web/API/FocusEvent/relatedTarget
         */
        if (!e.relatedTarget || !this.nodeIsContained(e.relatedTarget as Node)) {
            if (this.props.onFocus) {
                this.props.onFocus(e);
            }
            this.handleShowCalendar();
        }
    }
    private onBlur(e: React.FocusEvent<HTMLInputElement>) {
        // Only handle blur if the "destination node" of the blur event is outside the input or daypicker
        if (!this.nodeIsContained(e.relatedTarget as Node)) {
            this.handleBlur(e);
        }
    }
    private nodeIsContained(node: Node) {
        if (this.portalRef.current) {
            return this.portalRef.current.contains(node);
        }
        return this.containerRef.current && this.containerRef.current.contains(node);
    }

    private handleBlur(e?: React.FocusEvent<HTMLInputElement>) {
        if (this.disableKeyboardInteraction) {
            return;
        }
        this.setState({
            showError: Boolean(this.state.error),
            keyboardDate: undefined,
            inputDate: '',
        });
        this.props.hideCalendar();
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }

        if (this.state.error) {
            this.props.onChange('');
        }
    }
    private onMonthChange(month: Date) {
        this.setState({
            month,
            keyboardDate: undefined,
        });
    }
}
export const ComposedDayPickerInput = compose(responsiveState<DayPickerInputProps & InnerProps>(['isTablet']))
    .with(
        deriveProps(() => ({
            formattedValueSelector: Selectors.makeMemoizedFormattedValue(),
            modifiersSelector: Selectors.makeModifiersSelector(),
        })),
    )
    .with(
        formPrepare(props => ({
            formattedValue: props.formattedValueSelector(props),
            formattedModifiers: props.modifiersSelector(props),
        })),
    )
    .apply(InnerDayPickerInput);

const StyledTextField = styled(TextField)`
    && {
        margin: 0;
        position: relative;
        &[readonly] {
            background: ${props => props.theme.invertedText};
            cursor: inherit;
        }
    }
`;

const DayPickerInputContainer = styled('div')`
    position: relative;
    width: 100%;
`;
const ValidationContainer = styled('div')<{active: boolean}>`
    position: absolute;
    top: 0;
    left: 8px;
    padding: 2px;
    border: 1px solid lightgray;
    background: ${props => props.theme.invertedText};
    border-bottom: none;
    ${props =>
        props.active
            ? css`
                  transition: transform 100ms;
                  transform: translate(0, -100%);
              `
            : css`
                  opacity: 0;
              `}
`;

const SharedContainerCSS = css`
    border: 1px solid ${props => props.theme.delimiter};
    background: ${props => props.theme.invertedText};
    padding: 16px 0;
    border-radius: 4px;
    box-shadow: ${props => props.theme.shadow.one};
`;
const positionToCSS = (position: DayPickerPosition) => {
    switch (position) {
        case DayPickerPosition.LEFT:
            return css`
                top: 100%;
                left: 0;
            `;
        case DayPickerPosition.RIGHT:
            return css`
                top: 100%;
                right: 0;
            `;
        case DayPickerPosition.TOP_LEFT:
            return css`
                bottom: 100%;
                left: 0;
            `;
        case DayPickerPosition.TOP_RIGHT:
            return css`
                bottom: 100%;
                right: 0;
            `;
    }
};

const DayPickerCalendarPortal: React.FC<
    React.PropsWithChildren<{
        geometry: ReturnType<typeof getElementGeometry>;
        innerRef: any;
        portalId?: PortalRootId;
        dynamicPortalId?: string;
    }>
> = ({children, geometry, innerRef, portalId, dynamicPortalId}) => (
    <Portal rootId={portalId ?? PortalRootId.contractPortalRoot} dynamicId={dynamicPortalId}>
        <PortalContent className={dayPickerInputPortalContentClassName} geometry={geometry}>
            <div ref={innerRef}>{children}</div>
        </PortalContent>
    </Portal>
);

export const PortalContent = styled.div<{geometry: ReturnType<typeof getElementGeometry>}>`
    position: fixed;
    z-index: 1999;
    top: ${props => props.geometry.top}px;
    left: ${props => props.geometry.left}px;
    height: ${props => props.geometry.height}px;
    width: ${props => props.geometry.width}px;
`;

const DayPickerCalendarContainer = styled('div')<{position: DayPickerPosition}>`
    position: absolute;
    z-index: 100;
    ${props => positionToCSS(props.position)}
    ${SharedContainerCSS}
`;
const MobileDayPickerCalendarContainer = styled.div`
    min-height: 320px;
    ${SharedContainerCSS};
    padding: 20px 16px 16px 16px;
    border-radius: 7px;
`;
const StyledDayPicker = styled(DayPicker)`
    .DayPicker-Caption {
        margin-top: -14px;
    }
    ${Day} {
        ${media.mobile`
            height: 42px;
            width: 42px;
        `}
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--isInRange) {
        ${Day} {
            border-radius: 50% !important;
        }
    }
    .DayPicker-Month {
        margin-bottom: 0;
    }
    position: relative;
`;

interface State {
    showDayPicker: boolean;
}
class DayPickerInputWrapper extends React.Component<DayPickerInputProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            showDayPicker: false,
        };
        this.showCalendar = this.showCalendar.bind(this);
        this.hideCalendar = this.hideCalendar.bind(this);
    }
    public render() {
        return (
            <ComposedDayPickerInput
                {...this.props}
                showDayPicker={this.state.showDayPicker}
                showCalendar={this.showCalendar}
                hideCalendar={this.hideCalendar}
            />
        );
    }

    private showCalendar() {
        this.setState({showDayPicker: true});
    }
    private hideCalendar() {
        this.setState({showDayPicker: false});
    }
}

export default DayPickerInputWrapper;

interface MobileDayPickerWrapperState {
    active: boolean;
}
interface MobileDayPickerWrapperProps {
    onClose: () => void;
    innerRef: any;
    portalId?: string;
}
class MobileDayPickerWrapper extends React.Component<
    React.PropsWithChildren<MobileDayPickerWrapperProps>,
    MobileDayPickerWrapperState
> {
    public state = {active: false};
    public componentDidMount() {
        waitForFrames(() => this.setState({active: true}));
    }
    public render() {
        return (
            <DayPickerPortalModal
                rootId={this.props.portalId ?? PortalRootId.portalRoot}
                active={this.state.active}
                noBackdropOverflow
                fullScreenOnMobile={false}
                onCloseModal={this.props.onClose}
            >
                <div ref={this.props.innerRef}>
                    <MobileDayPickerCalendarContainer>
                        {this.props.children}
                        <StyledCloseButton onClick={this.props.onClose} buttonSize={ButtonSize.small}>
                            <Body emphasized>{i18next.t('close')}</Body>
                        </StyledCloseButton>
                    </MobileDayPickerCalendarContainer>
                </div>
            </DayPickerPortalModal>
        );
    }
}

export const DayPickerPortalModal = styled(PortalModal)<any>`
    && {
        background: transparent;
        margin: 0 auto;
        position: absolute;
        max-height: calc(100% - ${getSpacing(s6)});
        top: 50%;
        left: 50%;
        transition: transform 0.3s ${props => props.theme.timings.functions.default}, opacity 0.2s linear;
        display: flex;
        flex-direction: column;
        min-width: 264px;
        ${props =>
            props.active
                ? css`
                      transform: translate(-50%, -50%);
                      opacity: 1;
                  `
                : css`
                      transform: translate(-50%, 0);
                      opacity: 0;
                  `}
    }
`;

const StyledRemoveButton = styled(Button)`
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0 8px;
    height: 100%;
    right: 0;
`;

const StyledCloseButton = styled(Button)`
    justify-content: center;
    background: ${props => props.theme.invertedText};
    display: block;
    margin: 16px auto 0;
    &&& * {
        color: ${props => props.theme.analogue2};
    }
    &,
    &:active,
    &:focus {
        border: none;
    }
`;
