import React from 'react';
import {type RouteComponentProps} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Stack, Text} from 'modern-famly';

import {fetchChild} from 'signin-app/child/actions';
import Header from 'signin-app/components/header';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {PinHeader} from 'signin-app/child/pin-header';
import {PageBodyContainer} from 'signin-app/components/action-buttons';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import * as LoginSelectors from 'signin-app/login/selectors';
import * as ChildSelectors from 'signin-app/child/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';
import {PageContainer} from 'signin-app/components/containers';
import {PersonImage} from 'signin-app/components/person-image';

import * as Selectors from './selectors';
import {RoomSwitcher} from './room-switcher';
import {CheckOutDetails} from './check-out-details';
import {CheckOutChildProvider, useCheckOutChild} from './check-out-child-context';

const HeaderChildDetails = () => {
    const {child, initials, displayName} = useCheckOutChild();

    return (
        <Stack alignItems="center" gap={2}>
            <PersonImage size="sm" image={child.image.small} initials={initials} />
            <Text variant="h5" emphasized>
                {displayName}
            </Text>
        </Stack>
    );
};

type CheckOutRouteProps = RouteComponentProps<{childId: string}>;

export const ChildCheckOut: React.FC<CheckOutRouteProps> = props => {
    const {match} = props;
    const [isSwitchingRoomViewVisible, setIsSwitchingRoomViewVisible] = React.useState(false);
    const dispatch = useDispatch();

    const child = useTypedSelector(state => Selectors.getChild(state, props));
    const isFetching = useTypedSelector(ChildSelectors.isFetching);

    const isPinApp = useTypedSelector(LoginSelectors.isPinApp);
    const whitelabelConfiguration = useTypedSelector(WhitelabelSelectors.whiteLabelConfiguration);
    const accessToken = useTypedSelector(LoginSelectors.accessToken);

    const showSwitchRoomView = React.useCallback(() => {
        setIsSwitchingRoomViewVisible(true);
    }, []);

    React.useEffect(() => {
        if (!isPinApp) {
            dispatch(fetchChild.action(accessToken, match.params.childId));
        }
    }, [accessToken, match.params.childId, isPinApp, dispatch]);

    if (isFetching || !child) {
        return (
            <PageContainer>
                {isPinApp ? <PinHeader whitelabelConfiguration={whitelabelConfiguration} /> : <Header />}
                <Spinner centered />
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <CheckOutChildProvider child={child}>
                {isPinApp ? (
                    <PinHeader
                        centerElement={<HeaderChildDetails />}
                        whitelabelConfiguration={whitelabelConfiguration}
                    />
                ) : (
                    <Header centerElement={<HeaderChildDetails />} />
                )}
                <PageBodyContainer mt={16}>
                    {isSwitchingRoomViewVisible ? (
                        <RoomSwitcher />
                    ) : (
                        <CheckOutDetails showSwitchRoomView={showSwitchRoomView} />
                    )}
                </PageBodyContainer>
            </CheckOutChildProvider>
        </PageContainer>
    );
};
