import {hasValue} from 'web-app/util/typescript';

const cache = {};

const createCacheKey = (iconName, iconColor) => `${iconName}_${iconColor}`;

export const useInvertIconColors = (iconMarkup: string, icon: string, color: string): string => {
    // If caching is enabled and there is a markup already cached, return the cached markup
    const key = createCacheKey(icon, color);
    if (hasValue(iconMarkup) && hasValue(cache[key]) && cache[key].length > 0) {
        return cache[key];
    }

    const containerDiv = document.createElement('div');
    containerDiv.innerHTML = decodeURIComponent(iconMarkup);

    const svgElement = containerDiv.querySelector('svg');
    if (!svgElement) {
        return iconMarkup; // Return original markup if no SVG found
    }

    // Loop through each <g> element to find fill colors
    const gElements = svgElement.querySelectorAll('g');
    let isWhiteIcon = false;
    let backgroundColor: string = '';

    gElements.forEach(g => {
        const fillColor = g.getAttribute('fill');

        if (fillColor === 'white') {
            isWhiteIcon = true;
        } else if (fillColor && fillColor !== 'transparent') {
            backgroundColor = fillColor;
        }
    });

    // If we identified a white icon on a colored background
    if (isWhiteIcon && backgroundColor) {
        // Swap the colors: make the icon the background color and make the background transparent
        gElements.forEach(g => {
            if (g.getAttribute('fill') === 'white') {
                g.setAttribute('fill', backgroundColor);
            } else {
                g.setAttribute('fill', 'transparent');
            }
        });
    }

    const invertedMarkup = encodeURIComponent(containerDiv.innerHTML);

    cache[key] = invertedMarkup;

    return invertedMarkup;
};
