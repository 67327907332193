import React from 'react';
import {
    type AutocompleteChangeReason,
    type AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import {useFormControl} from '@mui/material/FormControl';
import {type AutocompleteChangeDetails} from '@mui/base';

import {Avatar} from 'modern-famly/components/data-display';
import {Pill} from 'modern-famly/components/data-display';
import {useDataProps} from 'modern-famly/components/util';
import {hasValue} from 'modern-famly/util';
import {useDebounce} from 'modern-famly/util/use-debounce';
import {extractSystemStyles, type AlignmentProps} from 'modern-famly/system/system-props';

import {BaseSelect, LoadingAdornment, type BaseSelectProps, type SelectOption} from './base';

type Multiple = true;
type DisableClearable = true;
type FreeSolo = undefined;
type AutoselectProps<T extends SelectOption> = MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;

export type MultiSelectProps<T extends SelectOption> = BaseSelectProps<T> & {
    /**
     * If `true`, the popup won't close when a value is selected.
     * @default true
     */
    disableCloseOnSelect?: AutoselectProps<T>['disableCloseOnSelect'];

    /**
     * The change handler called when the component updates
     */
    onChange?: (
        option: T[] | undefined,
        reason: AutocompleteChangeReason,
        details: AutocompleteChangeDetails<T> | undefined,
    ) => void;

    /**
     * The value of the component. If a string is given, the component will use the option whose `value` matches the string.
     */
    value?: T[] | string[] | null;

    /**
     * Whether the component is currently loading
     */
    loading?: boolean;
} & AlignmentProps;

export const MultiSelect = <T extends SelectOption>({
    autoFocus,
    disableCloseOnSelect,
    disablePortal,
    disabled: disabledFromProps,
    error: errorFromProps,
    fullWidth,
    noOptionsText,
    onChange: onChangeFromProps,
    open,
    options,
    placeholder,
    size,
    value: valueFromProps,
    getOptionAdornment,
    loading,
    ...rest
}: MultiSelectProps<T>) => {
    const dataProps = useDataProps(rest);

    // As MUI's Autoselect component uses a FormControl itself, we have to manually get the
    // relevant values from the outer FormControl and pass them on. We ensure that values from
    // props always take precedence.
    const {disabled: formControlDisabled, error: formControlError} = useFormControl() || {};

    const disabled = disabledFromProps ?? formControlDisabled;
    const error = errorFromProps ?? formControlError;

    // MUI Autoselect's onChange passes in the native HTML event as the first argument to
    // onChange. Until someone requests otherwise we're only interested in the selected option.
    const onChange = React.useCallback<Exclude<AutoselectProps<T>['onChange'], undefined>>(
        (_, value, reason, details) => {
            onChangeFromProps?.(value ?? undefined, reason, details);
        },
        [onChangeFromProps],
    );

    const value = React.useMemo(() => {
        if (!valueFromProps) {
            return [];
        }

        if (isStringArray(valueFromProps)) {
            return valueFromProps
                .map(valueFromProps => options.find(option => option.value === valueFromProps))
                .filter(hasValue);
        }

        return valueFromProps;
    }, [options, valueFromProps]);

    const {sx: systemSx} = extractSystemStyles(rest);

    return (
        <BaseSelect<T, Multiple>
            fullWidth={fullWidth}
            noOptionsText={noOptionsText}
            open={open}
            size={size}
            disabled={disabled}
            disableCloseOnSelect={disableCloseOnSelect ?? true}
            disablePortal={disablePortal}
            multiple={true}
            onChange={onChange}
            loading={loading}
            renderInput={params => (
                <MuiTextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: loading ? (
                            <>
                                <LoadingAdornment size={size ?? 'regular'} />
                                {params.InputProps.endAdornment}
                            </>
                        ) : (
                            params.InputProps.endAdornment
                        ),
                    }}
                    error={error}
                    variant="outlined"
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                />
            )}
            renderTags={(values, getTagProps) => {
                return values.map((value, idx) => {
                    return (
                        // The `key` is returned by the `getTagProps` function
                        // eslint-disable-next-line react/jsx-key
                        <Pill
                            {...getTagProps({index: idx})}
                            size="compact"
                            text={value.label}
                            avatar={value.imageUrl ? <Avatar src={value.imageUrl} /> : undefined}
                        />
                    );
                });
            }}
            options={options}
            // Prevents the error: "A component is changing the uncontrolled value state..."
            // The `onChange` handler will never receive this value.
            value={value}
            getOptionAdornment={getOptionAdornment}
            onOpen={rest.onOpen}
            onClose={rest.onClose}
            onInputChange={rest.onInputChange}
            filterSelectedOptions={rest.filterSelectedOptions}
            inputValue={rest.inputValue}
            isOptionEqualToValue={rest.isOptionEqualToValue}
            sx={systemSx}
            {...dataProps}
        />
    );
};

const isStringArray = (cands: any): cands is Array<string> => {
    return Array.isArray(cands) && cands.every((cand: any) => typeof cand === 'string');
};

type AsyncOptionsProps<T extends SelectOption> = Pick<
    MultiSelectProps<T>,
    | 'onChange'
    | 'onInputChange'
    | 'value'
    | 'options'
    | 'inputValue'
    | 'loading'
    | 'onOpen'
    | 'onClose'
    | 'filterSelectedOptions'
    | 'includeInputInList'
    | 'autoComplete'
    | 'filterOptions'
    | 'isOptionEqualToValue'
>;

/**
 * Utility function for the asynchronous MultiSelect component to determine if an option is equal to a value.
 *
 * This is necessary as MUI's Autoselect component default to compare by reference (strict equality) which
 * will never be true for options fetched asynchronously.
 */
const isOptionEqualToValue = <T extends SelectOption>(option: T, value: T) => option.value === value.value;

/**
 * A hook that provides the necessary props to use the MultiSelect component with asynchronously loaded options.
 * This hook is intended to be used in conjunction with the MultiSelect component.
 *
 * @param fetchOptions A function that fetches options based on a query
 */
export function useAsyncOptionsForMultiSelect<T extends SelectOption>({
    fetchOptions,
    onChange: onChangeFromProps,
}: {
    fetchOptions: (query: string) => Promise<Array<T>>;
    onChange?: MultiSelectProps<T>['onChange'];
}): AsyncOptionsProps<T> {
    const [selectedOption, setSelectedOption] = React.useState<Array<T>>([]);
    const [query, setQuery] = React.useState<string>('');
    const [state, setState] = React.useState<'clean' | 'dirty' | 'closed'>('closed');

    const debouncedValue = useDebounce(query, 500);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<T[]>([]);

    React.useEffect(() => {
        if (!debouncedValue) {
            return () => {};
        }

        // We only want to fetch options when user has entered text
        if (state !== 'dirty') {
            return () => {};
        }

        // Avoids results of previously fired network requests to be visible to the user
        let wasCancelled = false;

        setLoading(true);

        fetchOptions(debouncedValue)
            .then(result => {
                if (wasCancelled) {
                    return;
                }
                setOptions([...selectedOption, ...result]);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            wasCancelled = true;
        };
    }, [debouncedValue, state, selectedOption, fetchOptions]);

    const onChange = React.useCallback<NonNullable<MultiSelectProps<T>['onChange']>>(
        (selectedOptions, reason, details) => {
            setSelectedOption(selectedOptions ?? []);
            onChangeFromProps?.(selectedOptions, reason, details);

            // Reset the query when an option is selected
            // The available options from the search are still available as we don't change the options
            if (reason === 'selectOption') {
                setQuery('');
                setState('clean');
            }

            // Remove the option from the list of selected options, otherwise it shows up in the option list
            if (reason === 'removeOption') {
                setState('clean');

                if (details) {
                    setOptions(currentOptions =>
                        currentOptions.filter(option => option.value !== details.option.value),
                    );
                }
            }
        },
        [onChangeFromProps],
    );

    const onInputChange = React.useCallback<NonNullable<MultiSelectProps<SelectOption>['onInputChange']>>(
        (_, value, reason) => {
            if (reason === 'input') {
                setState('dirty');
                setQuery(value);
            }
        },
        [],
    );

    const onOpen = React.useCallback(() => {
        setState('clean');
    }, []);

    const onClose = React.useCallback(() => {
        setState('closed');
    }, []);

    return {
        onChange,
        onInputChange,
        value: selectedOption,
        options,
        inputValue: query,
        loading,
        onOpen,
        onClose,
        filterSelectedOptions: true,
        includeInputInList: true,
        autoComplete: true,
        filterOptions: x => x,
        isOptionEqualToValue,
    };
}
