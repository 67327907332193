/* eslint-disable default-case */
/* eslint-disable consistent-return */
import React from 'react';
import styled, {type ThemedStyledProps, css} from 'styled-components';

import {type ITheme} from 'web-app/styleguide/themes/model';
import {getSpacing, s6, s5, s8, s1, s2, s4, s3, s0, type SpacingIdentity} from 'web-app/styleguide/spacing';
import {Colors, currentTheme} from 'web-app/styleguide';
import {type IconProps, SelfScaling, type IconWeight} from 'web-app/react/components/icons/types';
import {type MarginProps, marginPropsToCSS, Flex} from 'web-app/react/components/layout/layout';
import {Subheader, Body, type TextProps, TextBase, Caption} from 'web-app/react/components/text/text';
import {getProperty, hasValue} from 'web-app/util/typescript';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {addHover} from 'web-app/styleguide/utils';
import {overlayColors} from 'web-app/util/color';

import {type ContentProps, LayoutSize, type DisplayProps, isOnlyIcon, isOnlyText, IconPosition} from './types';

type ButtonIconProps = IconProps & {weight?: IconWeight};

type ButtonHTMLAttributes = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLElement>, HTMLElement>;

type OnClick = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement & HTMLAnchorElement & HTMLLabelElement>,
    HTMLElement
>['onClick'];
type BaseButtonProps = {
    className?: string;
    appearance?: Appearance;
    disabled?: boolean;
    ['data-e2e-id']?: string;
    ['data-e2e-class']?: string;
    ['data-intercom-target']?: string;
    type?: ButtonHTMLAttributes['type'];
    onTouchEnd?: ButtonHTMLAttributes['onTouchEnd'];
    onMouseDown?: ButtonHTMLAttributes['onMouseDown'];
};

type WithHtmlFor = BaseButtonProps & {onClick?: OnClick; href?: undefined; htmlFor?: string};
type WithOnClick = BaseButtonProps & {onClick: OnClick; href?: undefined; htmlFor?: undefined};
type WithHref = BaseButtonProps & {
    onClick?: undefined;
    href: string;
    openInNewTab?: boolean;
    htmlFor?: undefined;
    download?: boolean;
};
type WithOnClickAndHref = BaseButtonProps & {
    onClick: OnClick;
    href: string;
    openInNewTab?: boolean;
    htmlFor?: undefined;
    download?: boolean;
};
type ButtonProps = WithOnClick | WithHref | WithOnClickAndHref | WithHtmlFor;

export type InputProps = ContentProps & ButtonProps & DisplayProps & MarginProps;

const hasHref = (props: ButtonProps): props is WithHref | WithOnClickAndHref => {
    return hasValue(props.href);
};

const hasHtmlFor = (props: ButtonProps): props is WithHtmlFor => hasValue(props.htmlFor);

const extractContentProps = (props: ContentProps): ContentProps => {
    if (isOnlyIcon(props)) {
        return {
            spinner: props.spinner,
            icon: props.icon,
            circular: props.circular,
        };
    }

    if (isOnlyText(props)) {
        return {
            spinner: props.spinner,
            text: props.text,
        };
    }

    return {
        spinner: props.spinner,
        icon: props.icon,
        text: props.text,
    };
};

const extractMarginProps = (props: InputProps): MarginProps => ({
    margin: props.margin,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
});

/**
 * Private Button component with children.
 *
 * Consists of a private Styled button and a private Layout component.
 *
 * Both components needs to know about size, icon, and text in order to introduce the correct paddings and layout.
 * The StyledButton component is responsible for the outer-most padding which changes depending on content and size.
 * The Layout component renders the content.
 *
 * @private
 * It is not meant for consumers to pass in children.
 * Children should only be used for component extensions!
 */
export const ButtonWithChildren = React.forwardRef<HTMLElement, React.PropsWithChildren<InputProps>>((props, ref) => {
    const {appearance = Appearance.Default, size = LayoutSize.Medium, children} = props;

    const buttonProps = {
        onClick: props.onClick,
        onTouchEnd: props.onTouchEnd,
        onMouseDown: props.onMouseDown,
        appearance,
        size,
        ...extractContentProps(props),
        className: props.className,
        disabled: props.disabled || props.spinner,
        'data-e2e-id': getProperty(props, 'data-e2e-id'),
        'data-e2e-class': getProperty(props, 'data-e2e-class'),
        'data-intercom-target': getProperty(props, 'data-intercom-target'),
        type: props.type,
        ...extractMarginProps(props),
        /**
         * forwardRef and styled-components have a type conflict, even though it works.
         * See:
         * - https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
         * - https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
         */
        ref: ref as any,
    };

    const layout = (
        <Layout
            size={size}
            color={props.textColor ? props.textColor : appearanceToColor(appearance)}
            iconColor={props.iconColor}
            iconWeight={props.iconWeight}
            iconPosition={props.iconPosition}
            spinnerColor={props.spinnerColor}
            spinnerSize={props.spinnerSize}
            {...extractContentProps(props)}
        />
    );

    if (hasHref(props)) {
        return (
            <StyledAnchor
                {...buttonProps}
                href={props.href}
                target={props.openInNewTab ? '_blank' : undefined}
                rel="noopener noreferrer"
                download={props.download}
            >
                {layout}
                {children}
            </StyledAnchor>
        );
    }

    if (hasHtmlFor(props)) {
        return (
            <StyledLabel {...buttonProps} htmlFor={props.htmlFor}>
                {layout}
                {children}
            </StyledLabel>
        );
    }

    return (
        <StyledButton {...buttonProps} type={buttonProps.type ?? 'button'}>
            {layout}
            {children}
        </StyledButton>
    );
});

/**
 * Publicly exposed Button. Used this for your normal development work.
 *
 * @deprecated Use the Button from 'modern-famly' instead
 */
const Button = React.forwardRef<HTMLElement, InputProps>((props, ref) => <ButtonWithChildren {...props} ref={ref} />);
export default Button;

// eslint-disable-next-line no-restricted-syntax
export enum Appearance {
    Default = 'Default',
    DefaultOnBackground = 'DefaultOnBackground',
    Primary = 'Primary',
    Naked = 'Naked',
    Danger = 'Danger',
}

/**
 * Appearance related styling
 */
const appearanceToColor = (appearance: Appearance) => {
    switch (appearance) {
        case Appearance.Default:
        case Appearance.DefaultOnBackground:
        case Appearance.Naked:
            return Colors.Text;
        case Appearance.Primary:
        case Appearance.Danger:
            return Colors.InvertedText;
    }
};
const appearanceToBackground = ({
    appearance,
    theme,
}: ThemedStyledProps<
    {
        appearance: Appearance;
    },
    ITheme
>) => {
    switch (appearance) {
        case Appearance.Default:
        case Appearance.DefaultOnBackground:
            return theme.invertedText;
        case Appearance.Primary:
            return theme.primary;
        case Appearance.Danger:
            return theme.accent3;
        case Appearance.Naked:
            return 'transparent';
    }
};
const appearanceToBorderColor = ({
    appearance,
    theme,
}: ThemedStyledProps<
    {
        appearance: Appearance;
    },
    ITheme
>) => {
    switch (appearance) {
        case Appearance.Default:
            return theme.borderConfiguration.defaultColor;
        case Appearance.DefaultOnBackground:
        case Appearance.Primary:
        case Appearance.Naked:
        case Appearance.Danger:
            return 'transparent';
    }
};

/**
 * The padding calculations are based on our design specification.
 *
 * In the future when we introduce right-side icons (e.g. Arrow icon) we will have to revisit this
 * as well as the layout component.
 */
const getPadding = (props: ContentProps) => {
    if (hasValue(props.text) && hasValue(props.icon)) {
        // Both text and icon
        return css`
            padding: ${getSpacing(s2)} ${getSpacing(s4)} ${getSpacing(s2)} ${getSpacing(s3)};
        `;
    } else if (!hasValue(props.text) && hasValue(props.icon)) {
        // Icon only
        return css`
            padding: ${getSpacing(s2)};
        `;
    }
    // Text only
    return css`
        padding: ${getSpacing(s2)} ${getSpacing(s4)};
    `;
};

const apperanceToDisabledStyle = (props: ThemedStyledProps<ButtonStyleProps, ITheme>) => {
    switch (props.appearance) {
        case Appearance.Default:
        case Appearance.Naked:
            return css<typeof props>`
                ${TextBase}, ${StyledIcon} {
                    opacity: 0.5;
                }
            `;
        default:
            return css<typeof props>`
                background: ${overlayColors(appearanceToBackground(props), props.theme.invertedTextSecondary)};
            `;
    }
};

type ButtonStyleProps = {
    appearance: Appearance;
    size: LayoutSize;
    disabled?: boolean;
} & ContentProps &
    MarginProps;
export const ButtonStyle = css<ButtonStyleProps>`
    ${marginPropsToCSS}
    ${getPadding};
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background: ${appearanceToBackground};
    ${props =>
        props.disabled
            ? css`
                  & {
                      ${apperanceToDisabledStyle}
                  }
              `
            : addHover(css`
                  &:hover {
                      filter: brightness(97%);
                  }
              `)}
    ${props =>
        isOnlyIcon(props) && props.circular
            ? css`
                  border-radius: 50%;
              `
            : ''}
    /* Using box-shadow to simulate border this doesn't affect height */
    border: none !important;
    box-shadow: 0 0 0 1px ${appearanceToBorderColor} inset;
    &:focus {
        outline: ${props => props.theme.analogue2} auto ${getSpacing(s1)};
    }
`;
export const StyledButton = styled.button<ButtonStyleProps>`
    ${ButtonStyle}
`;
export const StyledLabel = styled.label<ButtonStyleProps>`
    ${ButtonStyle}
`;
const StyledAnchor = styled.a<ButtonStyleProps>`
    ${ButtonStyle}
`;

/**
 * Layout component
 */

interface RenderingProps extends DisplayProps {
    color: Colors;
}

const Layout = ({
    icon,
    text,
    color,
    size,
    spinner,
    iconColor,
    iconWeight,
    spinnerColor,
    spinnerSize,
    iconPosition,
}: ContentProps & RenderingProps) => {
    return (
        <>
            {spinner ? (
                <StyledSpinner color={currentTheme()[spinnerColor || color]} size={getSpinnerSize(size, spinnerSize)} />
            ) : null}
            <Flex align="center" direction={iconPosition === IconPosition.Right ? 'row-reverse' : 'row'}>
                {hasValue(icon) ? (
                    <IconRenderer
                        icon={icon}
                        size={size}
                        color={iconColor || color}
                        onlyIcon={!hasValue(text)}
                        weight={iconWeight}
                    />
                ) : null}
                {hasValue(text) ? (
                    <TextRenderer
                        text={text}
                        color={color}
                        size={size}
                        vertical="bottom"
                        {...propsToTextMargin(icon, size, iconPosition)}
                    />
                ) : null}
            </Flex>
        </>
    );
};

const getSpinnerSize = (size: LayoutSize, spinnerSize?: SpacingIdentity) => {
    if (spinnerSize) {
        return spinnerSize;
    }

    switch (size) {
        case LayoutSize.Small:
            return s4;
        case LayoutSize.Medium:
            return s6;
        case LayoutSize.Large:
            return s8;
    }
};

const textToIconSpacing = (size: LayoutSize) => {
    switch (size) {
        case LayoutSize.Small:
        case LayoutSize.Medium:
            return s2;
        case LayoutSize.Large:
            return s4;
    }
};

const propsToTextMargin = (
    icon: ContentProps['icon'],
    size: LayoutSize,
    iconPosition: DisplayProps['iconPosition'],
) => {
    const spacing = hasValue(icon) ? textToIconSpacing(size) : s0;

    if (iconPosition === IconPosition.Right) {
        return {marginRight: spacing};
    }

    return {marginLeft: spacing};
};

/**
 * Text Rendering
 */
const TextRenderer = ({text, size, ...rest}: {text: string} & RenderingProps & TextProps) =>
    React.createElement(getTextComponent(size), {emphasized: true, ...rest}, text);

const getTextComponent = (size: LayoutSize) => {
    switch (size) {
        case LayoutSize.Small:
            return Caption;
        case LayoutSize.Medium:
            return Body;
        case LayoutSize.Large:
            return Subheader;
    }
};

/**
 * Icon Rendering
 *
 * Decide on the size of the icon-wrapper, add padding to it and have the icon take up all available space
 *
 */
const StyledIcon = styled.div<Omit<ButtonIconProps, 'size'>>`
    /* Override the normal icon size */
    height: 100%;
    width: 100%;
`;

const iconWrapperSize = ({size}: Pick<DisplayProps, 'size'>) => {
    switch (size) {
        case LayoutSize.Small:
            return getSpacing(s5);
        case LayoutSize.Medium:
            return getSpacing(s6);
        case LayoutSize.Large:
            return getSpacing(s8);
    }
};

export const IconWrapper = styled.div<Pick<DisplayProps, 'size'> & {onlyIcon: boolean}>`
    padding: ${getSpacing(s1)};
    height: ${iconWrapperSize};
    width: ${iconWrapperSize};
    box-sizing: border-box;
    display: flex;
`;
const IconRenderer = ({
    icon,
    size,
    color,
    weight,
    onlyIcon,
}: {
    icon: React.ComponentType<React.PropsWithChildren<ButtonIconProps>>;
    onlyIcon: boolean;
} & RenderingProps &
    Pick<ButtonIconProps, 'weight'>) => (
    <IconWrapper size={size} onlyIcon={onlyIcon}>
        <StyledIcon as={icon} fill={color} size={SelfScaling} weight={weight} />
    </IconWrapper>
);

const StyledSpinner = styled(Spinner)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & ~ * {
        /* Hide all other elements (but keep them for layout) while spinning */
        visibility: hidden;
    }
`;
