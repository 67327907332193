import {useMemo} from 'react';
import {type TypographyProps} from '@mui/material/Typography';

type ExtractedProp = 'data' | 'aria';
type ExtractedProps<T, E extends ExtractedProp> = {
    [K in keyof T as K extends `${E}-${string}` ? K : never]: T[K];
};

type DataProp = `data-${string}`;
export type DataProps = {[key: DataProp]: string};
type Props = {[key: string]: any};

function extractProps<T, E extends ExtractedProp>(props: Props, type: E): ExtractedProps<T, E> {
    return Object.keys(props).reduce((acc, key) => {
        if (key.startsWith(type)) {
            acc[key as keyof ExtractedProps<T, E>] = props[key];
        }
        return acc;
    }, {} as ExtractedProps<T, E>);
}

export const extractDataProps = <P extends Props>(props: P) => {
    return extractProps<P, 'data'>(props, 'data');
};

export const extractAriaProps = <P extends Props>(props: P) => {
    return extractProps<P, 'aria'>(props, 'aria');
};

/**
 * Utility hook to extract all data-attributes that are added to components
 */
export const useDataProps = (props: Props): DataProps => {
    return useMemo(() => {
        return extractDataProps(props);
    }, [props]);
};

/**
 * Utility hook to extract all aria-attributes that are added to components
 */
export const useAriaProps = <P extends Props>(props: P): ReturnType<typeof extractProps<P, 'aria'>> => {
    return useMemo(() => {
        return extractAriaProps<P>(props);
    }, [props]);
};

export const getEmphasizedFontWeight = (variant: TypographyProps['variant']) => {
    return variant === 'micro' ? '700' : '500';
};
