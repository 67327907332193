import React from 'react';
import styled from 'styled-components';
import {createStack, Button} from 'modern-famly';

import translate from 'signin-app/helpers/translate';
import {useScrollXPosition} from 'web-app/react/hooks/use-did-scroll';

export const Wrapper = styled(createStack({}))`
    width: 100%;
    flex: 1;
`;

export const CarouselContainer = styled.div`
    width: 100%;
    display: flex;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    &::-webkit-scrollbar {
        background: transparent;
    }
`;

export const CarouselSlot = styled.div`
    flex: 1 0 100%;
    scroll-snap-align: center;
`;

const SlideButtonContainer = styled(
    createStack({
        width: '100%',
        justifyContent: 'space-between',
        position: 'fixed',
        bottom: '0',
        left: '0',
        px: '40px',
        boxSizing: 'border-box',
        pb: '26px',
    }),
)``;

const NEXT = 'NEXT';
const PREV = 'PREV';
type Direction = typeof PREV | typeof NEXT;

type SwipeableViewProps = {
    children: React.ReactNode;
};

export const SwipeableView = (props: SwipeableViewProps) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const count = React.Children.count(props.children);
    const scrollPosition = useScrollXPosition({
        target: containerRef,
        debounce: 50,
    });

    const {isNextButtonDisabled, isPrevButtonDisabled} = React.useMemo(() => {
        if (count <= 1) {
            return {
                isNextButtonDisabled: true,
                isPrevButtonDisabled: true,
            };
        }

        if (!containerRef.current) {
            return {
                isNextButtonDisabled: count <= 1,
                isPrevButtonDisabled: true,
            };
        }

        const {scrollWidth, clientWidth} = containerRef.current;

        return {
            isNextButtonDisabled: scrollPosition === scrollWidth - clientWidth,
            isPrevButtonDisabled: scrollPosition === 0,
        };
    }, [count, scrollPosition]);

    const slide = (dir: Direction) => {
        if (!containerRef.current) {
            return;
        }

        const containerEl = containerRef.current;
        const containerWidth = containerEl.clientWidth;

        if (dir === PREV) {
            containerEl.scrollBy({
                left: -containerWidth,
                behavior: 'smooth',
            });
        }

        if (dir === NEXT) {
            containerEl.scrollBy({
                left: containerWidth,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Wrapper>
            <CarouselContainer ref={containerRef}>
                {React.Children.map(props.children, child => (
                    <CarouselSlot>{child}</CarouselSlot>
                ))}
            </CarouselContainer>
            <SlideButtonContainer>
                {!isPrevButtonDisabled && (
                    <Button
                        onClick={() => slide(PREV)}
                        text={translate('prev')}
                        variant="secondary"
                        icon="arrow_back"
                    />
                )}
                {!isNextButtonDisabled && (
                    <Button
                        ml="auto"
                        onClick={() => slide(NEXT)}
                        text={translate('next')}
                        variant="secondary"
                        icon="arrow_forward"
                    />
                )}
            </SlideButtonContainer>
        </Wrapper>
    );
};
