import React from 'react';
import {type ColorPalette, Stack} from 'modern-famly';

const DEFAULT_BADGE_SIZE = '48px';
const DEFAULT_BADGE_OFFSET = -8;

type BadgeProps = {
    children: React.ReactNode;
    backgroundColor: keyof ColorPalette;
    borderColor: keyof ColorPalette;
    // Represents the `right` property in this case
    offset?: number;
    zIndex?: number;
    index: number;
};

const Badge = ({zIndex = 0, offset = DEFAULT_BADGE_OFFSET, ...props}: BadgeProps) => {
    const calculatedOffset = offset + props.index * 32 + DEFAULT_BADGE_OFFSET;
    return (
        <Stack
            backgroundColor={props.backgroundColor}
            width={DEFAULT_BADGE_SIZE}
            height={DEFAULT_BADGE_SIZE}
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
            zIndex={zIndex}
            bottom={DEFAULT_BADGE_OFFSET}
            right={calculatedOffset}
            position="absolute"
            borderWidth="2px"
            borderStyle="solid"
            border={props.borderColor}
        >
            {props.children}
        </Stack>
    );
};

export {Badge};
export type {BadgeProps};
