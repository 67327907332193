import {createAction} from 'signin-app/redux/helpers';

const domain = 'CHILD_CHECKIN_IN';

export const {action: selectPickupRelation} = createAction(
    `${domain}/SELECT_PICKUP_RELATION`,
    (relationId: string) => ({relationId}),
);

export const {action: selectPickupHours} = createAction(`${domain}/SELECT_PICKUP_HOURS`, (hours: number) => ({hours}));

export const {action: selectPickupMinutes} = createAction(`${domain}/SELECT_PICKUP_MINUTES`, (minutes: number) => ({
    minutes,
}));

export const {action: initPickupTime} = createAction(
    `${domain}/INIT_PICKUP_TIME`,
    (hours: number, minutes: number) => ({
        hours,
        minutes,
    }),
);

export const {action: resetPickup} = createAction(`${domain}/RESET_PICKUP`, () => null);

export const {action: showPickupInfo} = createAction(`${domain}/SHOW_PICKUP_INFO`, () => null);

interface CheckInChildOptions {
    accessToken: string;
    childId: string;
    pickupRelationId: string;
    selectedHours: string;
    selectedMinutes: string;
    newPickupRelationName?: string;
    successMessage?: string;
    groupId?: string;
    pin?: string;
    goHomeWithChildId?: string;
    isCheckedIn: boolean;
}

export const {
    action: checkInChild,
    success: checkInChildSuccess,
    failed: checkInChildFailed,
} = createAction(
    `${domain}/CHECKIN`,
    (options: CheckInChildOptions) => ({options}),
    (response, pickupName: string) => ({response, pickupName}),
    (error: Error) => ({error}),
);

export const {action: addNewPickupRelation} = createAction(
    `${domain}/NEW_PICKUP_RELATION`,
    (addNew: boolean, pickupPersonName?: string) => ({
        addNew,
        pickupPersonName,
    }),
);
