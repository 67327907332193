import {Record, List} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';
import {type PinLoginResponse} from 'signin-app/pin/models';

import * as Actions from './actions';

export class PinState extends Record<{
    readonly pinHolder: string;
    readonly didInsertPin: boolean;
    readonly isLoggingIn: boolean;
    readonly people?: PinLoginResponse | undefined;
    readonly failedToLogIn: boolean;
}>({
    pinHolder: '',
    didInsertPin: false,
    isLoggingIn: false,
    people: {
        children: List(),
        employees: List(),
        loginId: '',
        canOpenDoor: false,
    },
    failedToLogIn: false,
}) {}

export default makeReducer<PinState>(
    [
        match(Actions.setPinValue, (state, action) => {
            return state.merge({
                pinHolder: action.payload.newValue,
            });
        }),
        match(Actions.pinLogin, state => {
            return state.merge({
                isLoggingIn: true,
            });
        }),
        match(Actions.pinLogout, () => new PinState()),
        match(Actions.pinLoginSuccess, (state, {payload}) => {
            return state.merge({
                isLoggingIn: false,
                people: payload.response,
                didInsertPin: true,
            });
        }),
        match(Actions.pinLoginFailed, state => {
            return state.merge({
                pinHolder: '',
                isLoggingIn: false,
                failedToLogIn: true,
            });
        }),
        match(Actions.updateChild, (state, {payload}) => {
            const updatedChild = payload.updatedChildProps.response;
            return state.updateIn(['people', 'children'], children => {
                return children.map(childCheckinOverview => {
                    if (childCheckinOverview.child.id !== updatedChild.childId) {
                        return childCheckinOverview;
                    }

                    const overviewWithUpdatedChild = childCheckinOverview.update('child', child =>
                        child.merge({
                            checkedIn: !updatedChild.checkoutTime,
                            goHomeWithChildId: updatedChild.goHomeWithChildId,
                            groupId: updatedChild.groupId,
                            pickupRelationId: updatedChild.pickupRelationId,
                            pickupTime: updatedChild.pickupTime,
                        }),
                    );

                    return overviewWithUpdatedChild;
                });
            });
        }),
        match(Actions.updateEmployee, (state, {payload}) => {
            // In case of checkout, the response is an array
            const updatedEmployee = Array.isArray(payload.updatedEmployeeProps.response)
                ? payload.updatedEmployeeProps.response[0]
                : payload.updatedEmployeeProps.response;

            const updatedState = state.updateIn(['people', 'employees'], employees =>
                employees.map(employee => {
                    if (employee.employee.id === updatedEmployee.employeeId) {
                        // Could not make it work with immutable objects
                        const updatedEmployeeObj = {
                            id: employee.employee.id,
                            employeeId: employee.employee.id,
                            groupId: employee.employee.groupId,
                            image: employee.employee.image,
                            name: employee.employee.name,
                            estimatedCheckoutTime: employee.employee.estimatedCheckoutTime,
                            checkedIn: !updatedEmployee.checkoutTime,
                            tempGroupId: employee.employee.tempGroupId,
                            checkoutTime: updatedEmployee.checkoutTime,
                        };

                        return {
                            ...employee,
                            employee: updatedEmployeeObj,
                        };
                    }
                    return employee;
                }),
            );

            return updatedState;
        }),
    ],
    new PinState(),
);
