import React from 'react';
import {useDispatch} from 'react-redux';
import {DropdownButton} from 'modern-famly';

import {logOut} from 'signin-app/login/actions';
import {useModal} from 'web-app/react/modal-context/modal-context';
import translate from 'signin-app/helpers/translate';

import {LogoutWarningModal} from './logout-warning-modal';

type Props = {
    showWarning?: boolean;
    siteName?: string;
    settings?: {label: string; onClick: VoidFunction}[];
    variant?: 'light' | 'dark';
};

export const SettingsDropdown = (props: Props) => {
    const dispatch = useDispatch();

    const {show, hide} = useModal({
        component: LogoutWarningModal,
        modalProps: {
            fullScreenOnMobile: false,
        },
    });

    const handleLogout = React.useCallback(() => dispatch(logOut.action()), [dispatch]);

    const handleClick = React.useCallback(() => {
        if (props.showWarning) {
            show({
                onModalClose: hide,
                onConfirm: () => {
                    hide();
                    handleLogout();
                },
                siteName: props.siteName ?? '',
            });
        } else {
            handleLogout();
        }
    }, [handleLogout, hide, show, props]);

    const options = React.useMemo(() => {
        const logout = {
            label: translate('adminLogout'),
            onClick: handleClick,
            ['data-e2e-id']: 'logout-button',
        };
        return props.settings ? [...props.settings, logout] : [logout];
    }, [props.settings, handleClick]);

    return (
        <DropdownButton
            variant={props.variant === 'dark' ? 'primary' : 'tertiary'}
            icon="more_horiz"
            aria-label={translate('settings')}
            data-e2e-id="settings-button"
            options={options}
        />
    );
};
