import gql from 'graphql-tag';

export const RemoteOpenDoor = gql`
    mutation RemoteOpenDoor($siteId: SiteId!, $loginId: LoginId!) {
        doorLocking {
            openDoor(siteId: $siteId, loginId: $loginId) {
                interval
            }
        }
    }
`;
