import {type IconProps, type IconWeight} from 'web-app/react/components/icons/types';
import {type Colors} from 'web-app/styleguide';
import {type SpacingIdentity} from 'web-app/styleguide/spacing';
import {hasValue} from 'web-app/util/typescript';

// eslint-disable-next-line no-restricted-syntax
export enum LayoutSize {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

// eslint-disable-next-line no-restricted-syntax
export enum IconPosition {
    Left = 'Left',
    Right = 'Right',
}
export interface DisplayProps {
    size: LayoutSize;
    iconColor?: Colors;
    iconWeight?: IconWeight;
    iconPosition?: IconPosition;
    textColor?: Colors;
    spinnerColor?: Colors;
    spinnerSize?: SpacingIdentity;
}
export interface ContentBaseProps {
    spinner?: boolean;
}

type OnlyIcon = ContentBaseProps & {
    icon: React.ComponentType<React.PropsWithChildren<IconProps>>;
    text?: undefined;
    circular?: boolean;
};
type OnlyText = ContentBaseProps & {icon?: undefined; text: string};
type TextAndIcon = ContentBaseProps & {icon?: React.ComponentType<React.PropsWithChildren<IconProps>>; text?: string};

export type ContentProps = OnlyIcon | OnlyText | TextAndIcon;

export const isOnlyIcon = (props: ContentProps): props is OnlyIcon => hasValue(props.icon) && !hasValue(props.text);

export const isOnlyText = (props: ContentProps): props is OnlyText => !hasValue(props.icon) && hasValue(props.text);
