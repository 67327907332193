import React from 'react';
import {Stack, Text, Avatar, AvatarGroup} from 'modern-famly';

import translate from 'signin-app/helpers/translate';
import {useTypedSelector} from 'signin-app/components/hooks';
import {type IEmployee} from 'signin-app/entities/staff/model';
import {getInitials} from 'signin-app/util/get-initials';
import * as CheckOutSelectors from 'signin-app/child/check-out/selectors';

import * as Selectors from './selectors';
import {CardsContainer, GroupItem, GroupType} from './group-item';

export const StaffSection = () => {
    const displayedStaffGroups = useTypedSelector(Selectors.displayedStaffGroups);
    const checkedInCounts = useTypedSelector(Selectors.checkedInCounts);
    const isDataLimited = useTypedSelector(CheckOutSelectors.limitedData);

    const totalCheckedInCount = React.useMemo(() => {
        return displayedStaffGroups.reduce((total, group) => total + checkedInCounts[group.groupId], 0);
    }, [checkedInCounts, displayedStaffGroups]);

    const checkedInStaff = React.useMemo(() => {
        const checkedIn = Array.from(displayedStaffGroups)
            .reduce((acc, item) => [...acc, ...item.employees], [] as IEmployee[])
            .filter(staff => staff.checkedIn);
        return checkedIn;
    }, [displayedStaffGroups]);

    return (
        <Stack gap={2} flexDirection="column">
            <Text variant="h6" color="n400" emphasized>
                {translate('staffRooms')}
            </Text>
            {totalCheckedInCount ? (
                <Stack
                    color="n400"
                    backgroundColor="n50"
                    px={3}
                    py={1}
                    borderRadius={32}
                    flex="0"
                    minHeight="48px"
                    width="fit-content"
                    alignItems="center"
                >
                    <AvatarGroup>
                        {checkedInStaff.map(staff => (
                            <Avatar
                                key={staff.id}
                                initials={getInitials(isDataLimited ? staff.name.firstName : staff.name.fullName)}
                                src={staff.image.small}
                                alt={staff.name.firstName}
                                size={32}
                            />
                        ))}
                    </AvatarGroup>
                    <Text variant="body-small" ml={2}>
                        {translate('staffCheckedInCount', {
                            number: totalCheckedInCount,
                        })}
                    </Text>
                </Stack>
            ) : null}
            <CardsContainer>
                {displayedStaffGroups.map(group => (
                    <GroupItem
                        key={group.groupId}
                        title={group.title}
                        href={`/staff/${group.groupId}`}
                        checkedInCount={checkedInCounts[group.groupId]}
                        groupType={GroupType.Staff}
                        groupId={group.groupId}
                    />
                ))}
            </CardsContainer>
        </Stack>
    );
};
