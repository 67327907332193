// eslint-disable-next-line no-restricted-syntax
enum TextTypes {
    Display = 'Display',
    Headline = 'Headline',
    Title = 'Title',
    Subheader = 'Subheader',
    Body = 'Body',
    Caption = 'Caption',
    Small = 'Small',
}

export default TextTypes;
