import React from 'react';
import classNames from 'classnames';
import RCTooltip from 'rc-tooltip';

import {Body} from 'web-app/react/components/text/text';
import {Colors} from 'web-app/styleguide/colors';
import {type InferProps} from 'web-app/util/typescript';
import {GlobalTooltipClassname} from 'web-app/react/global-styles/tooltip-style';

type RCTooltipProps = InferProps<typeof RCTooltip>;

export type Trigger = RCTooltipProps['trigger'];

export type TooltipProps = Omit<RCTooltipProps, 'overlayClassName' | 'children'> & {
    className?: string;
    children?: any;
    disablePointerEvents?: boolean; // If true, disables pointer events on the tooltip, preventing it from being hovered over or interacted with
};

const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = React.memo(
    ({overlay, className, children, disablePointerEvents, ...rest}) => {
        const formattedOverlay =
            typeof overlay === 'string' ? (
                <Body color={Colors.InvertedText} emphasized>
                    {overlay}
                </Body>
            ) : (
                overlay
            );

        return (
            <RCTooltip
                overlay={formattedOverlay}
                overlayStyle={disablePointerEvents ? {pointerEvents: 'none'} : {}}
                overlayClassName={classNames(GlobalTooltipClassname, className)}
                {...rest}
            >
                {children}
            </RCTooltip>
        );
    },
);

export default Tooltip;
