import createSelector from 'web-app/util/custom-create-selector';
import {type RootState} from 'signin-app/redux/main-reducer';
import RoutesMap from 'signin-app/routes/routes-map';
import {employeeEstimatedCheckoutTimeEnabled} from 'signin-app/groups/selectors';
import {isPinApp} from 'signin-app/login/selectors';
import {type IEmployee} from 'signin-app/entities/staff/model';
import {type PinEmployee} from 'signin-app/pin/models';
import {type RecordInstance} from 'web-app/react/entities/factory/reducer-factory';
import * as GroupSelectors from 'signin-app/groups/selectors';
import * as GroupHelpers from 'signin-app/groups/helpers';
import {type Times} from 'signin-app/components/list-times/types';

import {makeStaffSelector, type EmployeeIdProps, getPinEmployee} from '../selectors';

export const selectedHours = (state: RootState): number | undefined => state.employeeCheckIn.selectedLeaveHours;
export const selectedMinutes = (state: RootState): number | undefined => state.employeeCheckIn.selectedLeaveMinutes;
export const isCheckingIn = (state: RootState): boolean => state.employeeCheckIn.isCheckingIn;
export const selectedGroupId = (state: RootState): string => state.employeeCheckIn.selectedGroupId;

const entityStaffSelector = makeStaffSelector(RoutesMap.employeeCheckIn);

export const employee = createSelector<
    RootState,
    EmployeeIdProps,
    boolean,
    PinEmployee | undefined,
    RecordInstance<IEmployee>,
    PinEmployee | RecordInstance<IEmployee> | undefined
>(isPinApp, getPinEmployee, entityStaffSelector, (pinApp, pinEmployee, entityStaff) => {
    if (pinApp) {
        return pinEmployee;
    }
    return entityStaff;
});

export const canCheckIn = createSelector(
    selectedHours,
    selectedMinutes,
    selectedGroupId,
    employeeEstimatedCheckoutTimeEnabled,
    (hours, minutes, groupId, checkoutTimeEnabled) => {
        if (checkoutTimeEnabled) {
            return hours !== undefined && minutes !== undefined && groupId.length > 0;
        }

        return true;
    },
);

// Employees can select a time outside the opening hours
// between 9:00 - 23:45
const HOURS_START = 9;
const HOURS_END = 23;

const timeOptionsBase = Array.from(Array(HOURS_END - HOURS_START + 1)).reduce<Times[]>((acc, _, i) => {
    return acc.concat({
        hour: HOURS_START + i,
        minutes: [0, 15, 30, 45],
    });
}, []);

export const checkoutTimeOptions = createSelector(GroupSelectors.timezone, timezone => {
    return GroupHelpers.timesLaterThanNow(timeOptionsBase, timezone);
});
