import {createStack} from 'modern-famly';
import styled from 'styled-components';

export const MIN_GRID_CARD_SIZE = 110;
export const GRID_ROW_GAP = 32;
export const GRID_COLUMN_GAP = 16;
export const GRID_PADDING_HORIZONTAL = 16;

export const GroupGrid = styled(
    createStack({
        display: 'grid',
        columnGap: GRID_COLUMN_GAP / 4,
        rowGap: GRID_ROW_GAP / 4,
        justifyContent: 'space-between',
        // To avoid status badges being cut off ('pr' would be enough but 'px' looks better)
        px: GRID_PADDING_HORIZONTAL / 4,
    }),
)`
    grid-template-columns: repeat(auto-fill, ${MIN_GRID_CARD_SIZE}px);
`;
