import {Record, List} from 'immutable';

import {FRAMEWORKIDS} from 'web-app/react/modals-container/link-to-framework-modal/constants';
import {Refinement} from 'web-app/util/graphql/api-types';
import {hexToRGBString} from 'web-app/util/hex-to-rgb';
import {hasValue} from 'web-app/util/typescript';

// eslint-disable-next-line no-restricted-syntax
export enum Colors {
    Text = 'text',
    TextSecondary = 'textSecondary',
    TextDisabled = 'textDisabled',
    InvertedText = 'invertedText',
    InvertedTextSecondary = 'invertedTextSecondary',
    InvertedTextDisabled = 'invertedTextDisabled',
    Primary = 'primary',
    Secondary = 'secondary',
    Active = 'active',
    Accent1 = 'accent1',
    Accent2 = 'accent2',
    Accent3 = 'accent3',
    Analogue1 = 'analogue1',
    Analogue2 = 'analogue2',
    Analogue4 = 'analogue4',
}

// eslint-disable-next-line no-restricted-syntax
export enum ElementState {
    Active = 'active',
    Hover = 'hover',
}

// Do everything you can to not add more colors to this enum. It is a temporary tool that will,
// in turn, help us eliminate all one-off colors in Famly
// eslint-disable-next-line no-restricted-syntax
export enum OneOffColor {
    // This is really newLook primary, but TS won't let me reference it
    VibrantPurple = '#5C34A3',
    BudgetOrange = '#FF7705',
    SchedulePurple = '#EFE8F8',
    ScheduleLightGrey = '#F6F5F6',
    MissingAck = '#BD7D00',
}

type IRefinementColors = {[key in Refinement]: string};
export class RefinementColors extends Record<IRefinementColors>({
    [Refinement.EMERGING]: 'rgba(18,10,32,0.25)',
    [Refinement.DEVELOPING]: '#17CF48',
    [Refinement.SECURE]: '#0B8934',
}) {}

// eslint-disable-next-line no-restricted-syntax
export enum FrameworkColorId {
    EYFS = 'EYFS',
    CoEL = 'CoEL',
    CfE = 'CfE',
    Espira2018 = 'Espira2018',
}
type IFrameworkColors = {[key in FrameworkColorId]: List<string>};
export class FrameworkColors extends Record<IFrameworkColors>({
    [FrameworkColorId.EYFS]: List(),
    [FrameworkColorId.CoEL]: List(),
    [FrameworkColorId.CfE]: List(),
    [FrameworkColorId.Espira2018]: List(),
}) {}

interface IChildDevelopment {
    RefinementColors: RefinementColors;
    FrameworkColors: FrameworkColors;
}
export class ChildDevelopmentColors extends Record<IChildDevelopment>({
    RefinementColors: new RefinementColors(),
    FrameworkColors: new FrameworkColors(),
}) {
    public GetAreaColor(frameworkId: string, index?: number) {
        const colorCode = frameworkIdToColorCode(frameworkId);
        return hasValue(index) ? this.FrameworkColors.get(colorCode, List()).get(index, '') : '';
    }
    public GetRefinementColor(refinement: Refinement) {
        return this.RefinementColors.get(refinement, '');
    }
    public GetRefinementFontColor(refinement: Refinement) {
        switch (refinement) {
            case Refinement.EMERGING:
                return '#120A20';
            default:
                return '#FFF';
        }
    }
}

interface IColorConfiguration {
    ChildDevelopment: ChildDevelopmentColors;
    BookingColors: List<string>;
    PackageColor: string;
}

const getBookingOrPackageColor = (colorString: string, elementState?: ElementState) => {
    if (!elementState) {
        return colorString;
    }
    const rgbColor = hexToRGBString(colorString);
    const opacity = elementState === ElementState.Active ? '0.25' : '0.08';
    return `rgba(${rgbColor}, ${opacity})`;
};

const frameworkIdToColorCode = (frameworkId: string) => {
    switch (frameworkId) {
        case FRAMEWORKIDS.ORSI:
        case FRAMEWORKIDS.CfE:
        case FRAMEWORKIDS.GIRFEC:
        case FRAMEWORKIDS.PB3:
            return FrameworkColorId.CfE;
        case FRAMEWORKIDS.CoEL:
            return FrameworkColorId.CoEL;
        case FRAMEWORKIDS.ESPIRA2018:
            return FrameworkColorId.Espira2018;
        default:
            return FrameworkColorId.EYFS;
    }
};

export class ColorConfiguration extends Record<IColorConfiguration>({
    ChildDevelopment: new ChildDevelopmentColors(),
    BookingColors: List([
        '#60d0d8',
        '#319ade',
        '#f5b36a',
        '#b46af5',
        '#62e662',
        '#6a92f5',
        '#f56ae3',
        '#fa644d',
        '#ff679c',
        '#a0bdbd',
        '#597882',
        '#54b99e',
        '#7873b9',
        '#cdd056',
        '#b0f08e',
        '#a35553',
        '#dcddd3',
        '#d2d0ff',
        '#ffd11f',
        '#5a2bf7',
        '#5395c1',
        '#61ffb4',
        '#8a0064',
        '#D7D782',
        '#BAEE0D',
        '#ff683d',
        '#ef0044',
        '#ad0e4c',
        '#B51F88',
        '#B8D7EE',
        '#CE1212',
        '#ffd692',
        '#B5E6D6',
        '#D1BCD1',
        '#ff7a29',
    ]),
    PackageColor: '#C1C3C9',
}) {
    public GetBookingColor(color?: number | string, elementState?: ElementState) {
        if (color === undefined) {
            return this.GetPackageColor(elementState);
        }

        const parsedColor = typeof color === 'string' ? parseInt(color, 10) : color;

        if (isNaN(parsedColor)) {
            return this.GetPackageColor(elementState);
        }
        return this.GetNonPackageColor(parsedColor, elementState);
    }

    private GetNonPackageColor(color: number, elementState?: ElementState) {
        const bookingColor = this.BookingColors.get(color % this.BookingColors.size);
        if (!bookingColor) {
            return '';
        }
        return getBookingOrPackageColor(bookingColor, elementState);
    }
    private GetPackageColor(elementState?: ElementState) {
        const packageColor = this.PackageColor;
        if (!packageColor) {
            return '';
        }
        return getBookingOrPackageColor(packageColor, elementState);
    }
}

/**
 * @deprecated Prefer using values from the Modern Famly themes instead, as the colors there are white label proof.
 *
 * @example
 * Access the colors in styled components by using:
 *
 * ```tsx
 * const MyComponent = styled.div`
 *     background-color: ${props => props.theme.mf.colorPalette.n75};
 * `
 * ```
 *
 * or in React components by using the `useTheme` hook which can be imported from the `'modern-famly'` package:
 *
 * ```tsx
 * import {useTheme} from 'modern-famly';
 *
 * const MyComponent = () => {
 *     const modernFamlyTheme = useTheme();
 *
 *     return <div>The value of n75: {modernFamlyTheme.colorPalette.n75}</div>;
 * };
 * ```
 *
 */
export const NewColors = {
    Primary: {
        base: 'hsl(262, 52%, 42%)',
        t30: 'hsl(262, 52%, 60%)',
        t80: 'hsl(262, 52%, 90%)',
        t90: 'hsl(262, 52%, 96%)',
        s30: 'hsl(262, 52%, 30%)',
    },
    Analogue1: {
        base: 'hsl(270, 94%, 42%)',
    },
    Neutral: {
        s80: 'hsl(220, 9%, 20%)',
    },
};

export default Colors;
