import React from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import translate from 'signin-app/helpers/translate';
import {type IChild} from 'signin-app/entities/children/model';
import {pinHolder} from 'signin-app/pin/selectors';
import {type PinChild} from 'signin-app/pin/models';
import {
    ConfirmButton,
    ActionButton as CancelButton,
    BottomBarContainer,
    ConfirmButtonTypes,
} from 'signin-app/components/action-buttons';
import {isPinApp} from 'signin-app/login/selectors';
import RoutesMap from 'signin-app/routes/routes-map';
import * as ChildSelectors from 'signin-app/child/selectors';
import * as GroupSelectors from 'signin-app/groups/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';
import {isBeforeNow} from 'signin-app/groups/helpers';
import * as GlobalEventActions from 'signin-app/global-event/actions';
import {useDisplayName} from 'signin-app/helpers/use-display-name';

import * as Selectors from './selectors';
import * as Actions from './actions';

interface CheckInSubmitProps {
    accessToken: string;
    child: IChild | PinChild;
    isUpdatePage?: boolean;
}

export const CheckinSubmit: React.FC<CheckInSubmitProps> = props => {
    const {accessToken, child} = props;
    const selectedPickupRelationId = useTypedSelector(Selectors.selectedPickupRelationId);
    const selectedHours = useTypedSelector(Selectors.selectedHours);
    const selectedMinutes = useTypedSelector(Selectors.selectedMinutes);
    const pickupPersonName = useTypedSelector(state => Selectors.pickupPersonName(state, props));
    const isCheckingIn = useTypedSelector(Selectors.isCheckingIn);
    const pin = useTypedSelector(pinHolder);
    const {childId: childIdFromParams} = useParams<{childId: string}>();
    const canCheckIn = useTypedSelector(state => Selectors.canCheckIn(state, {childId: childIdFromParams}));
    const isPin = useTypedSelector(isPinApp);
    const dispatch = useDispatch();
    const timezone = useTypedSelector(GroupSelectors.timezone);
    const displayName = useDisplayName(child?.name.firstName, child?.name.lastName);

    const nextPersonUrl = useTypedSelector(state => ChildSelectors.nextPersonUrl(state, props));

    const nextPersonLink = React.useMemo(() => {
        if (isPin) {
            return nextPersonUrl === RoutesMap.pin ? null : nextPersonUrl;
        }
        return null;
    }, [isPin, nextPersonUrl]);

    const handleChildCheckin = React.useCallback(() => {
        const pickupName = selectedPickupRelationId ? '' : pickupPersonName;
        const successMessage = translate(child.checkedIn ? 'updateSignInSuccess' : 'signInSuccess', {
            personName: displayName,
        });

        const pastPickupTimeSelected = isBeforeNow(timezone, selectedHours, selectedMinutes);
        if (pastPickupTimeSelected) {
            dispatch(GlobalEventActions.updateError.action(translate('updatePickupTimeError')));
            return;
        }

        dispatch(
            Actions.checkInChild.action({
                accessToken,
                childId: child.id,
                pickupRelationId: selectedPickupRelationId,
                selectedHours,
                selectedMinutes,
                newPickupRelationName: pickupName,
                successMessage,
                groupId: child.groupId,
                pin,
                isCheckedIn: child.checkedIn,
            }),
        );
    }, [
        selectedPickupRelationId,
        pickupPersonName,
        child,
        selectedHours,
        selectedMinutes,
        accessToken,
        pin,
        dispatch,
        timezone,
        displayName,
    ]);

    return (
        <BottomBarContainer>
            {nextPersonLink ? <CancelButton text={translate('skip')} link={nextPersonLink} /> : null}

            <ConfirmButton
                type={ConfirmButtonTypes.SIGN_IN}
                isLoading={isCheckingIn}
                onClick={handleChildCheckin}
                text={child.checkedIn ? translate('updatePickup') : translate('signIn', {personName: displayName})}
                disabled={!canCheckIn}
                dataE2EId="child-check-in-confirm-button"
                isOnlyButton={!nextPersonLink && isPin}
            />
        </BottomBarContainer>
    );
};
