declare const navigator: any;
import {StatusBar, Style} from '@capacitor/status-bar';

import PlatformHelper, {Store} from 'web-app/helpers/platform-helper';
import {currentTheme} from 'web-app/styleguide';
import {hslStringToRgb, isHSLA} from 'web-app/util/hsl-helpers';

// Taken from https://github.com/apache/cordova-plugin-media-capture/blob/master/www/CaptureError.js
export const captureError = {
    // Camera or microphone failed to capture image or sound.
    CAPTURE_INTERNAL_ERR: 0,
    // Camera application or audio capture application is currently serving other capture request.
    CAPTURE_APPLICATION_BUSY: 1,
    // Invalid use of the API (e.g. limit parameter has value less than one).
    CAPTURE_INVALID_ARGUMENT: 2,
    // User exited camera application or audio capture application before capturing anything.
    CAPTURE_NO_MEDIA_FILES: 3,
    // User denied permissions required to perform the capture request.
    CAPTURE_PERMISSION_DENIED: 4,
    // The requested capture operation is not supported.
    CAPTURE_NOT_SUPPORTED: 20,
} as const;

type VideoFile = {
    fullPath: string;
    localURL: string;
};

export const androidVideoHandler = () => {
    // `navigator.device` is defined by cordova-plugin-media-capture
    return new Promise<VideoFile[]>((resolve, reject) => {
        navigator.device.capture.captureVideo(resolve, reject, {
            limit: 1,
            // The video capture on Amazon devices crashes if the duration is not set.
            duration: PlatformHelper.store() === Store.AMAZONAPPSTORE ? 3600 : undefined,
        });
    });
};

/*
 * Status bar operations
 */
export const setStatusBar = (style: Style) => {
    if (!PlatformHelper.hasDynamicStatusBar()) {
        return new Promise<void>(resolve => resolve());
    }
    return Promise.all([
        StatusBar.setStyle({style}),
        PlatformHelper.isAndroid()
            ? StatusBar.setBackgroundColor({color: styleToBackgroundColor(style)})
            : Promise.resolve(),
    ]);
};

const styleToBackgroundColor = (style: Style) => {
    const chromeColor = currentTheme().chromeColor;
    const hexChromeColor = isHSLA(chromeColor) ? hslStringToRgb(chromeColor) : chromeColor;

    switch (style) {
        case Style.Light:
            return currentTheme().invertedText;
        case Style.Dark:
            return hexChromeColor;
        default:
            return hexChromeColor;
    }
};
