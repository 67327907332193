import {BusybeesTheme as BusybeesMFTheme} from 'modern-famly';

import BaseTheme from './base';

const BusybeesTheme = BaseTheme.merge({
    title: 'Busybees',
    chromeColor: BusybeesMFTheme.colorPalette.brandColor,
    mf: BusybeesMFTheme,
});

export default BusybeesTheme;
