import React from 'react';
import ReactDOM from 'react-dom';
import {ConnectedRouter} from 'connected-react-router';

import {ReduxContextWrapper, ApolloContextWrapper} from 'web-app/react/contexts/common';
import ContextProvider from 'signin-app/components/context';
import ScrollToTopOnRouteChange from 'root/shared/components/scroll-to-top-route';
import setupSentry from 'signin-app/setup/sentry-setup';

import {client} from './api/clients/apollo-client';
import store, {history} from './redux/store';
import App from './app';

// The Sentry SDK should be initialized as early as possible on app startup.
setupSentry();

const RouterContextWrapper = (InnerComponent, history) => props => {
    return (
        <ConnectedRouter history={history}>
            <InnerComponent {...props} />
        </ConnectedRouter>
    );
};

const ContextProviderWrapped = () => (
    <ContextProvider>
        <App />
    </ContextProvider>
);

const ScrollToTopWrapped = ScrollToTopOnRouteChange(ContextProviderWrapped);

// RouterWrapped must be InnerComponent of ReduxContextWrapper
const RouterWrapped = RouterContextWrapper(ScrollToTopWrapped, history);
const ReduxWrapped = ReduxContextWrapper(RouterWrapped, store);
const ApolloWrapped = ApolloContextWrapper(ReduxWrapped, client);

ReactDOM.render(
    <ApolloWrapped />,
    document.getElementById('root') || document.createElement('div'), // for testing purposes
);

if (module.hot) {
    module.hot.accept();
}

export default ApolloWrapped;
