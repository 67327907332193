import {type GlobalEventState} from 'web-app/react/global-event/helpers/create-reducer';

export interface StateProps {
    events: GlobalEventState['events'];
    isShowingModals: boolean;
}

// eslint-disable-next-line no-restricted-syntax
export enum EventSize {
    Default,
    Large,
}
