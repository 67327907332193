import React from 'react';

import {limitedData} from 'signin-app/child/check-out/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';

export const useDisplayName = (firstName?: string, lastName?: string) => {
    const isDataLimited = useTypedSelector(limitedData);

    const displayName = React.useMemo(() => {
        if (!firstName) {
            return '';
        }

        if (isDataLimited || !lastName) {
            return firstName;
        }

        // Example: Aaron H.
        return `${firstName} ${lastName.charAt(0)}.`;
    }, [isDataLimited, firstName, lastName]);

    return displayName;
};
