import React from 'react';
import {type RouteComponentProps} from 'react-router-dom';
import {Stack, Box, Text} from 'modern-famly';
import {type List} from 'immutable';

import translate from 'signin-app/helpers/translate';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import PersonButton from 'signin-app/components/person-button';
import Header from 'signin-app/components/header';
import {staffGroup} from 'signin-app/staff-group/selectors';
import * as PrivateRouteSelectors from 'signin-app/components/private-route/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';
import {StyledPageContainer} from 'signin-app/components/containers';
import {useElementDimensions, useGetPages} from 'signin-app/group/hooks';
import {SwipeableView} from 'signin-app/group/swipe-view';
import {type RecordInstance} from 'web-app/react/entities/factory/reducer-factory';
import {type IEmployee} from 'signin-app/entities/staff/model';
import {GroupGrid} from 'signin-app/components/group-grid';

type GroupRouteProps = RouteComponentProps<{staffGroupId: string}>;

export const StaffGroup: React.FC<GroupRouteProps> = props => {
    const group = useTypedSelector(state => staffGroup(state, {staffGroupId: props.match.params.staffGroupId}));
    const isFetching = useTypedSelector(PrivateRouteSelectors.isPrefetching);
    const items = React.useMemo(() => {
        if (!group) {
            return [];
        }

        return group.employees;
    }, [group]) as List<RecordInstance<IEmployee>>;
    const {dimensions, ref: swipeAreaRef} = useElementDimensions();

    const {pages} = useGetPages({
        items,
        width: dimensions?.width,
        height: dimensions?.height,
    });

    if (isFetching) {
        return <Spinner centered margined />;
    }

    return (
        <StyledPageContainer>
            <Header
                centerElement={
                    <Text variant="h4" color="n400" textAlign="center">
                        {group?.title}
                    </Text>
                }
            />
            <Stack flexDirection="column" gap={8} mt={6} flex={1}>
                {!(group && group.employees.size) ? (
                    <Box textAlign="center" color="n400" mt={8}>
                        {translate('noEmployees')}
                    </Box>
                ) : (
                    <Stack ref={swipeAreaRef} flex={1}>
                        {/* key={Date.now()} to force a re-render when the pages change */}
                        <SwipeableView key={Date.now()}>
                            {pages.map(({items, pageId}) => (
                                <GroupGrid key={pageId}>
                                    {items.map(employee => {
                                        return (
                                            <PersonButton
                                                key={employee.id}
                                                linkTo={
                                                    employee.checkedIn
                                                        ? `/employee/${employee.id}/checkout`
                                                        : `/employee/${employee.id}/checkin`
                                                }
                                                person={employee}
                                            />
                                        );
                                    })}
                                </GroupGrid>
                            ))}
                        </SwipeableView>
                    </Stack>
                )}
            </Stack>
        </StyledPageContainer>
    );
};
