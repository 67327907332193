import createSelector from 'web-app/util/custom-create-selector';
import {type RootState} from 'signin-app/redux/main-reducer';
import {isPinApp, isLoggedIn, isQrSignin} from 'signin-app/login/selectors';
import * as GoHomeWithSelectors from 'signin-app/go-home-with/selectors';
import {PinLoadingState} from 'web-app/react/components/pin/pin';

export const didInsertPin = (state: RootState) => state.pin.didInsertPin;
export const pinHolder = (state: RootState) => state.pin.pinHolder;
export const pinLoginId = (state: RootState) => state.pin.people?.loginId;
export const pinCanOpenDoor = (state: RootState) => state.pin.people?.canOpenDoor;
export const isLoggingIn = (state: RootState) => state.pin.isLoggingIn;
export const pinLoginResponse = (state: RootState) => state.pin.people;
const failedToLogIn = (state: RootState) => state.pin.failedToLogIn;

export const pinState = createSelector(isLoggingIn, failedToLogIn, (isLoggingIn, failedToLogIn) => {
    if (isLoggingIn) {
        return PinLoadingState.Loading;
    } else if (failedToLogIn) {
        return PinLoadingState.Error;
    } else {
        return PinLoadingState.Success;
    }
});

const pinChildById = createSelector(
    pinLoginResponse,
    (_, props) => props.childId,
    (people, childId) => {
        return people?.children.find(child => child.child.id === childId);
    },
);

const goHomeWithChildId = createSelector(pinChildById, pinChild => {
    return pinChild?.child.goHomeWithChildId;
});

export const goHomeWithChild = createSelector(
    GoHomeWithSelectors.groups,
    goHomeWithChildId,
    (groups, goHomeWithChildId) => {
        let child;

        groups.some(group => {
            child = (group as any).children.find(child => child.id === goHomeWithChildId);
            return Boolean(child);
        });

        return child;
    },
);

export const isCompletelyLoggedIn = createSelector<RootState, boolean, boolean, boolean>(
    isLoggedIn,
    didInsertPin,
    (hasToken, hasPin) => hasToken && hasPin,
);

export const isAuthorized = createSelector<RootState, boolean, boolean, boolean, boolean>(
    isPinApp,
    didInsertPin,
    isLoggedIn,
    (pinApp, hasPin, hasToken) => {
        return pinApp ? hasPin && hasToken : hasToken;
    },
);

export const redirectUrl = createSelector(
    isLoggedIn,
    isQrSignin,
    isPinApp,
    didInsertPin,
    pinLoginResponse,
    (loggedIn, qrSignIn, pinApp, hasPin, pinPeople): string => {
        if (loggedIn && qrSignIn && !pinApp) {
            return '/qr';
        } else if (loggedIn && pinApp) {
            if (hasPin && pinPeople) {
                const loggedInEmployee = pinPeople.employees.first();
                const loggedInChild = pinPeople.children.first();

                if (loggedInChild) {
                    if (loggedInChild.child.checkedIn) {
                        return `/child/${loggedInChild.child.id}/checkout`;
                    } else {
                        return `/child/${loggedInChild.child.id}/checkin`;
                    }
                } else if (loggedInEmployee) {
                    if (loggedInEmployee.employeeCheckin) {
                        return `/employee/${loggedInEmployee.employee.employeeId}/checkout`;
                    } else {
                        return `/employee/${loggedInEmployee.employee.employeeId}/checkin`;
                    }
                    // The entered PIN was correct but backend didn't send data either for a child or an employee
                } else {
                    return '';
                }
            } else {
                return '';
            }
        } else {
            return '/';
        }
    },
);
