// eslint-disable-next-line no-restricted-syntax
enum Timings {
    default = 'default',
    easeIn = 'easeIn',
    easeInOut = 'easeInOut',
    overshoot = 'overshoot',
    undershoot = 'undershoot',
}

// eslint-disable-next-line no-restricted-syntax
enum TimeUnits {
    ms = 'ms',
    s = 's',
}

export interface TimingConfiguration {
    unit: TimeUnits;
    functions: {[key in Timings]: string};
}

export default {
    unit: TimeUnits.ms,
    functions: {
        // https://github.com/expo/react-apple-easing/blob/master/AppleEasing.js
        default: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
        easeIn: 'cubic-bezier(0.42, 0, 1, 1)',
        easeInOut: 'cubic-bezier(0.42, 0, 0.58, 1)',
        overshoot: 'cubic-bezier(.09,.9,.52,1.15)',
        undershoot: 'cubic-bezier(.86,-0.2,.94,.39)',
    },
} as TimingConfiguration;
