import React from 'react';
import {css, keyframes} from 'styled-components';
import styled from 'styled-components';

import {media} from 'web-app/styleguide/utils';
import range from 'web-app/util/range';

// eslint-disable-next-line no-restricted-syntax
export enum PillAppearance {
    Colorful = 'Colorful',
    Simple = 'Simple',
}
// eslint-disable-next-line no-restricted-syntax
export enum PillState {
    Success = 'Success',
    Error = 'Error',
    Loading = 'Loading',
}

interface ProgressPillsProps {
    activeCount: number;
    count: number;
    pillState?: PillState;
    appearance: PillAppearance;
    size?: number;
    spacing?: number;
    className?: string;
}
const ProgressPills: React.FC<React.PropsWithChildren<ProgressPillsProps>> = ({
    activeCount,
    count,
    pillState,
    appearance,
    size,
    spacing,
    className,
}) => (
    <ProgressPillContainer appearance={appearance} className={className}>
        {range(0, count).map(i => (
            <ProgressPill
                pillState={pillState}
                key={i}
                active={activeCount > i}
                index={i}
                count={count}
                size={size}
                spacing={spacing}
            />
        ))}
    </ProgressPillContainer>
);

export default ProgressPills;

const ProgressPillErrorAnimation = keyframes`
    10%, 90% {
        transform: translate3d(-2px, 0, 0);
    }
    20%, 80% {
        transform: translate3d(4px, 0, 0);
    }
    30%, 50%, 70% {
        transform: translate3d(-8px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(8px, 0, 0);
    }
`;

const ProgressPillLoadingAnimation = keyframes`
    40%, 100% {
        transform: translate3d(0, 0, 0);
    }
    0% {
        transform: translate3d(0, -20px, 0);
    }
`;

const LOADING_DURATION = 300; // ms

export const ProgressPillContainer = styled('div')<{appearance: PillAppearance}>`
    text-align: center;
    ${props =>
        props.appearance === PillAppearance.Simple &&
        css`
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        `}
    ${media.mobile`
        justify-content: center;
    `}
    ${media.mobileSideways`
        justify-content: center;
    `}
`;

const DEFAULT_SIZE = 30;
export const ProgressPill = styled('div').attrs<{pillState?: PillState; active: boolean}>(props => ({
    /**
     * We've discovered some apparent performance gains with styled components writing static CSS, and then
     * applying data-* tags which the style rules can apply to.
     *
     * This work spawned from an investigation that showed that the first time the `keyframes` animations where fired
     * the component render took unusually long. We discovered that inserting the `keyframes` into the `css` takes a bit of
     * time, which can make interactions seem unresponsive if it happens dynamically. Instead we've decided to "pre load"
     * all the CSS instead.
     */
    ['data-pill-state']: props.pillState,
    ['data-pill-active']: props.active,
}))<{
    active: boolean;
    index: number;
    count: number;
    pillState?: PillState;
    size?: number;
    spacing?: number;
}>`
    display: inline-block;
    height: ${props => props.size || DEFAULT_SIZE}px;
    width: ${props => props.size || DEFAULT_SIZE}px;
    border-radius: 20px;
    background: ${props => props.theme.delimiter};
    position: relative;
    transform: translate3d(0, 0, 0);
    transition: transform 200ms ${props => props.theme.timings.functions.default};
    &:not(:last-child) {
        margin-right: ${props => props.spacing || 16}px;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${props => props.theme.active};
        border-radius: 20px;
        transform: scale3d(0, 0, 0);
    }
    &[data-pill-active='true'] {
        &:after {
            transform: scale3d(1, 1, 1);
        }
    }

    &[data-pill-state='${PillState.Error}'] {
        animation: ${ProgressPillErrorAnimation} 600ms ${props => props.theme.timings.functions.default};
    }

    ${media.mobile`
        height: ${props => props.size || 24}px;
        width: ${props => props.size || 24}px;
        &:not(:last-child) {
            margin-right: ${props => props.spacing || 24}px;
        }
    `}
    ${media.mobileSideways`
        height: ${props => props.size || 18}px;
        width: ${props => props.size || 18}px;
        &:not(:last-child) {
            margin-right: ${props => props.spacing || 18}px;
        }
    `}

    &[data-pill-state="${PillState.Loading}"] {
        animation: ${ProgressPillLoadingAnimation} ${props => LOADING_DURATION * props.count * 0.5}ms infinite
            alternate-reverse ${props => props.theme.timings.functions.default};
        animation-delay: ${props => props.index * LOADING_DURATION * 0.35 + 500}ms;
    }
`;
