import * as React from 'react';

import {type IChild} from 'signin-app/entities/children/model';
import {type PinChild} from 'signin-app/pin/models';
import {type RecordInstance} from 'web-app/react/entities/factory/reducer-factory';
import {getInitials} from 'signin-app/util/get-initials';
import {useDisplayName} from 'signin-app/helpers/use-display-name';

type CheckOutChildType = RecordInstance<IChild> | PinChild;

const CheckOutChildContext = React.createContext<{child: CheckOutChildType; displayName: string; initials: string}>(
    null!,
);

type CheckOutChildProviderProps = {children: React.ReactNode; child: CheckOutChildType};

function CheckOutChildProvider({children, child}: CheckOutChildProviderProps) {
    const displayName = useDisplayName(child?.name.firstName, child?.name.lastName);
    const initials = React.useMemo(() => getInitials(displayName), [displayName]);

    return (
        <CheckOutChildContext.Provider value={{child, displayName, initials}}>{children}</CheckOutChildContext.Provider>
    );
}

function useCheckOutChild() {
    const context = React.useContext(CheckOutChildContext);

    if (context === undefined) {
        throw new Error('useCheckOutChild must be used within a CheckOutChildProvider');
    }

    return context;
}

export {CheckOutChildProvider, useCheckOutChild};
