import React from 'react';
import styled from 'styled-components';

import {EspiraIcon} from 'web-app/react/components/icons/custom';
import {FamlyLogoMark, FamlyLogoFullColoredBG} from 'web-app/react/components/icons/branding';
import {hexToRGBString} from 'web-app/util/hex-to-rgb';
import {NewLookTheme} from 'web-app/styleguide/themes';
import EspiraTheme from 'web-app/styleguide/themes/espira';
import KidoTheme from 'web-app/styleguide/themes/kido';
import KidoLogo from 'root/public/img/kido/kido-transparent.png';
import KidoLogoForWhiteBg from 'root/public/img/kido/kido-blue.png';
import BrightHorizonsTheme from 'web-app/styleguide/themes/bright-horizons';
import BrightHorizonsLogo from 'root/public/img/bright-horizons/logo.svg';
import BrightHorizonsLoginLogo from 'root/public/img/bright-horizons/login-logo.svg';
import KindredLogo from 'root/public/img/kindred/logo-colored-bg.svg';
import KindredLogoFroWhiteBg from 'root/public/img/kindred/logo-white-bg.svg';
import KindredTheme from 'web-app/styleguide/themes/kindred';
import MonkeyPuzzleLogo from 'root/public/img/monkeypuzzle/logo.svg';
import MonkeyPuzzleTheme from 'web-app/styleguide/themes/monkeypuzzle';
import GrandirUKLogo from 'root/public/img/grandiruk/GrandirLogoMark-DarkBG.svg';
import GrandirUKTheme from 'web-app/styleguide/themes/grandiruk';
import NuffieldHealthLogo from 'root/public/img/nuffieldhealth/NuffieldLogo-DarkBG.svg';
import NuffieldHealthTheme from 'web-app/styleguide/themes/nuffieldhealth';
import NuffieldHealthLogoForWhiteBg from 'root/public/img/nuffieldhealth/NuffieldLogo-WhiteBG.svg';
import NFamilyClubLogo from 'root/public/img/nfamilyclub/logo-colored-bg.svg';
import NFamilyClubTheme from 'web-app/styleguide/themes/nfamilyclub';
import NFamilyClubLogoForWhiteBg from 'root/public/img/nfamilyclub/logo-white-bg.svg';
import KinderzimmerUKLogo from 'root/public/img/kinderzimmeruk/KinderzimmerUKLogoMark.svg';
import KinderzimmerUKTheme from 'web-app/styleguide/themes/kinderzimmeruk';
import CpcTreehouseLogo from 'root/public/img/cpc-treehouse/CpcTreehouseLogoMark.svg';
import CpcTreehouseTheme from 'web-app/styleguide/themes/cpc-treehouse';
import CpcOrchardLogo from 'root/public/img/cpc-orchard/CpcOrchardLogoMark.svg';
import CpcOrchardsTheme from 'web-app/styleguide/themes/cpc-orchards';
import BusyBeesLogo from 'root/public/img/busybees/BusyBeesNavBarLogo.svg';
import BusyBeesLogoForWhiteBg from 'root/public/img/busybees/BusyBeesLogo.svg';
import BusyBeesTheme from 'web-app/styleguide/themes/busybees';

import {type WhiteLabelConfiguration} from './types';

/**
 * Famly (default) configuration
 */
export const makeFamlyConfiguration = (): WhiteLabelConfiguration => {
    const theme = NewLookTheme;
    return {
        ProductName: 'Famly',
        MobileAppUrl: 'com.famly.famlyapp',
        PinFontColor: theme.chromeColor,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: `rgba(${hexToRGBString(theme.text)}, 0.5)`,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: theme.chromeColor,
        CircleAndDotColor: theme.primary,
        PinLogo: FamlyPinLogoComponent,
        LoginLogo: FamlyLoginLogoComponent,
        LogoForWhiteBg: FamlyLoginLogoComponent,
        WithSiteImage: true,
    };
};

const FamlyPinLogoComponent = () => <FamlyLogoFullColoredBG width={120} />;

const FamlyLoginLogoComponent = () => <FamlyLogoMark width="120" />;

/**
 * White labels logo component
 */

const StyledImgLogo = styled.img``;

/**
 * Espira configuration
 */
export const makeEspiraConfiguration = (): WhiteLabelConfiguration => {
    const theme = EspiraTheme;
    return {
        ProductName: 'Spireportalen',
        MobileAppUrl: 'no.spireportalen.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.text,
        PrimaryBackgroundColor: '#669600',
        SecondaryBackgroundColor: '#80BC00',
        CircleAndDotColor: theme.primary,
        PinLogo: EspiraLogoComponent,
        LoginLogo: EspiraLogoComponent,
        LogoForWhiteBg: EspiraLogoComponent,
        WithSiteImage: true,
        p500: '#491315',
        p400: '#A2282F',
        p300: '#B82D35',
        p200: '#FDF7F7',
    };
};

const StyledEspiraIcon = styled(EspiraIcon)`
    height: 144px;
    width: 144px;
`;

const EspiraLogoComponent = () => (
    /**
     * The size, height, and width props are overwritten by styled components.
     * They're there to satisfy TS and to properly size the icon within its' viewbox.
     */
    <StyledEspiraIcon height={144} width={144} />
);

/**
 * Kido configuration
 */
export const makeKidoConfiguration = (): WhiteLabelConfiguration => {
    const theme = KidoTheme;

    return {
        ProductName: 'Kido',
        MobileAppUrl: 'school.kido.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: KidoLogoComponent,
        LoginLogo: KidoLogoComponent,
        LogoForWhiteBg: () => KidoLogoComponent({isWhiteBg: true}),
        WithSiteImage: true,
    };
};

const KidoLogoComponent = ({isWhiteBg}: {isWhiteBg?: boolean}) => {
    return <StyledImgLogo width={224} src={isWhiteBg ? KidoLogoForWhiteBg : KidoLogo} />;
};

/**
 * Bright Horizons configuration
 */
export const makeBrightHorizonsConfiguration = (): WhiteLabelConfiguration => {
    return {
        ProductName: 'Bright Horizons',
        MobileAppUrl: 'com.brighthorizons.app',
        PinFontColor: BrightHorizonsTheme.text,
        PinShadowColor: BrightHorizonsTheme.invertedText,
        PinPillColor: BrightHorizonsTheme.mf.colorPalette.p300,
        PinPillEmptyColor: BrightHorizonsTheme.invertedText,
        PrimaryColor: BrightHorizonsTheme.chromeColor,
        PrimaryBackgroundColor: '#1F3E4E',
        SecondaryBackgroundColor: '#004A61',
        CircleAndDotColor: BrightHorizonsTheme.chromeColor,
        PinLogo: BrightHorizonsLogoComponent,
        LoginLogo: BrightHorizonsLogoComponent,
        LogoForWhiteBg: () => BrightHorizonsLogoComponent({isWhiteBg: true}),
        WithSiteImage: true,
    };
};

const BrightHorizonsLogoComponent = ({isWhiteBg}: {isWhiteBg?: boolean}) => {
    return <StyledImgLogo width={252} height={96} src={isWhiteBg ? BrightHorizonsLoginLogo : BrightHorizonsLogo} />;
};

/**
 * Kindred configuration
 */
export const makeKindredConfiguration = (): WhiteLabelConfiguration => {
    const theme = KindredTheme;

    return {
        ProductName: 'Kindred',
        MobileAppUrl: 'com.kindred.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: KindredLogoComponent,
        LoginLogo: KindredLogoComponent,
        LogoForWhiteBg: () => KindredLogoComponent({isWhiteBg: true}),
        WithSiteImage: true,
    };
};

const KindredLogoComponent = ({isWhiteBg}: {isWhiteBg?: boolean}) => {
    return <StyledImgLogo height={160} src={isWhiteBg ? KindredLogoFroWhiteBg : KindredLogo} />;
};

/**
 * MonkeyPuzzle configuration
 */
export const makeMonkeyPuzzleConfiguration = (): WhiteLabelConfiguration => {
    const theme = MonkeyPuzzleTheme;

    return {
        ProductName: 'Monkey Puzzle',
        MobileAppUrl: 'com.monkeypuzzle.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: MonkeyPuzzleLogoComponent,
        LoginLogo: MonkeyPuzzleLogoComponent,
        LogoForWhiteBg: MonkeyPuzzleLogoComponent,
        WithSiteImage: true,
    };
};

const MonkeyPuzzleLogoComponent = () => {
    return <StyledImgLogo height={156} src={MonkeyPuzzleLogo} />;
};

/**
 * Grandir UK configuration
 */
export const makeGrandirUKConfiguration = (): WhiteLabelConfiguration => {
    const theme = GrandirUKTheme;

    return {
        ProductName: 'Grandir UK',
        MobileAppUrl: 'com.grandiruk.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: GrandirUKLogoComponent,
        LoginLogo: GrandirUKLogoComponent,
        LogoForWhiteBg: GrandirUKLogoComponent,
        WithSiteImage: true,
    };
};

const GrandirUKLogoComponent = () => {
    return <StyledImgLogo width={88} src={GrandirUKLogo} />;
};

/**
 * Nuffield Health configuration
 */
export const makeNuffieldHealthConfiguration = (): WhiteLabelConfiguration => {
    const theme = NuffieldHealthTheme;

    return {
        ProductName: 'Nuffield Health Nursery',
        MobileAppUrl: 'com.nuffieldhealth.nursery',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: theme.chromeColor,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: NuffieldHealthLogoComponent,
        LoginLogo: NuffieldHealthLogoComponent,
        LogoForWhiteBg: () => NuffieldHealthLogoComponent({isWhiteBg: true}),
        WithSiteImage: true,
        p500: '#003D00',
        p400: '#00A200',
        p300: '#00B200',
        p200: '#F5FFF5',
    };
};

const NuffieldHealthLogoComponent = ({isWhiteBg}: {isWhiteBg?: boolean}) => {
    return <StyledImgLogo width={260} src={isWhiteBg ? NuffieldHealthLogoForWhiteBg : NuffieldHealthLogo} />;
};

/**
 * NFamilyClub configuration
 */
export const makeNFamilyClubConfiguration = (): WhiteLabelConfiguration => {
    const theme = NFamilyClubTheme;

    return {
        ProductName: 'N Family Club',
        MobileAppUrl: 'com.nfamilyclub.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: NFamilyClubLogoComponent,
        LoginLogo: NFamilyClubLogoComponent,
        LogoForWhiteBg: () => NFamilyClubLogoComponent({isWhiteBg: true}),
        WithSiteImage: true,
    };
};

const NFamilyClubLogoComponent = ({isWhiteBg}: {isWhiteBg?: boolean}) => {
    return <StyledImgLogo height={128} src={isWhiteBg ? NFamilyClubLogoForWhiteBg : NFamilyClubLogo} />;
};

/**
 * KinderzimmerUK configuration
 */
export const makeKinderzimmerUKConfiguration = (): WhiteLabelConfiguration => {
    const theme = KinderzimmerUKTheme;

    return {
        ProductName: 'Kinderzimmer',
        MobileAppUrl: 'com.kinderzimmeruk.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: KinderzimmerUKLogoComponent,
        LoginLogo: KinderzimmerUKLogoComponent,
        LogoForWhiteBg: KinderzimmerUKLogoComponent,
        WithSiteImage: true,
    };
};

const KinderzimmerUKLogoComponent = () => {
    return <StyledImgLogo height={128} src={KinderzimmerUKLogo} />;
};

/**
 * CPC Treehouse configuration
 */
export const makeCpcTreehouseConfiguration = (): WhiteLabelConfiguration => {
    const theme = CpcTreehouseTheme;

    return {
        ProductName: 'Treehouse Children’s Academy',
        MobileAppUrl: 'com.treehouseschools.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: CpcTreehouseLogoComponent,
        LoginLogo: CpcTreehouseLogoComponent,
        LogoForWhiteBg: CpcTreehouseLogoComponent,
        WithSiteImage: true,
    };
};

const CpcTreehouseLogoComponent = () => {
    return <StyledImgLogo height={128} src={CpcTreehouseLogo} />;
};

/**
 * CPC Orchard configuration
 */
export const makeOrchardConfiguration = (): WhiteLabelConfiguration => {
    const theme = CpcOrchardsTheme;

    return {
        ProductName: 'Orchard Academy',
        MobileAppUrl: 'com.childrensorchardacademy.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: CpcOrchardLogoComponent,
        LoginLogo: CpcOrchardLogoComponent,
        LogoForWhiteBg: CpcOrchardLogoComponent,
        WithSiteImage: true,
    };
};

const CpcOrchardLogoComponent = () => {
    return <StyledImgLogo height={228} src={CpcOrchardLogo} />;
};

/**
 * Busy Bees configuration
 */
export const makeBusyBeesConfiguration = (): WhiteLabelConfiguration => {
    const theme = BusyBeesTheme;

    return {
        ProductName: 'Busy Bees',
        MobileAppUrl: 'com.busybees.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: BusyBeesLogoComponent,
        LoginLogo: BusyBeesLogoComponent,
        LogoForWhiteBg: () => BusyBeesLogoComponent({isWhiteBg: true}),
        WithSiteImage: true,
        p500: theme.chromeColor,
    };
};

const BusyBeesLogoComponent = ({isWhiteBg}: {isWhiteBg?: boolean}) => {
    return <img width="224" src={isWhiteBg ? BusyBeesLogoForWhiteBg : BusyBeesLogo} />;
};
