import React from 'react';
import {Text, Stack, useBreakpoints} from 'modern-famly';

import {PersonImage} from 'signin-app/components/person-image';

export const HeaderPerson: React.FC<{
    image: string | undefined;
    initials: string;
    name: string;
}> = ({image, initials, name}) => {
    const {isTabletLandscapeAndLarger} = useBreakpoints();
    return (
        <Stack alignItems="center" flexDirection={isTabletLandscapeAndLarger ? 'row' : 'column'} gap={3}>
            <PersonImage size="sm" image={image} initials={initials} />
            <Text variant="h5" color="n400" emphasized data-e2e-id="head-person-name">
                {name}
            </Text>
        </Stack>
    );
};
