// eslint-disable-next-line no-restricted-syntax
export enum LeaveType {
    Absent = 'ABSENT',
    Sick = 'SICK',
    ChildSick = 'CHILD_SICK',
    Vacation = 'VACATION',
}

/**
 *
 * @param value LeaveType value: 'SICK' for example
 * @returns key: Sick
 */
export function getLeaveTypeByValue(value: string): LeaveType {
    const indexOfS = Object.values(LeaveType).indexOf(value as unknown as LeaveType);
    const key = Object.keys(LeaveType)[indexOfS];
    return LeaveType[key] as LeaveType;
}
