import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Stack, Box, useBreakpoints} from 'modern-famly';

import RoutesMap from 'signin-app/routes/routes-map';
import translate from 'signin-app/helpers/translate';
import {type WhiteLabelConfiguration} from 'signin-app/whitelabel/types';
import {HeaderLogoContainer} from 'signin-app/components/containers';

interface PinHeaderProps {
    whitelabelConfiguration: WhiteLabelConfiguration;
    centerElement?: React.ReactNode;
}

export const PinHeader: React.FC<PinHeaderProps> = ({whitelabelConfiguration, centerElement}) => {
    const {isMobileLandscapeAndLarger} = useBreakpoints();
    return (
        <Stack
            alignItems="center"
            justifyContent="space-between"
            flexDirection={isMobileLandscapeAndLarger ? 'row' : 'column'}
            gap={3}
        >
            <HeaderLogoContainer>
                <whitelabelConfiguration.LogoForWhiteBg />
            </HeaderLogoContainer>
            {centerElement ? <Box>{centerElement}</Box> : null}
            <Link to={RoutesMap.pin}>
                <Button variant="tertiary" text={translate('logOut')} icon="logout" />
            </Link>
        </Stack>
    );
};
