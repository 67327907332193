import React from 'react';
import styled from 'styled-components';
import {Text, Box, Stack, createStack, useBreakpoints} from 'modern-famly';

import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

const TimeBoxContainer = styled(
    createStack({
        alignItems: 'center',
        justifyContent: 'center',
        px: '16px',
        py: '24px',
        width: '104px',
        maxHeight: {base: '64px', tabletPortrait: '80px'},
        minHeight: {base: '64px', tabletPortrait: '80px'},
        borderRadius: '16px',
    }),
)<{selected?: boolean; customBorderColor?: string; customBackgroundColor?: string}>`
    box-sizing: border-box;
    border: 2px solid ${props => (props.selected ? props.customBorderColor : props.theme.mf.colorPalette.n200)};
    background-color: ${props => (props.selected ? props.customBackgroundColor : 'transparent')};
    cursor: pointer;
    color: ${props => props.theme.mf.colorPalette.n400};
`;

interface TimeBoxProps {
    value: number;
    selected: boolean;
    handleOnClick: (value: number) => void;
    displayValue?: string | number;
    className?: string;
    subText?: string;
    dataE2eTimeHour?: string;
}

const TimeBox: React.FC<TimeBoxProps> = ({
    value,
    selected,
    displayValue,
    className,
    subText,
    dataE2eTimeHour,
    handleOnClick,
}) => {
    const {isTabletPortraitAndLarger} = useBreakpoints();
    const p300 = useCustomWhitelabelColor('p300');
    const p200 = useCustomWhitelabelColor('p200');

    const selectTime = React.useCallback(() => {
        handleOnClick(value);
    }, [handleOnClick, value]);

    const timeValue = displayValue === undefined ? value : displayValue;

    return (
        <TimeBoxContainer
            onClick={selectTime}
            className={className}
            data-e2e-class={dataE2eTimeHour}
            customBorderColor={p300}
            customBackgroundColor={p200}
            selected={selected}
        >
            <Stack flexDirection="column" justifyContent="center" alignItems="center">
                <Text variant={isTabletPortraitAndLarger ? 'h4' : 'h6'} color="n400">
                    {timeValue}
                </Text>
                <Box>{subText}</Box>
            </Stack>
        </TimeBoxContainer>
    );
};

export default TimeBox;
