import React from 'react';
import moment, {type Moment} from 'moment-timezone';
import i18next from 'i18next';
import styled, {css} from 'styled-components';

import {s1, getSpacing, s2} from 'web-app/styleguide/spacing';
import {hasValue, type RequireAtLeastOne, getProperty} from 'web-app/util/typescript';
import * as DayPickerConstants from 'web-app/react/components/form-elements/daypicker/constants';
import {Base, Flex} from 'web-app/react/components/layout/layout';
import {Body} from 'web-app/react/components/text/text';
import DayPickerInput, {
    type CustomDayPickerInputFieldProps,
    type DayPickerInputProps,
    type DayPickerPosition,
} from 'web-app/react/components/form-elements/daypicker/day-picker-input';
import Button from 'web-app/react/components/form-elements/button/button';
import {ArrowLeft1} from 'web-app/react/components/icons/streamline';

import {lastWeekButtonE2eClass, nextWeekButtonE2eClass} from './week-of-picker-ids';

export {default as useHighlightWeekConfig} from './use-highlight-week-config';

/*
|------------------------------------------------------------------------------
| Week of picker
|------------------------------------------------------------------------------
*/

// eslint-disable-next-line no-restricted-syntax
export enum ElementAppearance {
    Default,
    OnWhiteBackground,
}

interface BaseProps {
    appearance?: ElementAppearance;
    timezone: string;
    selectedDate?: string;
    onDateChange: (date: Moment) => void;
    disabledDays?: DayPickerInputProps['disabledDays'];
    min?: DayPickerInputProps['min'];
    max?: DayPickerInputProps['max'];
    className?: string;
    'data-e2e-id'?: string;
    'data-intercom-target'?: string;
    hoverEffectOnWeek?: boolean;
    dayPickerPosition?: DayPickerPosition;
    modifiers?: DayPickerInputProps['modifiers'];
    disabled?: boolean;
    inputFieldDateFormat?: string;
    placeholder?: string;
    locale?: string;
    onlyAllWeekdaysSelectable?: boolean;
    weekendSupport?: boolean;
    onLeftArrowButtonClick?: () => void;
    onRightArrowButtonClick?: () => void;
    id?: string;
}

export type InputProps = RequireAtLeastOne<BaseProps, 'selectedDate' | 'placeholder'>;

const WeekOfPicker: React.FC<React.PropsWithChildren<InputProps>> = React.memo(props => {
    const {
        timezone,
        selectedDate: selectedDateString,
        onDateChange,
        disabledDays,
        className,
        min,
        max,
        appearance = ElementAppearance.Default,
        hoverEffectOnWeek,
        modifiers,
        disabled,
        dayPickerPosition,
        inputFieldDateFormat,
        placeholder,
        locale,
        onlyAllWeekdaysSelectable,
        weekendSupport,
        onLeftArrowButtonClick,
        onRightArrowButtonClick,
        id,
    } = props;

    const selectedDate = React.useMemo(
        () => (selectedDateString ? moment(selectedDateString) : undefined),
        [selectedDateString],
    );

    const canSelectNextWeek = React.useMemo(() => {
        if (disabled || !selectedDate) {
            return false;
        }

        if (!hasValue(max)) {
            return true;
        }

        const endOfMaximumWeek = moment(max).endOf('week');

        return selectedDate.clone().add(1, 'week').isSameOrBefore(endOfMaximumWeek);
    }, [selectedDate, max, disabled]);

    const canSelectPreviousWeek = React.useMemo(() => {
        if (disabled || !selectedDate) {
            return false;
        }

        if (!hasValue(min)) {
            return true;
        }

        const startOfMinimumWeek = moment(min).startOf('week');

        return selectedDate.clone().subtract(1, 'week').isSameOrAfter(startOfMinimumWeek);
    }, [selectedDate, min, disabled]);

    const selectNextWeek = React.useCallback(() => {
        if (canSelectNextWeek && selectedDate) {
            onDateChange(selectedDate.clone().add(1, 'week'));
        }
    }, [canSelectNextWeek, selectedDate, onDateChange]);

    const selectPreviousWeek = React.useCallback(() => {
        if (canSelectPreviousWeek && selectedDate) {
            onDateChange(selectedDate.clone().subtract(1, 'week'));
        }
    }, [canSelectPreviousWeek, selectedDate, onDateChange]);

    const handleDateChange = React.useCallback(
        (date?: string) => {
            const formattedDate = moment.tz(date, timezone);
            onDateChange(formattedDate);
        },
        [onDateChange, timezone],
    );

    const customInput = React.useCallback(
        inputProps => (
            <CustomDayPickerInputField
                {...inputProps}
                timezone={timezone}
                inputFieldDateFormat={inputFieldDateFormat}
                placeholder={placeholder}
                locale={locale}
            />
        ),
        [timezone, inputFieldDateFormat, placeholder, locale],
    );

    const customModifiers = React.useMemo(() => {
        if (onlyAllWeekdaysSelectable) {
            return getSelectedDaysModifier(moment(selectedDate), weekendSupport);
        }
        return modifiers;
    }, [onlyAllWeekdaysSelectable, modifiers, selectedDate, weekendSupport]);

    const onlyAllowWeekdaysDateFilter = React.useCallback(
        (day: Date) => {
            return isNotWeekendDays(day, timezone);
        },
        [timezone],
    );

    const customHoverEffectOnWeek = onlyAllWeekdaysSelectable || hoverEffectOnWeek;

    const generateDisabledDays = () => {
        // if disabledDays = undefined, that essentially means that there are no disabled days and we can show the weekend
        if (weekendSupport) {
            return undefined;
        }

        return onlyAllWeekdaysSelectable ? onlyAllowWeekdaysDateFilter : disabledDays;
    };

    const customDisabledDays = generateDisabledDays();

    const handlePreviousWeekClick = React.useCallback(() => {
        if (hasValue(onLeftArrowButtonClick)) {
            onLeftArrowButtonClick();
        }
        selectPreviousWeek();
    }, [onLeftArrowButtonClick, selectPreviousWeek]);

    const handleNextWeekClick = React.useCallback(() => {
        if (hasValue(onRightArrowButtonClick)) {
            onRightArrowButtonClick();
        }
        selectNextWeek();
    }, [onRightArrowButtonClick, selectNextWeek]);

    return (
        <WeekOfPickerContainer className={className} appearance={appearance} data-e2e-id={props['data-e2e-id']}>
            <ArrowButtonContainerLeft
                onClick={handlePreviousWeekClick}
                disabled={!canSelectPreviousWeek}
                data-e2e-class={lastWeekButtonE2eClass}
            >
                <ArrowLeft1 size={10} />
            </ArrowButtonContainerLeft>
            <DayPickerInputContainer
                data-e2e-class="selectWeek"
                data-intercom-target={getProperty(props, 'data-intercom-target')}
                appearance={appearance}
            >
                <StyledDayPickerInput
                    onChange={handleDateChange}
                    value={selectedDate}
                    clearable={false}
                    disabledDays={customDisabledDays}
                    customInput={customInput}
                    min={min}
                    max={max}
                    modifiers={customModifiers}
                    hoverEffectOnWeek={customHoverEffectOnWeek}
                    disabled={disabled}
                    position={dayPickerPosition}
                    locale={locale}
                    id={id}
                />
            </DayPickerInputContainer>
            <ArrowButtonContainerRight
                onClick={handleNextWeekClick}
                disabled={!canSelectNextWeek}
                data-e2e-class={nextWeekButtonE2eClass}
            >
                <ArrowRight1 size={10} />
            </ArrowButtonContainerRight>
        </WeekOfPickerContainer>
    );
});

/*
|------------------------------------------------------------------------------
| Components
|------------------------------------------------------------------------------
*/

const INPUT_ELEMENT_HEIGHT = '36px';

const WeekOfPickerContainer = styled.div<{appearance: ElementAppearance}>`
    display: flex;
    border: ${props =>
        props.theme.containerConfiguration.border
            ? props.theme.containerConfiguration.border
            : css`
                1px solid
        ${
            props.appearance === ElementAppearance.OnWhiteBackground
                ? props.theme.borderConfiguration.defaultColor
                : 'transparent'
        }`};
    ${props =>
        props.theme.containerConfiguration.boxShadow2 &&
        props.theme.applicationBackground &&
        css`
            background-color: ${props.theme.applicationBackground};
            box-shadow: ${props.theme.containerConfiguration.boxShadow2};
        `};
    border-radius: 3px;
    justify-content: flex-start;
`;

export const DayPickerInputContainer = styled(Flex)<{appearance: ElementAppearance}>`
    flex-grow: 1;
    border-left: 1px solid
        ${props =>
            props.appearance === ElementAppearance.OnWhiteBackground
                ? props.theme.borderConfiguration.defaultColor
                : 'transparent'};
    border-right: 1px solid
        ${props =>
            props.appearance === ElementAppearance.OnWhiteBackground
                ? props.theme.borderConfiguration.defaultColor
                : 'transparent'};
    width: 158px;
`;

export const StyledDayPickerInput = styled(DayPickerInput)`
    height: ${INPUT_ELEMENT_HEIGHT};
    border: none;
    padding: ${getSpacing([s1, s2])};
`;

const dateValueContainerCSS = css`
    &&&:focus {
        border: none;
    }
`;

export const DateValueContainer = styled(Button)`
    border-radius: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${dateValueContainerCSS};
`;

const ArrowButtonContainer = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    ${dateValueContainerCSS}
`;

export const ArrowButtonContainerLeft = styled(ArrowButtonContainer)`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;

export const ArrowButtonContainerRight = styled(ArrowButtonContainer)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

const ArrowRight1 = styled(ArrowLeft1)`
    transform: rotate(180deg);
`;

const CustomDayPickerInputField: React.FC<
    React.PropsWithChildren<
        CustomDayPickerInputFieldProps & {
            timezone: string;
            inputFieldDateFormat?: string;
            placeholder?: string;
            locale?: string;
        }
    >
> = ({value, timezone, onClick, inputFieldDateFormat, placeholder, locale, ...rest}) => {
    const formattedDate =
        hasValue(value) && value.length > 0
            ? moment
                  .tz(value, DayPickerConstants.DEFAULT_DATE_FORMAT, timezone)
                  .locale(locale ?? false)
                  .startOf('week')
                  .format(inputFieldDateFormat || 'll')
            : undefined;

    return (
        <DateValueContainer
            {...rest}
            onClick={e => {
                e.target.focus();

                // eslint-disable-next-line no-unused-expressions
                onClick?.();
            }}
        >
            <Flex pointerEvents="none">
                {!formattedDate && placeholder ? (
                    <Body disabled>{placeholder}</Body>
                ) : (
                    <>
                        <Body>{i18next.t('weekOf')}</Body>
                        <Base marginLeft={s1}>
                            <Body emphasized>{formattedDate}</Body>
                        </Base>
                    </>
                )}
            </Flex>
        </DateValueContainer>
    );
};

const getSelectedDayTo = (selectedDayFrom: Moment, weekendSupport?: boolean) => {
    return selectedDayFrom.clone().add(weekendSupport ? 6 : 4, 'days');
};

const getSelectedDaysModifier = (selectedDate: Moment, weekendSupport?: boolean) => {
    // if weekend support enabled -> select the localized first day (sunday for US and monday for UK) otherwise fallback on monday
    const startOfWeek = weekendSupport ? selectedDate.clone().startOf('week') : selectedDate.clone().isoWeekday(1);
    const selectedDayFrom = startOfWeek;
    const selectedDayTo = getSelectedDayTo(selectedDayFrom, weekendSupport);

    return {
        selected: {
            from: selectedDayFrom.toDate(),
            to: selectedDayTo.toDate(),
        },
        isFirstInRange: day => moment(day).isSame(selectedDayFrom, 'day'),
        isLastInRange: day => moment(day).isSame(selectedDayTo, 'day'),
        isInRange: day => moment(day).isBetween(selectedDayFrom, selectedDayTo, 'days', '[]'),
        isMonday: day => moment(day).isoWeekday() === 1,
        isFriday: day => moment(day).isoWeekday() === 5,
        isWeekendDisabled: () => true,
    };
};

const isNotWeekendDays = (day: Date, timezone: string) => moment.tz(day, timezone).isoWeekday() > 5;

export default WeekOfPicker;
