import React from 'react';

import {Button, type ButtonProps, type TextButtonProps} from 'modern-famly/components/input';
import type {DataProps} from 'modern-famly/components/util';

import {ListItem} from './list-item';
import type {AnchorProps} from './anchor-props';

export type ListItemButtonProps = Omit<
    TextButtonProps &
        DataProps &
        AnchorProps & {
            variant?: Exclude<ButtonProps['variant'], 'primary'>;
        },
    'size'
>;

export const ListItemButton = (props: ListItemButtonProps) => {
    const defaultVariant = 'secondary';

    return (
        <ListItem px={3} onClick={e => e.stopPropagation()}>
            <Button size="compact" variant={defaultVariant} {...props} />
        </ListItem>
    );
};
