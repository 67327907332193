import type * as NonEmptyArray from 'web-app/util/non-empty-array';
import {type IconProps} from 'web-app/react/components/icons/types';
import {type NavigationItem} from 'web-app/util/graphql/api-types';

import {type BehaviorsQuery} from './__generated__/queries.api-types';

// eslint-disable-next-line no-restricted-syntax
export enum NavigationGroupIds {
    GET_STARTED = 'GET_STARTED',
    HOME = 'HOME',
    ATTENDANCE = 'ATTENDANCE',
    LEARNING = 'LEARNING',
    CHILDREN = 'CHILDREN',
    FINANCES = 'FINANCES',
    SETTINGS = 'SETTINGS',
    REPORTS = 'REPORTS',
    FAMLY_STORE = 'STORE',
    HELP_AND_SUPPORT = 'HELP_AND_SUPPORT',
}

// eslint-disable-next-line no-restricted-syntax
export enum NavigationLinkIds {
    // Get started
    GET_STARTED = 'GET_STARTED',
    NURSERY_INFO = 'NURSERY_INFO',
    ROOMS = 'ROOMS',
    MANAGE_GROUP_RULES = 'MANAGE_GROUP_RULES',
    GET_STARTED_CHILDREN = 'GET_STARTED_CHILDREN',
    CHILD_IMPORT = 'CHILD_IMPORT',
    GET_STARTED_STAFF = 'GET_STARTED_STAFF',
    STAFF_ROLES = 'STAFF_ROLES',
    STAFF_PERMISSIONS = 'STAFF_PERMISSIONS',
    INVOICING_PROFILES = 'INVOICING_PROFILES',
    ATTENDANCE_SCHEDULES = 'ATTENDANCE_SCHEDULES',
    FUNDING_GRANTS = 'FUNDING_GRANTS',
    PRICING = 'PRICING',
    DISCOUNT_SETTINGS = 'DISCOUNT_SETTINGS',
    GET_STARTED_PLANS = 'GET_STARTED_PLANS',
    GET_STARTED_FAMLY_PAY = 'GET_STARTED_FAMLY_PAY',
    INVOICE_LAYOUT = 'INVOICE_LAYOUT',
    ACCOUNTING_SETTINGS = 'ACCOUNTING_SETTINGS',
    GET_STARTED_FAMLY_PAY_SETTINGS = 'GET_STARTED_FAMLY_PAY_SETTINGS',
    PARENT_BOOKING_SETTINGS = 'PARENT_BOOKING_SETTINGS',
    AUTOMATIC_INVOICING_CONFIG = 'AUTOMATIC_INVOICING_CONFIG',
    // Home
    OVERVIEW = 'OVERVIEW',
    HOME_CUSTOMIZATION = 'HOME_CUSTOMIZATION',
    ORGANIZATION_SETTINGS = 'ORGANIZATION_SETTINGS',
    CHILDREN_AND_STAFF = 'CHILDREN_AND_STAFF',
    NEWS_FEED = 'NEWS_FEED',
    NEWS_FEED_SINGLE_POST = 'NEWS_FEED_SINGLE_POST',
    CHILDREN = 'CHILDREN',
    // Sub-links
    CHILDREN_OVERVIEW = 'CHILDREN_OVERVIEW',
    PARENTAL_PERMISSIONS = 'PARENTAL_PERMISSIONS',
    PARENTAL_PERMISSIONS_CHECK_PERMISSIONS = 'PARENTAL_PERMISSIONS_CHECK_PERMISSIONS',
    VACATION_PLANS = 'VACATION_PLANS',
    STAFF = 'STAFF',
    DOCUMENTS = 'DOCUMENTS',
    CALENDAR = 'CALENDAR',
    CALENDAR_EVENT = 'CALENDAR_EVENT',
    OLD_MEAL_PLANS = 'OLD_MEAL_PLANS',
    MEALS = 'MEALS',
    MEAL_PLANS_V3 = 'MEAL_PLANS_V3',
    MEAL_PLANS_V3_CREATE = 'MEAL_PLANS_V3_CREATE',
    MEAL_PLANS_V3_VIEW = 'MEAL_PLANS_V3_VIEW',
    MEAL_SETTINGS = 'MEAL_SETTINGS',
    MEAL_BOOKINGS = 'MEAL_BOOKINGS',
    BILL_PAYER = 'BILL_PAYER',
    BILL_PAYERS = 'BILL_PAYERS',
    LESSON_PLANS_PARENT = 'LESSON_PLANS_PARENT',
    OVERVIEW_REPORTS = 'OVERVIEW_REPORTS',
    // Attendance
    ROOM_PLANNING = 'ROOM_PLANNING',
    ATTENDANCE_REPORT = 'ATTENDANCE_REPORT',
    KINDER_CONNECT = 'KINDER_CONNECT',
    KINDER_CONNECT_EXPORT = 'KINDER_CONNECT_EXPORT',
    KINDER_CONNECT_LINKING = 'KINDER_CONNECT_LINKING',
    ATTENDANCE_STATISTICS = 'ATTENDANCE_STATISTICS',
    STAFFING = 'STAFFING',
    STAFF_ATTENDANCE_DETAILS = 'STAFF_ATTENDANCE_DETAILS',
    // Sub-links
    STAFF_RATIO_REPORT = 'STAFF_RATIO_REPORT',
    STAFFING_STAFF_ATTENDANCE = 'STAFFING_STAFF_ATTENDANCE',
    STAFFING_STAFF_SCHEDULE_V2 = 'STAFFING_STAFF_SCHEDULE_V2',
    STAFFING_STAFF_LEAVE = 'STAFFING_STAFF_LEAVE',
    STAFFING_STAFF_LEAVE_V2 = 'STAFFING_STAFF_LEAVE_V2',
    STAFFING_STAFF_HOURS = 'STAFFING_STAFF_HOURS',
    INQUIRIES = 'INQUIRIES',
    INQUIRIES_V2 = 'INQUIRIES_V2',
    FORMS = 'FORMS',
    CRF_NEW_FORM = 'CRF_NEW_FORM',
    FORM_BUILDER = 'FORM_BUILDER',
    FORM_SUBMISSION_RESPONSE = 'FORM_SUBMISSION_RESPONSE',
    OCCUPANCY = 'OCCUPANCY',
    REGISTRATION = 'REGISTRATION',
    // Sub-links
    OCCUPANCY_PLANNER = 'OCCUPANCY_PLANNER',
    OCCUPANCY_INDEX = 'OCCUPANCY_INDEX',
    // Learning
    CHILD_DEVELOPMENT = 'CHILD_DEVELOPMENT',
    CHILD_DEVELOPMENT_OVERVIEW = 'CHILD_DEVELOPMENT_OVERVIEW',
    // Sub-links
    CHILD_DEVELOPMENT_NEXT_STEPS = 'CHILD_DEVELOPMENT_NEXT_STEPS',
    CHILD_DEVELOPMENT_PROGRESS_REPORT = 'CHILD_DEVELOPMENT_PROGRESS_REPORT',
    CHILD_DEVELOPMENT_COMPARE_COHORTS = 'CHILD_DEVELOPMENT_COMPARE_COHORTS',
    CHILD_DEVELOPMENT_TALLY = 'CHILD_DEVELOPMENT_TALLY',
    CHILD_DEVELOPMENT_TWO_YEAR_CHECKS = 'CHILD_DEVELOPMENT_TWO_YEAR_CHECKS',
    CHILD_DEVELOPMENT_GROUP_OBSERVATION = 'CHILD_DEVELOPMENT_GROUP_OBSERVATION',
    ACTIVITY_LIBRARY = 'ACTIVITY_LIBRARY',
    ACTIVITY_LIBRARY_SINGLE_LIBRARY = 'ACTIVITY_LIBRARY_SINGLE_LIBRARY',
    ACTIVITY_LIBRARY_SINGLE_ACTIVITY = 'ACTIVITY_LIBRARY_SINGLE_ACTIVITY',
    LESSON_PLANS = 'LESSON_PLANS',
    CURRICULUM_BUILDER_LIST = 'CURRICULUM_BUILDER_LIST',
    CURRICULUM_BUILDER_CREATE = 'CURRICULUM_BUILDER_CREATE',
    CURRICULUM_BUILDER_DUPLICATE = 'CURRICULUM_BUILDER_DUPLICATE',
    CURRICULUM_BUILDER_SHOW = 'CURRICULUM_BUILDER_SHOW',
    // Safeguarding
    ACCIDENTS_INCIDENTS = 'ACCIDENTS_INCIDENTS',
    MEDICATION_FORMS = 'MEDICATION_FORMS',
    IMMUNIZATION = 'IMMUNIZATION',
    IMMUNIZATION_ONBOARDING = 'IMMUNIZATION_ONBOARDING',
    HEAD_COUNT = 'HEAD_COUNT',
    // Finances
    REVENUE = 'REVENUE',
    DEBT = 'DEBT',
    INVOICING = 'INVOICING',
    PUBLIC_FUNDING = 'PUBLIC_FUNDING',
    FUNDING_SETTINGS = 'FUNDING_SETTINGS',
    CHILD_BASED_FUNDING = 'CHILD_BASED_FUNDING',
    FAMLY_PAY = 'FAMLY_PAY',
    BULK_IMPORT_PAYMENT = 'BULK_IMPORT_PAYMENT',
    BULK_IMPORT_PAYMENT_PROTOPAYMENTS_TABLE = 'BULK_IMPORT_PAYMENT_PROTOPAYMENTS_TABLE',
    BOOKINGS = 'BOOKINGS',
    FINANCE = 'FINANCE',
    BOOKING_PATTERN_BUILDER = 'BOOKING_PATTERN_BUILDER',
    BULK_PATTERN_FLOW = 'BULK_PATTERN_FLOW',
    PLANS = 'PLANS',
    PURCHASES = 'PURCHASES',
    INVOICING_REPORTS = 'INVOICING_REPORTS',
    PAYMENTS = 'PAYMENTS',
    INVOICES_REPORT = 'INVOICES_REPORT',
    BILL_PAYERS_REPORT = 'BILL_PAYERS_REPORT',
    DEPOSITS_REPORT = 'DEPOSITS_REPORT',
    DIRECT_DEBITS_REPORT = 'DIRECT_DEBITS_REPORT',
    SUBSCRIPTION = 'SUBSCRIPTION',
    // Reports
    LISTS = 'LISTS',
    LISTS_FILTER = 'LISTS_FILTER',
    HEAD_COUNT_REPORT = 'HEAD_COUNT_REPORT',
    OCCUPANCY_REPORT = 'OCCUPANCY_REPORT',
    FAMLY_REPORT = 'FAMLY_REPORT',
    ORG_BALANCE_REPORT = 'ORG_BALANCE_REPORT',
    FAMLY_INSIGHTS = 'FAMLY_INSIGHTS',
    ORG_DASHBOARD = 'ORG_DASHBOARD',
    // Settings
    SETTINGS = 'SETTINGS',
    ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
    // Store
    FAMLY_STORE_FEATURES = 'STORE_FEATURES',
    FEATURE_STORE = 'FEATURE_STORE',
    FAMLY_STORE_PARTNERS = 'STORE_PARTNERS',
    FAMLY_STORE_EMBED_PACKAGE_PRICING_PAGE = 'FAMLY_STORE_EMBED_PACKAGE_PRICING_PAGE',
    FAMLY_STORE_TEMPLATES = 'FAMLY_STORE_TEMPLATES',
    // Tab bar
    NOTIFICATIONS = 'NOTIFICATIONS',
    INBOX = 'INBOX',
    // Child profile
    CHILD_PROFILE_ACTIVITY = 'CHILD_PROFILE_ACTIVITY',
    CHILD_PROFILE_ABOUT = 'CHILD_PROFILE_ABOUT',
    CHILD_PROFILE_SLEEP = 'CHILD_PROFILE_SLEEP',
    CHILD_PROFILE_INSTITUTION_INFO = 'CHILD_PROFILE_INSTITUTION_INFO',
    CHILD_PROFILE_LEAVE = 'CHILD_PROFILE_LEAVE',
    CHILD_PROFILE_PHOTOS = 'CHILD_PROFILE_PHOTOS',
    CHILD_PROFILE_PLANS = 'CHILD_PROFILE_PLANS',
    CHILD_PROFILE_BILLING = 'CHILD_PROFILE_BILLING',
    CHILD_PROFILE_RELATIONS = 'CHILD_PROFILE_RELATIONS',
    CHILD_PROFILE_FAMILY = 'CHILD_PROFILE_FAMILY',
    CHILD_PROFILE_PLANS_AND_INVOICES = 'CHILD_PROFILE_PLANS_AND_INVOICES',
    CHILD_PROFILE_NOTES = 'CHILD_PROFILE_NOTES',
    CHILD_PROFILE_NOTE = 'CHILD_PROFILE_NOTE',
    CHILD_PROFILE_LEARNING_JOURNEY = 'CHILD_PROFILE_LEARNING_JOURNEY',
    CHILD_PROFILE_LEARNING_JOURNEY_OBSERVATION = 'CHILD_PROFILE_LEARNING_JOURNEY_OBSERVATION',
    CHILD_PROFILE_LEARNING_JOURNEY_DETAILS = 'CHILD_PROFILE_LEARNING_JOURNEY_DETAILS',
    CHILD_PROFILE_ACCIDENT_INCIDENT_REPORTS = 'CHILD_PROFILE_ACCIDENT_INCIDENT_REPORTS',
    CHILD_PROFILE_SAFEGUARDING = 'CHILD_PROFILE_SAFEGUARDING',
    CHILD_PROFILE_PRINT_PREVIEW = 'CHILD_PROFILE_PRINT_PREVIEW',
    // Employee profile
    EMPLOYEE_PROFILE_DATA = 'EMPLOYEE_PROFILE_DATA',
    EMPLOYEE_PROFILE_RELATIONS = 'EMPLOYE_PROFILE_RELATIONS',
    EMPLOYEE_PROFILE_LEAVE = 'EMPLOYEE_PROFILE_LEAVE',
    EMPLOYEE_PROFILE_ABOUT = 'EMPLOYEE_PROFILE_ABOUT',
    EMPLOYEE_PROFILE_CALENDAR = 'EMPLOYEE_PROFILE_CALENDAR',
    // No Access Page
    NO_ACCESS = 'NO_ACCESS',
    // Finance onboarding
    FINANCE_ONBOARDING = 'FINANCE_ONBOARDING',
    // Mobile menu
    MOBILE_MENU = 'MOBILE_MENU',
    MOBILE_MENU_NAVIGATION_GROUP = 'MOBILE_MENU_NAVIGATION_GROUP',
    // Reports
    REPORTS = 'REPORTS',
    REPORT_CATEGORY = 'REPORT_CATEGORY',
    REPORT = 'REPORT',
    REPORT_LISTS = 'REPORT_LIST',
    REPORT_LISTS_FILTER = 'REPORT_LIST_FILTER',
    REPORT_FAMLY_INSIGHTS = 'REPORT_FAMLY_INSIGHTS',
    NEW_REPORT = 'NEW_REPORT',
    // full screen create report page
    CREATE_NEW_REPORT = 'CREATE_NEW_REPORT',
    CREATE_NEW_REPORT_FROM_DATASET = 'CREATE_NEW_REPORT_FROM_DATASET',
    CREATE_NEW_REPORT_FROM_REPORT_TEMPLATE = 'CREATE_NEW_REPORT_FROM_REPORT_TEMPLATE',
    // Finance reports
    FINANCE_REPORTS = 'FINANCE_REPORTS',
    FINANCE_REPORT = 'FINANCE_REPORT',
    // Leaning reports
    LEARNING_REPORTS = 'LEARNING_REPORTS',
    LEARNING_REPORT = 'LEARNING_REPORT',
    // Attendance reports
    ATTENDANCE_REPORTS = 'ATTENDANCE_REPORTS',
    REPORTS_ATTENDANCE_REPORT = 'REPORTS_ATTENDANCE_REPORT',
    // Category landing pages
    FINANCE_LANDING_PAGE = 'FINANCE_LANDING_PAGE',
    ATTENDANCE_LANDING_PAGE = 'ATTENDANCE_LANDING_PAGE',
    LEARNING_LANDING_PAGE = 'LEARNING_LANDING_PAGE',
    CHILDREN_LANDING_PAGE = 'CHILDREN_LANDING_PAGE',
    HEALTH_SCREENING_STATEMENTS = 'HEALTH_SCREENING_STATEMENTS',
}

interface NavigationGroupCommonTypes {
    id: NavigationGroupIds;
}

interface NavigationGroupWithNavigationLinks extends NavigationGroupCommonTypes {
    type: 'WithNavigationLinks';
    navigationLinks: NonEmptyArray.NonEmptyArray<NavigationLink>;
    hideNavigationLinks?: boolean;
}

interface NavigationGroupWithoutNavigationLinks extends NavigationGroupCommonTypes {
    type: 'WithoutNavigationLinks';
    onClick: () => void;
}

export type NavigationGroup = NavigationGroupWithNavigationLinks | NavigationGroupWithoutNavigationLinks;

export interface NavigationLink {
    id: NavigationLinkIds;
    institutions: InstitutionForNavigationLink[];
    organizations: OrganizationForNavigationLink[];
    subNavigationLinks: NavigationLink[];
    subPages?: NavigationLinkIds[];
    /**
     * @deprecated Refrain from using custom entities if possible. They cause a lot of hassle.
     */
    customEntities?: CustomEntity[];
    isRemovable?: boolean;
}

export type BehaviorDataResponse = {
    institutions: InstitutionForNavigationLink[];
    organizations: OrganizationForNavigationLink[];
    behaviorId: string;
    isDismissed?: boolean;
    isRemovable?: boolean;
}[];

export const hasNavigationLinks = (
    navigationGroup: NavigationGroup,
): navigationGroup is NavigationGroupWithNavigationLinks => {
    return navigationGroup.type === 'WithNavigationLinks';
};

// eslint-disable-next-line no-restricted-syntax
export enum CustomEntityIds {
    NEWS_FEED_BOOKMARKS = 'NEWS_FEED_BOOKMARKS',
    NEWS_FEED_MY_NEWS = 'NEWS_FEED_MY_NEWS',
    MY_CALENDAR = 'MY_CALENDAR',
    REPORTS = 'REPORTS',
    FINANCE_REPORTS = 'FINANCE_REPORTS',
    LEARNING_REPORTS = 'LEARNING_REPORTS',
    ATTENDANCE_REPORTS = 'ATTENDANCE_REPORTS',
}

/**
 * @deprecated Refrain from using custom entities if possible. They cause a lot of hassle.
 */
export interface CustomEntity {
    id: CustomEntityIds;
    title: string;
    image?: string;
    icon?: React.ComponentType<React.PropsWithChildren<IconProps>>;
}

export interface InstitutionForNavigationLink {
    institutionId: string;
    payload: object;
    title: string;
    profileImage?: {
        url: string;
    };
}

export interface OrganizationForNavigationLink {
    organizationId: string;
    title: string;
    profileImage?: {
        url: string;
    };
    payload: object;
}

export interface EntitiesForNavigationLink {
    institutions: InstitutionForNavigationLink[];
    organizations: OrganizationForNavigationLink[];
}

export interface FreePackageBehavior {
    freePackageBehavior: boolean;
    institutionId: string;
}

export type ApiNavigationItem = BehaviorsQuery['me']['navigation']['items'][number];

export type DismissableNavigationItem = NavigationItem & {isDismissed: boolean};
export type RemovableNavigationItem = NavigationItem & {isRemovable: boolean};
