import {type ITheme} from 'web-app/styleguide/themes/model';

// eslint-disable-next-line no-restricted-syntax
export enum SpacingType {
    s0 = 's0',
    s1 = 's1',
    s2 = 's2',
    s3 = 's3',
    s4 = 's4',
    s5 = 's5',
    s6 = 's6',
    s7 = 's7',
    s8 = 's8',
    s9 = 's9',
    s10 = 's10',
    s11 = 's11',
    s12 = 's12',
    s13 = 's13',
    s14 = 's14',
    s15 = 's15',
    s16 = 's16',
    s18 = 's18',
    s20 = 's20',
    s21 = 's21',
    s22 = 's22',
}

// eslint-disable-next-line no-restricted-syntax
enum SpacingKind {
    Identity = 'Identity',
    Negative = 'Negative',
    Divide = 'Divide',
}

export interface SpacingIdentity {
    kind: SpacingKind.Identity;
    spacingType: SpacingType;
}

interface Negative {
    kind: SpacingKind.Negative;
    spacing: Spacing;
}

interface Divide {
    kind: SpacingKind.Divide;
    spacing: Spacing;
    divisor: number;
}

type Spacing = SpacingIdentity | Negative | Divide;

// Discriminated union, auto is mostly there for autocompletion
export type SingleSpacingDescription = Spacing | 'auto' | string;

export type SpacingDescription =
    | SingleSpacingDescription
    | [SingleSpacingDescription]
    | [SingleSpacingDescription, SingleSpacingDescription]
    | [SingleSpacingDescription, SingleSpacingDescription, SingleSpacingDescription]
    | [SingleSpacingDescription, SingleSpacingDescription, SingleSpacingDescription, SingleSpacingDescription];

// Expressions

export const s0: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s0};
export const s1: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s1};
export const s2: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s2};
export const s3: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s3};
export const s4: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s4};
export const s5: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s5};
export const s6: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s6};
export const s7: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s7};
export const s8: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s8};
export const s9: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s9};
export const s10: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s10};
export const s11: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s11};
export const s12: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s12};
export const s13: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s13};
export const s14: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s14};
export const s15: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s15};
export const s16: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s16};
export const s18: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s18};
export const s20: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s20};
export const s21: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s21};
export const s22: SpacingIdentity = {kind: SpacingKind.Identity, spacingType: SpacingType.s22};

export const getSpacing = (spacing: SpacingDescription) => (props: {theme: ITheme}) =>
    getSpacingStringFromDescription(spacing, props.theme);

export const negative = (spacing: Spacing): Negative => ({kind: SpacingKind.Negative, spacing});
export const divide = (spacing: Spacing, divisor: number): Divide => ({kind: SpacingKind.Divide, spacing, divisor});

// Evaluation

// eslint-disable-next-line consistent-return
export const calcSpacing = (spacing: Spacing, theme: ITheme): number => {
    // eslint-disable-next-line default-case
    switch (spacing.kind) {
        case SpacingKind.Identity:
            return theme.spacing.spacingMap[spacing.spacingType];
        case SpacingKind.Negative:
            return -1 * calcSpacing(spacing.spacing, theme);
        case SpacingKind.Divide:
            return calcSpacing(spacing.spacing, theme) / spacing.divisor;
    }
};

export const getSpacingStringFromDescription = (spacing: SpacingDescription, theme: ITheme): string => {
    // If spacing is a list
    if (spacing instanceof Array) {
        return spacing.map(sp => getSpacingStringFromDescription(sp, theme)).join(' ');
    }
    if (typeof spacing === 'string') {
        return spacing;
    }

    return `${calcSpacing(spacing, theme)}${theme.spacing.unit}`;
};

// Theme configuration

export interface SpacingConfiguration {
    unit: string;
    spacingMap: {[key in SpacingType]: number};
}

export const DefaultSpacingConfiguration: SpacingConfiguration = {
    unit: 'px',
    spacingMap: {
        s0: 0,
        s1: 4,
        s2: 8,
        s3: 12,
        s4: 16,
        s5: 20,
        s6: 24,
        s7: 28,
        s8: 32,
        s9: 36,
        s10: 40,
        s11: 44,
        s12: 48,
        s13: 52,
        s14: 56,
        s15: 60,
        s16: 64,
        s18: 72,
        s20: 80,
        s21: 84,
        s22: 88,
    },
};
