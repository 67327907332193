import {StatusBar, Style} from '@capacitor/status-bar';
import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import styled, {css} from 'styled-components';
import {ModernFamlyContext, type ModernFamlyContextType} from 'modern-famly';

import PlatformHelper from 'web-app/helpers/platform-helper';
import Button, {ButtonSize} from 'web-app/react/components/form-elements/button/button';
import lifecycle from 'web-app/react/components/higher-order/lifecycle';
import {PureRenderIgnoreFunctions} from 'web-app/react/components/higher-order/pure-render';
import {type IconProps} from 'web-app/react/components/icons/legacy/icon-props';
import Close from 'web-app/react/components/icons/legacy/lazy-icons/close';
import {Base} from 'web-app/react/components/layout/layout';
import {PortalRootId} from 'web-app/react/components/portals/constants';
import Portal from 'web-app/react/components/portals/portal';
import ProfileImage from 'web-app/react/components/profile-image/profile-image';
import {Caption, Subheader, TextBase} from 'web-app/react/components/text/text';
import {CLASSNAME_PREFIX} from 'web-app/react/global-styles/legacy-modal-style';
import * as ModalsContainerActions from 'web-app/react/modals-container/actions';
import renderStyle from 'web-app/styleguide/render-style';
import {s0, s2} from 'web-app/styleguide/spacing';
import {media} from 'web-app/styleguide/utils';
import * as CapacitorUtil from 'web-app/util/capacitor';
import {compose, hasValue} from 'web-app/util/typescript';
import withContext from 'web-app/react/components/higher-order/with-context';

import {ModalContent, ModalContentType} from './modal-styled';

const ModalBody = styled.div<{noPadding?: boolean; mobileScroll?: boolean}>`
    padding: 0 16px 16px 16px;

    ${props =>
        props.noPadding
            ? css`
                  padding: 0;
              `
            : ''}

    ${props =>
        props.mobileScroll
            ? media.mobile`
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    `
            : ''}
`;

const FullWidthBase = styled(Base)`
    max-width: 100%;
`;

interface ModalHeaderProps {
    image?: string;
    // Please don't use this, the props will be deprecated soon
    headlineProps?: {
        textAlign?: 'left' | 'right' | 'inherit' | 'initial' | 'unset' | 'center';
        lineHeight?: string;
    };
    header?: React.ReactNode;
    subHeader?: React.ReactNode;
}

class ModalHeaderClass extends React.PureComponent<ModalHeaderProps> {
    public render() {
        const {header, subHeader, image, headlineProps} = this.props;

        return [
            image ? (
                <div key="modalHeaderImage" className={`${CLASSNAME_PREFIX}modalTopBar_image`}>
                    <div className={`${CLASSNAME_PREFIX}modalHeaderImage`}>
                        <ProfileImage src={image} />
                    </div>
                </div>
            ) : null,
            <FullWidthBase key="modalHeader" paddingLeft={image ? s2 : s0}>
                {header ? (
                    <Subheader emphasized {...headlineProps} data-e2e-class="modal-header">
                        {header}
                    </Subheader>
                ) : null}
                {subHeader ? (
                    <Caption disabled data-e2e-class="modal-subheader">
                        {subHeader}
                    </Caption>
                ) : null}
            </FullWidthBase>,
        ];
    }
}

const ModalHeader = PureRenderIgnoreFunctions<ModalHeaderProps>(ModalHeaderClass);

const ModalCloseButton = (props: {onCloseModal: () => void}) => {
    return (
        <Button appearance="custom" className={`${CLASSNAME_PREFIX}outsideCloseButton`} onClick={props.onCloseModal}>
            <Close className={`${CLASSNAME_PREFIX}outsideCloseIcon`} appearance="white" />
        </Button>
    );
};

const StyledModalTopBar = styled.div`
    &&& {
        background: ${props => props.theme.invertedText};
        box-shadow: none;
        .${`${CLASSNAME_PREFIX}closeButtonContainer`} {
            align-self: flex-start;
        }
    }
`;

const StyledCloseButton = styled(Close).attrs(() => ({
    appearance: 'default',
}))<IconProps>``;

const StyledModalHeaderButton = styled(({...rest}) => <TextBase as={Button} {...rest} />)`
    color: ${props => (props.isWhite ? props.theme.invertedText : props.theme.text)};
`;

export const ModalHeaderButton: React.FC<
    React.PropsWithChildren<{onClick?: () => void; isWhite?: boolean; className?: string}>
> = ({isWhite, className, children, onClick}) => (
    <StyledModalHeaderButton
        buttonSize={ButtonSize.mini}
        appearance="icon"
        className={className}
        isWhite={isWhite}
        onClick={onClick}
    >
        <Subheader>{children}</Subheader>
    </StyledModalHeaderButton>
);

interface ModalTopBarProps {
    className?: string;
    supportsCancel?: boolean;
    leftMobileElement?: React.ReactNode;
    rightMobileElement?: React.ReactNode;
    header?: string;
    background?: string;
    subHeader?: string;
    onCloseModal?: () => void;
}

const ModalTopBar: React.FC<React.PropsWithChildren<ModalTopBarProps>> = props => {
    const {className, supportsCancel, leftMobileElement, rightMobileElement, onCloseModal, children, background} =
        props;
    const isWhite = background === 'white';
    const showCancelButton = supportsCancel && onCloseModal;

    return (
        <StyledModalTopBar
            className={classNames(
                `${CLASSNAME_PREFIX}modalTopBar`,
                className,
                {[`${CLASSNAME_PREFIX}showCancelButton`]: showCancelButton},
                {[`${CLASSNAME_PREFIX}hasRightMobileElement`]: hasValue(rightMobileElement)},
                {[`${CLASSNAME_PREFIX}hasLeftMobileElement`]: hasValue(leftMobileElement) || showCancelButton},
                {[`${CLASSNAME_PREFIX}white`]: isWhite},
            )}
        >
            {showCancelButton && !leftMobileElement ? (
                <div className={`${CLASSNAME_PREFIX}cancelButtonContainer`} onClick={onCloseModal}>
                    <ModalHeaderButton isWhite={isWhite}>{i18next.t('cancel')}</ModalHeaderButton>
                </div>
            ) : null}
            {leftMobileElement ? (
                <div className={`${CLASSNAME_PREFIX}leftMobileElement`}>{leftMobileElement}</div>
            ) : null}
            {children ? <div className={`${CLASSNAME_PREFIX}modalTopBar_children`}>{children}</div> : null}
            {onCloseModal ? (
                <div className={`${CLASSNAME_PREFIX}closeButtonContainer`} onClick={onCloseModal}>
                    <Button
                        id="closeModalButton"
                        buttonSize={ButtonSize.mini}
                        appearance="icon"
                        className={`${CLASSNAME_PREFIX}closeButton`}
                        data-e2e-class="modalCloseButton"
                    >
                        <StyledCloseButton className={`${CLASSNAME_PREFIX}closeIcon`} />
                    </Button>
                </div>
            ) : null}
            {rightMobileElement ? (
                <div className={classNames(`${CLASSNAME_PREFIX}rightMobileElement`)}>{rightMobileElement}</div>
            ) : null}
        </StyledModalTopBar>
    );
};

export const TopLayer = styled.div`
    position: fixed;
    top: 0;
    z-index: 9999;
`;

interface BackdropProps {
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    className?: string;
    children?: React.ReactNode;
    noOverflow?: boolean;
    layerOrder?: number;
    overrideZIndex?: number;
    noPadding?: boolean;
}

// eslint-disable-next-line no-restricted-syntax
enum ClickState {
    awaitingMouseEvent = 'awaitingMouseEvent',
    receivedMouseDown = 'receivedMouseDown',
    receivedMouseUpAfterMouseDown = 'receivedMouseUpAfterMouseDown',
}
export class BaseBackdrop extends React.Component<BackdropProps, {clickState: ClickState}> {
    constructor(props) {
        super(props);

        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            clickState: ClickState.awaitingMouseEvent,
        };
    }
    public render() {
        const {onClick} = this.props;
        const propsToApply = {
            ...this.props,
            onMouseUp: onClick && this.handleMouseUp,
            onMouseDown: onClick && this.handleMouseDown,
            onClick: onClick && this.handleClick,
        };
        return (
            <StyledBackdrop {...propsToApply} className={this.props.className}>
                {this.props.children}
            </StyledBackdrop>
        );
    }
    /*
        We introduced event handlers to mitigate an issue where you could mousedown inside a modal, move the mouse onto the
        backdrop, and then release it, which would then fire a click event on the backdrop and close it.
        In a similar way you could mousedown on the backdrop, move the mouse inside the modal, and then release it,
        which would also fire a click event on the backdrop and then closing the modal.

        Part of our strategy to circumvent this problem add state that keeps track of which events had been received
        by the backdrop. Only in the case where we get `mousedown` -> `mouseup` -> `onclick` will be actually call
        the `onClick` prop passed to this component. If our event handlers catch any other sequence they should
        reset the state back to it's initial value.
     */
    private handleMouseUp(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        if (PlatformHelper.isMobileApp()) {
            return;
        } else if (this.state.clickState === ClickState.receivedMouseDown) {
            this.setState({clickState: ClickState.receivedMouseUpAfterMouseDown});
        } else {
            this.setState({clickState: ClickState.awaitingMouseEvent});
        }
    }
    private handleMouseDown(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        if (PlatformHelper.isMobileApp()) {
            return;
        } else if (this.state.clickState === ClickState.awaitingMouseEvent) {
            this.setState({clickState: ClickState.receivedMouseDown});
        }
    }
    private handleClick(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        if (PlatformHelper.isMobileApp() && this.props.onClick) {
            this.props.onClick(e);
        } else if (this.state.clickState === ClickState.receivedMouseUpAfterMouseDown && this.props.onClick) {
            this.props.onClick(e);
        }
        this.setState({clickState: ClickState.awaitingMouseEvent});
    }
}

const getStackNumber = (layerOrder?: number, overrideZIndex?: number) => {
    if (layerOrder) {
        return 120 - layerOrder + 1;
    } else if (overrideZIndex) {
        return overrideZIndex;
    }
    return 120;
};

const StyledBackdrop = styled.div.attrs(props => ({
    className: classNames(
        'modalBackdrop',
        `${CLASSNAME_PREFIX}groupActionModalBackdrop`,
        props.className
            ? {
                  [props.className]: Boolean(props.className),
              }
            : undefined,
    ),
}))<{noOverflow?: boolean; layerOrder?: number; containerCSS?: any; overrideZIndex?: number; noPadding?: boolean}>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${props => getStackNumber(props.layerOrder, props.overrideZIndex)};
    background-color: ${props => props.theme.blackScrim};
    ${props =>
        props.noOverflow
            ? css`
                  overflow: hidden;
              `
            : ''}

    ${props =>
        props.noPadding
            ? css`
                  padding: ${props => props.theme.mf.spacing(0)};
              `
            : ''}

    ${props => (props.containerCSS ? props.containerCSS : '')};
`;

const Backdrop = PureRenderIgnoreFunctions<BackdropProps>(BaseBackdrop);

export const WhenModalIsOpenCSS = renderStyle(css`
    body {
        overflow: hidden;
    }
`);

export interface ModalProps {
    fullScreenOnMobile?: boolean;
    fixHeaderOnMobile?: boolean;
    fitToViewport?: boolean;
    onCloseModal?: () => void;
    containerClassName?: string;
    type?: 'tiny' | 'medium' | 'big' | 'fullscreen' | 'navigation' | 'custom';
    id?: string;
    className?: string;
    noBackdropOverflow?: boolean;
    layerOrder?: number;
    hideOverflow?: boolean; // Seems to be used in the SC
    suppressClassNameWarning?: boolean;
    ['data-e2e-id']?: string;
    noBackdrop?: boolean;
    children: React.ReactNode;
    /**
     * Sets a plain z-index value on the backdrop component, overriding the default of 120.
     * If layerOrder is also provided, this is ignored.
     */
    overrideZIndex?: number;
    noPadding?: boolean;
}

const typeClassMap = {
    tiny: `${CLASSNAME_PREFIX}tiny`,
    medium: `${CLASSNAME_PREFIX}medium`,
    big: `${CLASSNAME_PREFIX}big`,
    navigation: `${CLASSNAME_PREFIX}navModal`,
    fullscreen: `${CLASSNAME_PREFIX}fullscreen`,
    custom: `${CLASSNAME_PREFIX}custom`,
};

export const MODAL_PORTAL_DROPDOWN_ROOT_ID = 'modal-dropdown-portal-root';

class InnerModal extends React.Component<ModalProps & {actions: any; context: ModernFamlyContextType}> {
    public static defaultProps = {
        fullScreenOnMobile: true,
    };

    private shouldResetStatusBarOnUnmount = false;

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.stopEventPropagation = this.stopEventPropagation.bind(this);
    }

    public componentDidMount() {
        if (PlatformHelper.hasDynamicStatusBar() && PlatformHelper.isMobileSize() && this.props.fullScreenOnMobile) {
            StatusBar.getInfo().then(info => {
                /**
                 * The reason for not always wanting to "reset" the status bar on unmount,
                 * is that we want to preserve the black status bar text when multiple fullscreen modals are stacked on top of each other.
                 */
                if (info.style === Style.Dark) {
                    this.shouldResetStatusBarOnUnmount = true;
                }
                CapacitorUtil.setStatusBar(Style.Light);
            });
        }
    }

    public componentWillUnmount() {
        if (this.shouldResetStatusBarOnUnmount) {
            CapacitorUtil.setStatusBar(Style.Dark);
        }
    }

    public render() {
        const {
            onCloseModal,
            containerClassName,
            children,
            type,
            className,
            fixHeaderOnMobile,
            fullScreenOnMobile,
            id,
            fitToViewport,
            noBackdropOverflow,
            layerOrder,
            noBackdrop,
            overrideZIndex,
            noPadding,
            context: modernFamlyContext,
        } = this.props;

        // Due to the way MUI has implemented, we need to temporarily "detach" these listeners when a datepicker's "calendar popper" is open.
        // Otherwise, the "calendar popper" cannot be closed again due to not receiving the clicks.
        const listeners = modernFamlyContext?.isDisplayingDatePicker
            ? {}
            : {
                  onMouseDown: e => {
                      this.stopEventPropagation(e);
                  },
                  onMouseUp: e => {
                      this.stopEventPropagation(e);
                  },
                  onClick: e => {
                      this.stopEventPropagation(e);
                  },
              };

        const content = (
            <ModalContent
                data-e2e-id={this.props['data-e2e-id']}
                type={type === 'fullscreen' ? ModalContentType.fullscreen : undefined}
                id={id}
                {...listeners}
                className={classNames(
                    `${CLASSNAME_PREFIX}groupActionModal`,
                    'clearfix',
                    {[`${CLASSNAME_PREFIX}fullScreenOnMobile`]: fullScreenOnMobile},
                    {
                        [`${CLASSNAME_PREFIX}fixHeaderOnMobile`]: fixHeaderOnMobile,
                        [`${CLASSNAME_PREFIX}fitToViewport`]: fitToViewport,
                    },
                    type ? typeClassMap[type] : undefined,
                    className,
                )}
            >
                {children}
            </ModalContent>
        );

        return (
            <React.Fragment>
                <TopLayer id={MODAL_PORTAL_DROPDOWN_ROOT_ID} />
                <WhenModalIsOpenCSS />
                {noBackdrop ? (
                    content
                ) : (
                    <Backdrop
                        className={containerClassName}
                        onClick={
                            // Due to the way MUI has implemented, we need to temporarily "detach" this callback when a datepicker's "calendar popper" is open.
                            // Otherwise, the "calendar popper" cannot be closed again due to not receiving the clicks.
                            !modernFamlyContext?.isDisplayingDatePicker && Boolean(onCloseModal)
                                ? this.handleClose
                                : undefined
                        }
                        noOverflow={noBackdropOverflow}
                        layerOrder={layerOrder}
                        overrideZIndex={overrideZIndex}
                        noPadding={noPadding}
                    >
                        {content}
                    </Backdrop>
                )}
            </React.Fragment>
        );
    }
    private stopEventPropagation(e) {
        /*
            This was introduced to mitigate an issue where you could mousedown inside a modal, move the mouse onto the
            backdrop, and then release it, which would then fire a click event on the backdrop and close it.
            In a similar way you could mousedown on the backdrop, move the mouse inside the modal, and then release it,
            which would also fire a click event on the backdrop and then closing the modal.

            Part of our strategy to circumvent this problem is to stop mouse events from propagating from inside the modal out
            to the backdrop.

            Check the Backdrop class component to see the rest of the implementation introduced in an effort to fix this
         */
        e.stopPropagation();
    }
    private handleClose(e) {
        this.stopEventPropagation(e);
        if (this.props.onCloseModal) {
            this.props.onCloseModal();
        }
    }
}

const StyledInnerModal = styled(InnerModal).attrs(props => ({
    suppressClassNameWarning: true,
    className: classNames(props.className, 'inner-modal'),
}))`
    /* This follows the same breakpoint as in the styles for when we have full screen modal */
    ${props =>
        props.fullScreenOnMobile
            ? css`
                  @media all and (max-width: 50em) {
                      padding-top: env(safe-area-inset-top);
                  }
              `
            : ''}

    border-radius: ${props => props.theme.containerConfiguration.borderRadius};

    .${`${CLASSNAME_PREFIX}modalTopBar`} {
        border-top-left-radius: ${props => props.theme.containerConfiguration.borderRadius};
        border-top-right-radius: ${props => props.theme.containerConfiguration.borderRadius};
    }

    ${props =>
        props.hideOverflow
            ? css`
                  overflow: hidden;
              `
            : ''}
`;

const Modal = compose<React.ComponentType<ModalProps & {actions: any}>, React.ComponentType<ModalProps>>(
    connect<{}, {actions: any}, ModalProps, {}>(null, dispatch => ({
        actions: bindActionCreators(ModalsContainerActions as any, dispatch),
    })),
)
    .with(
        lifecycle<ModalProps & {actions: any}>(
            props => props.actions.modalOpened(),
            props => props.actions.modalClosed(),
        ),
    )
    .with<React.ComponentType<ModalProps & {actions: any}>>(PureRenderIgnoreFunctions)
    .with(withContext<ModernFamlyContextType, ModalProps & {actions: any}>(ModernFamlyContext.Consumer))
    .apply(StyledInnerModal);

const PortalModal: React.FC<React.PropsWithChildren<ModalProps & {rootId?: PortalRootId}>> = props => (
    <Portal rootId={props.rootId || PortalRootId.modalRoot}>
        <Modal {...props} />
    </Portal>
);

export {PortalModal, Modal, Backdrop, StyledBackdrop, ModalTopBar, ModalCloseButton, ModalBody, ModalHeader};
