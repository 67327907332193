import {Box} from 'modern-famly';
import React from 'react';
import styled, {css} from 'styled-components';
import {List} from 'immutable';
import {useTheme} from 'modern-famly';

import {useOnClickOutside} from 'web-app/react/hooks/use-on-click-outside';
import {type DistributiveOmit, hasValue} from 'web-app/util/typescript';
import {type NonEmptyArray} from 'web-app/util/non-empty-array';
import ActionSheet, {
    type ActionSheetItem,
    useActionSheet,
    type ActionSheetProps,
} from 'web-app/react/components/action-sheet/action-sheet';
import {divide, getSpacing, s1, s2, s4} from 'web-app/styleguide/spacing';
import {Body} from 'web-app/react/components/text/text';
import {ArrowDown1} from 'web-app/react/components/icons/streamline';
import {addHover} from 'web-app/styleguide/utils';

import Button, {ButtonWithChildren, type InputProps as ButtonInputProps} from './button-renderer';
import {IconPosition} from './types';
import {InlineBase} from '../layout/layout';

export const ACTION_SHEET_CLASS_NAME = 'expandable-button-action-sheet';

/**
 * Types
 */
// If you need icon or category you have to implement support for it in this component first
type ListItem<T extends string> = Omit<ActionSheetItem<T>, 'category'> & {
    // Sometimes, when the list item acts as a link, we want to open the link in a new tab,
    // so an optional "target: _blank" prop needs to be passed in
    target?: string;
};
type ListItems<T extends string> = NonEmptyArray<ListItem<T>>;
type InputProps<T extends string> = DistributiveOmit<ButtonInputProps, 'onClick' | 'href' | 'htmlFor'> & {
    items: ListItems<T>;
    onClick: (id: T) => void;
    canCollapse?: boolean;
    ['data-e2e-id']?: string;
    isOpen?: boolean;
    setIsOpen?: (isOpen: boolean) => void;
    buttonWrapperRef?: React.RefObject<HTMLDivElement>;
};

/**
 * makes sure that we can either pass in a state from outside, or use an internal state by deciding on the
 * correct return value
 *
 * @param payload open and setOpen state handling functions as passed in from outside
 * @returns the same like a useState tuple - first is a boolean indicating the current open state, the second
 * is the state setting function
 */
function useExpandableButtonState(payload?: {
    open: boolean;
    setOpen: (open: boolean) => void;
}): [isOpen: boolean, setIsOpen: (open: boolean) => void] {
    const {open, setOpen} = payload ?? {};
    const [isOpen, setIsOpen] = React.useState(open ?? false);
    return [open ?? isOpen, setOpen ?? setIsOpen];
}

/**
 * @summary
 * Use this component to render multiple options with our standard Button component.
 * Will open the ActionSheet on smaller devices, otherwise renders a dropdown relative to the Button.
 *
 * It has the same interface as Button, with the following exceptions:
 * - Excluded props: `'onClick' | 'href' | 'htmlFor'`
 * - New props: `<T>{items: ListItems<T>; onClick: (id: T) => void;}`
 *
 * `ListItems<T>` is a non-empty array of `ActionSheetItems<T>`
 *
 * @example
 * ```
 * const handleClick = (id: 'foo' | 'bar') => {...}
 * const items = [
 *   {id: 'foo', title: 'First item'},
 *   {id: 'bar', title: 'Second item', href: 'https://famly.co/'},
 *   {id: 'fuzz', title: 'Third item', onClick: mySpecialClickHandler},
 * ]
 * return (
 *   <ExpandableButton
 *     items={items}
 *     onClick={handleClick}
 *     size={ButtonSize.Large}
 *     appearance={ButtonAppearance.Primary}
 *   />
 * )
 * ```
 *
 * @deprecated Use the DropdownButton component from 'modern-famly' instead
 */
function ExpandableButton<T extends string>({
    items,
    onClick,
    canCollapse,
    isOpen: open,
    setIsOpen: setOpen,
    buttonWrapperRef,
    ...rest
}: InputProps<T>) {
    const buttonRef = React.useRef<HTMLElement>(null);

    const [isOpen, setIsOpen] = useExpandableButtonState(
        hasValue(open) && hasValue(setOpen) ? {open, setOpen} : undefined,
    );
    const handleClick = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

    const handleClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);
    useOnClickOutside(buttonWrapperRef ?? buttonRef, handleClose, [`.${ACTION_SHEET_CLASS_NAME}`]);

    const handleItemClick = React.useCallback(
        (id: T) => {
            onClick(id);
            handleClose();
        },
        [handleClose, onClick],
    );

    if (canCollapse && items.length === 1) {
        // If items only has a single option we collapse it into a regular button
        const [item] = items;
        return <CollpasedButton item={item} handleClick={handleItemClick} {...rest} />;
    }

    return (
        <StyledButtonWithChildren
            icon={StyledArrowDown1}
            iconPosition={IconPosition.Right}
            {...rest}
            onClick={handleClick}
            ref={buttonRef}
        >
            {isOpen && !rest.spinner && !rest.disabled ? (
                <ItemList items={items} handleClick={handleItemClick} handleClose={handleClose} />
            ) : null}
        </StyledButtonWithChildren>
    );
}

export default ExpandableButton;

/**
 * Design chapter has requested that the arrow icon for this component is a bit smaller.
 * To not interfere with the internals of the Button component I opted to just
 * change the icon. This approach will still work with different values of `ButtonSize`
 * making the size reduction scale with how the Button scales the icon.
 */
const StyledArrowDown1 = styled(ArrowDown1)`
    padding: ${getSpacing(divide(s1, 2))};
    box-sizing: border-box;
`;

/**
 * Overrides the hover effect. Normally the button uses `filter: brightness(97%)`, but this would
 * also apply to the list, since it's rendered inside the button.
 *
 * This approach tries to mimick the look of the filter, and it is very similar.
 */
const StyledButtonWithChildren = styled(ButtonWithChildren)<{disabled?: boolean}>`
    ${props =>
        props.disabled
            ? ''
            : addHover(css`
                  &:hover {
                      filter: none;
                      &:before {
                          content: '';
                          position: absolute;
                          top: 0;
                          bottom: 0;
                          left: 0;
                          right: 0;
                          background: rgba(0, 0, 0, 0.03);
                          pointer-events: none;
                      }
                  }
              `)}
`;

/**
 * Internal utility component for rendering a collapsed ExpandableButton.
 */
function CollpasedButton<T extends string>({
    item,
    handleClick,
    text,
    size,
    disabled,
    ...rest
}: DistributiveOmit<ButtonInputProps, 'onClick' | 'href' | 'htmlFor'> & {
    item: ListItem<T>;
    handleClick: (id: T) => void;
}) {
    const {onClick, href} = useItemProps(item, handleClick);

    return (
        <Button
            text={item.title}
            onClick={onClick}
            size={size}
            href={href}
            disabled={item.disabled || disabled}
            {...rest}
        />
    );
}

/**
 * Component for rendering the list of items. Will switch to use ActionSheet in responsive mode
 */
function ItemList<T extends string>({
    items,
    handleClick,
    handleClose,
}: {
    items: ListItems<T>;
    handleClick: (id: T) => void;
    handleClose: VoidFunction;
}) {
    // Action sheet rendering for responsive sizes
    const shouldUseActionSheet = useActionSheet();
    const itemsForActionSheet = React.useMemo(() => List(items), [items]);

    const handleItemClick = React.useCallback<Required<ActionSheetProps<T>>['onItemClick']>(
        item => {
            handleClick(item.id);
        },
        [handleClick],
    );

    if (shouldUseActionSheet) {
        return (
            <ActionSheet
                handleClose={handleClose}
                className={ACTION_SHEET_CLASS_NAME}
                items={itemsForActionSheet}
                onItemClick={handleItemClick}
            />
        );
    }

    return (
        <StyledItemList>
            {items.map(item => (
                <ListItem<T> key={item.id} item={item} handleClick={handleClick} />
            ))}
        </StyledItemList>
    );
}

export const StyledItemList = styled.ul`
    position: absolute;
    top: 100%;
    right: 0;
    transform: translate(0, ${getSpacing(s2)});

    background: ${props => props.theme.invertedText};
    border-radius: 3px; // Same as used internally in Button component
    border: 1px solid ${props => props.theme.delimiter};
    overflow: hidden;
`;

const InlineBody = styled(Body)`
    display: inline;
`;

/**
 * Component for rendering a single item in the list.
 */
function ListItem<T extends string>({item, handleClick}: {item: ListItem<T>; handleClick: (id: T) => void}) {
    const listItemProps = useItemProps(item, handleClick);
    const mfTheme = useTheme();
    return (
        <StyledListItem {...listItemProps} disabled={item.disabled} data-e2e-id={`expandable-button-${item.id}`}>
            <Box>
                <InlineBase>{item.icon}</InlineBase>
                <InlineBody
                    marginLeft={hasValue(item.icon) ? mfTheme.spacing(2) : mfTheme.spacing(0)}
                    emphasized
                    disabled={item.disabled}
                >
                    {item.title}
                </InlineBody>
            </Box>
        </StyledListItem>
    );
}

const StyledListItem = styled.li<{disabled?: boolean}>`
    display: list-item;
    text-align: right;
    padding: ${getSpacing(s4)};
    padding-right: ${getSpacing(s2)};
    &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.delimiter};
    }
    ${props =>
        props.disabled
            ? css``
            : addHover(css`
                  &:hover {
                      background: ${props => props.theme.backgroundHover};
                  }
              `)}
`;

/**
 * Small utility hook for transforming props that is used for both the ListItem renderer and the
 * CollpasedButton.
 *
 */
function useItemProps<T extends string>(item: ListItem<T>, handleClick: (id: T) => void) {
    const onClick = React.useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            if (item.disabled) {
                return;
            }

            handleClick(item.id);

            if (hasValue(item.onClick)) {
                item.onClick();
            }
        },
        [handleClick, item],
    );
    return {
        onClick,
        ...(hasValue(item.href)
            ? {
                  as: 'a' as const,
                  href: item.href,
                  target: item.target,
              }
            : {}),
    };
}
