/**
 *  Returns uppercase letters of the first and last name.
 *  If there is no space, only the first uppercase letter of the first name is returned.
 *
 * @param fullName a string containing names separated by spaces
 */
export const getInitials = (fullName: string) => {
    if (!fullName) {
        return '';
    }

    const names = fullName.split(' ');
    const firstName = names[0];
    const lastName = names[names.length - 1];

    if (names.length === 1) {
        return `${firstName.charAt(0)}`.toUpperCase();
    }

    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
};
