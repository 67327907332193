// eslint-disable-next-line no-restricted-syntax
export enum Visibility {
    Hidden,
    Visible,
}

interface Closed {
    visibility: Visibility.Hidden;
}

type Open<T> = {visibility: Visibility.Visible} & T;

export type ModalState<T> = Closed | Open<T>;

export type OpenState<ModalState> = ModalState extends Open<infer P> ? P : never;

export const open = <T = {}>(state: T) => {
    return {
        visibility: Visibility.Visible,
        ...state,
    } as ModalState<T>;
};

export const close = <T>() => {
    return {
        visibility: Visibility.Hidden,
    } as ModalState<T>;
};

export const isOpen = <T>(modalState: ModalState<T>): modalState is Open<T> =>
    modalState.visibility === Visibility.Visible;

export const isClosed = <T>(modalState: ModalState<T>): modalState is Closed =>
    modalState.visibility === Visibility.Hidden;

export type InferModalState<T> = T extends Open<infer InnerModalState> ? InnerModalState : never;
