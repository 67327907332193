import React from 'react';
import {Redirect} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {useDispatch} from 'react-redux';
import {Text, Stack, createStack, createBox} from 'modern-famly';

import {hasValue} from 'web-app/util/typescript';
import {accessToken, getSiteId, isQrSignin} from 'signin-app/login/selectors';
import translate from 'signin-app/helpers/translate';
import {fetchOverview} from 'signin-app/groups/actions';
import {institutionTitle, didLoadInstitution} from 'signin-app/groups/selectors';
import {media} from 'web-app/styleguide/utils';
import {PinLoadingState, getPillState} from 'web-app/react/components/pin/pin';
import {PIN, PINButtonContainer, StyledBackspace, StyledPINButton} from 'web-app/react/components/pin/pin';
import ProgressPills, {ProgressPill, PillAppearance} from 'web-app/react/components/progress-pills/progress-pills';
import {SettingsDropdown} from 'signin-app/components/settings-dropdown';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {type WhiteLabelConfiguration} from 'signin-app/whitelabel/types';
import {useBindDispatch} from 'web-app/react/hooks/use-bind-dispatch';
import {useTypedSelector} from 'signin-app/components/hooks';
import {saveIsQRSigninAvailable, saveSiteId} from 'signin-app/api/helpers/local-storage';
import {useTransformQuery} from 'web-app/react/hooks/use-transform-query';
import * as LoginQueries from 'signin-app/login/queries';
import * as LoginActions from 'signin-app/login/actions';
import {
    type GetQRSignInSettingsQuery,
    type GetQRSignInSettingsQueryVariables,
} from 'signin-app/login/__generated__/queries.api-types';
import {QRCode, TextContainer} from 'signin-app/qr/qr-screen';
import * as Breakpoints from 'web-app/styleguide/breakpoint-helpers';
import {HeaderLogoContainer} from 'signin-app/components/containers';
import {Initials} from 'signin-app/components/initials';
import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

import {redirectUrl, pinHolder, pinState} from './selectors';
import {setPinValue, pinLogin} from './actions';

const StyledContainer = styled(createStack({}))`
    /** Needed to add scroll to accommodate PIN + QR on smaller screens and in case there is longer text in DE */
    max-height: 100vh;
    overflow: auto;
`;

const LoginContainer = styled(
    createBox({
        maxWidth: 'calc(100vw - 48px)',
        position: 'relative',
        width: '380px',
        marginTop: {
            tabletLandscape: '0',
        },
    }),
)<{whiteLabelConfiguration: WhiteLabelConfiguration}>`
    /* Drop shadow around and underneath PIN */
    ${PINButtonContainer} {
        overflow: hidden;
        border-radius: 16px;
    }
`;

const BodyContainer = createStack({mt: 5, pb: 11});

const StyledButtonsContainer = styled(
    createStack({
        px: '40px',
        pt: '40px',
    }),
)`
    ${Breakpoints.tabletLandscapeAndLarger`
        box-sizing: border-box;
        position: absolute;
        width: 100%;
    `}
`;

const makeProgressPillCSS = (configuration: WhiteLabelConfiguration) => css`
    ${ProgressPill} {
        box-shadow: 0 0 0 2px ${configuration.PinShadowColor};

        &:after {
            background: ${configuration.PinPillColor};
        }
    }
`;

const StyledProgressPills = styled(ProgressPills)<{whiteLabelConfiguration: WhiteLabelConfiguration}>`
    ${props => makeProgressPillCSS(props.whiteLabelConfiguration)}

    text-align: left;
    padding: ${props => props.theme.mf.spacing(4, 0, 3, 0)};
`;

const PINandQRContainer = createStack({
    flex: 1,
    marginTop: {
        base: 12,
    },
    maxWidth: '100vw',
    overflow: 'auto hidden',
    flexDirection: {
        base: 'column',
        tabletLandscape: 'row',
        mobileLandscape: 'row',
        laptop: 'row',
    },
    paddingBottom: {
        base: '60px',
        mobileLandscape: 0,
    },
});

const QRContainer = createStack({
    mt: {
        base: 8,
        tabletLandscape: 0,
        mobileLandscape: 0,
        laptop: 0,
    },
});

const PinTextContainer = styled(TextContainer)`
    margin-bottom: 0;
`;

// We're stacking the logo and name horizontally on some tablets placed horizontally, to avoid scroll. 780px was a number agreed on with the design.
const NurseryLogoNameContainer = styled(
    createStack({
        flex: 1,
        alignItems: 'center',
        gap: '12px',
    }),
)`
    flex-direction: column !important;
    max-width: 100%;

    h4 {
        word-break: break-word;
        padding: ${({theme}) => theme.mf.spacing(2, 0)};
        text-align: center;
    }

    @media (max-height: 780px) and (min-width: 740px) {
        flex-direction: row !important;

        & > div:first-child {
            width: 40px;
            height: 40px;
        }

        h2 {
            font-size: 32px;
        }

        h4 {
            text-align: left;
        }
    }
`;

const StyledLogoContainer = styled(createStack({}))<{customBorderColor?: string}>`
    border: 4px solid ${props => props.customBorderColor};
    min-width: 128px;
    min-height: 128px;
    flex-shrink: 0;

    @media (max-height: 780px) and (min-width: 740px) {
        min-width: 40px;
        min-height: 40px;
        width: 40px !important;
        height: 40px !important;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const PIN_SIZE = 4;

const Pin: React.FC<{}> = () => {
    const token = useTypedSelector(accessToken);
    const title = useTypedSelector(institutionTitle);
    const url = useTypedSelector(redirectUrl);
    const siteId = useTypedSelector(getSiteId);
    const institutionLoaded = useTypedSelector(didLoadInstitution);
    const siteImage = useTypedSelector(
        state => state.institutionOverview.institution && state.institutionOverview.institution.image.large,
    );
    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));
    const p400 = useCustomWhitelabelColor('p400');
    const isQRSigninEnabled = useTypedSelector(isQrSignin);
    const [siteIdFromNetwork, setSiteIdFromNetwork] = React.useState('');
    // Initials for the institution title (max 2 letters - if two word name)
    const initials = React.useMemo(() => {
        if (title) {
            const words = title.split(' ');

            if (words.length > 1) {
                return words[0][0] + words[1][0];
            }

            return words[0][0];
        }

        return '';
    }, [title]);

    const dispatch = useDispatch();

    /** Allows to show the QR code immediately after it is enabled from settings, without the need to log out */
    const [qrEnabledFromNetwork, {loading}] = useTransformQuery<
        GetQRSignInSettingsQuery,
        GetQRSignInSettingsQueryVariables
    >()(
        LoginQueries.GetQRSignInSettings,
        {...(siteId ? {variables: {siteIds: [siteId]}, fetchPolicy: 'network-only'} : {fetchPolicy: 'network-only'})},
        ({data}) => {
            if (data && data.qrSignin.siteSettings.siteSettings[0]?.siteId && !siteId && !siteIdFromNetwork) {
                /** When switching from v1 to v2 without logging out, the siteId is not there in local storage (it's only saved when logging in to v2).
                 * And because it is needed for the QR component, we're storing it additionally in state for when it's fetched */
                saveSiteId(data.qrSignin.siteSettings.siteSettings[0].siteId);
                setSiteIdFromNetwork(data.qrSignin.siteSettings.siteSettings[0].siteId);
            }
            return data?.qrSignin.siteSettings.siteSettings[0]?.isEnabled;
        },
    );

    React.useEffect(() => {
        if (token && !institutionLoaded) {
            fetchOverview.dispatch(token);
        }
    }, [institutionLoaded, token]);

    React.useEffect(() => {
        if (!loading && hasValue(qrEnabledFromNetwork) && isQRSigninEnabled !== qrEnabledFromNetwork) {
            saveIsQRSigninAvailable(Boolean(qrEnabledFromNetwork));
            dispatch(LoginActions.qrSigninChange.action(Boolean(qrEnabledFromNetwork)));
        }
    }, [dispatch, isQRSigninEnabled, loading, qrEnabledFromNetwork]);

    const onPinFinish = React.useCallback((pinValue: string) => {
        pinLogin.dispatch(pinValue);
    }, []);

    if (url) {
        return <Redirect push to={url} />;
    }

    return (
        <StyledContainer
            flexDirection="column"
            className="container"
            minHeight={{base: 'none', tabletLandscape: isQRSigninEnabled ? 'none' : '100vh'}}
        >
            <StyledButtonsContainer justifyContent="space-between">
                <HeaderLogoContainer>
                    <whitelabelConfiguration.PinLogo />
                </HeaderLogoContainer>

                <SettingsDropdown showWarning siteName={title} variant="light" />
            </StyledButtonsContainer>

            <BodyContainer
                flex={1}
                flexDirection={{base: 'column', tabletLandscape: isQRSigninEnabled ? 'column' : 'row'}}
                alignItems="center"
                justifyContent={{base: 'center', tabletLandscape: 'space-between'}}
                maxWidth="800px"
                width="100%"
                mx="auto"
                color="n0"
            >
                {/* Image and title */}
                <NurseryLogoNameContainer>
                    <StyledLogoContainer
                        height="128px"
                        width="128px"
                        borderRadius="50%"
                        overflow="hidden"
                        customBorderColor={p400}
                    >
                        {whitelabelConfiguration.WithSiteImage && siteImage ? (
                            <img src={siteImage} width={128} height={128} />
                        ) : (
                            <Initials initials={initials} />
                        )}
                    </StyledLogoContainer>

                    <Text variant="h4" emphasized>
                        {title}
                    </Text>
                </NurseryLogoNameContainer>
                <PINandQRContainer
                    justifyContent={isQRSigninEnabled ? 'space-between' : 'center'}
                    width={
                        isQRSigninEnabled
                            ? {
                                  tabletLandscape: '864px',
                              }
                            : undefined
                    }
                >
                    <Stack flexDirection="column" alignItems="center" data-e2e-id="pin-container">
                        <PinTextContainer>
                            <Text variant="h6" marginBottom="10px">
                                {translate('enterPinCode')}
                            </Text>
                        </PinTextContainer>
                        <PerformantProgressPills />
                        <LoginContainer whiteLabelConfiguration={whitelabelConfiguration}>
                            <PerformantPIN handleFinish={onPinFinish} />
                        </LoginContainer>
                    </Stack>
                    {isQRSigninEnabled ? (
                        <QRContainer flexDirection="column" alignItems="center" data-e2e-id="qr-container">
                            <TextContainer>
                                <Text variant="h5" marginBottom="10px">
                                    {translate('qrCodeExplanation')}
                                </Text>
                            </TextContainer>
                            <QRCode institutionId={siteId ? siteId : siteIdFromNetwork} />
                        </QRContainer>
                    ) : null}
                </PINandQRContainer>
            </BodyContainer>
        </StyledContainer>
    );
};

export default Pin;

const PerformantProgressPills = () => {
    const length = useTypedSelector(pinHolder).length;
    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));

    const pinLoadingState = useTypedSelector(pinState);
    const pillState = getPillState(pinLoadingState);

    return (
        <StyledProgressPills
            whiteLabelConfiguration={whitelabelConfiguration}
            count={PIN_SIZE}
            appearance={PillAppearance.Colorful}
            activeCount={length}
            pillState={pillState}
        />
    );
};

const PerformantPIN = ({handleFinish}) => {
    const pinValue = useTypedSelector(pinHolder);
    const handleChangePin = useBindDispatch(setPinValue);

    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));

    const pinLoadingState = useTypedSelector(pinState);

    React.useEffect(() => {
        if (pinLoadingState === PinLoadingState.Error) {
            handleChangePin();
        }
    }, [handleChangePin, pinLoadingState]);

    return (
        <div>
            <StyledPIN
                value={pinValue}
                pinSize={PIN_SIZE}
                onChange={handleChangePin}
                onFinish={handleFinish}
                whiteLabelConfig={whitelabelConfiguration}
                withPills={false}
            />
        </div>
    );
};

const StyledPIN = styled(PIN)<{whiteLabelConfig: WhiteLabelConfiguration}>`
    ${StyledPINButton} {
        border: none;
        color: ${props => props.theme.mf.colorPalette.n0};
        font-size: 48px;
        font-weight: 400;
        position: relative;
        padding: 16px 24px;
        font-family: ${props => props.theme.fontConfiguration.family} !important;
        justify-content: end;

        &,
        &&&[disabled],
        &:hover:not([disabled]) {
            opacity: 1;
            background-color: transparent;
        }

        &:nth-child(-n + 3) {
            margin-top: 16px;
        }

        &:nth-child(-n + 9)::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 64px;
            height: 1px;
            background: ${props => props.theme.mf.colorPalette.n0};
            opacity: 0.5;
        }

        &:nth-child(3n + 1),
        &:nth-child(3n + 2) {
            border-right: 1px solid ${props => props.theme.mf.colorPalette.n0};
        }
    }
    ${StyledBackspace} {
        height: 71px;
        width: 71px;
        g {
            fill: ${props => props.theme.mf.colorPalette.n0};
        }
    }

    ${PINButtonContainer} {
        // Unified height for all buttons (80px) + top padding (16px)
        grid-template-rows: repeat(4, 96px);
    }

    ${media.mobileSideways`
        ${StyledPINButton} {
            // Overrides parent font-size
            font-size: 48px !important;
        }

        
        ${PINButtonContainer} {
            // Unified height for all buttons (80px) + top padding (16px)
            grid-template-rows: repeat(4, 96px);
        }
    `}

    ${media.mobile`
        ${PINButtonContainer} {
            // Unified height for all buttons (80px) + top padding (16px)
            grid-template-rows: repeat(4, 96px);
        }

        ${StyledPINButton} {
            font-size: 48px;
        }
    `}

    ${props => makeProgressPillCSS(props.whiteLabelConfig)}
`;
