import {useState, useEffect, type RefObject, useCallback} from 'react';
import {type FromEventTarget} from 'rxjs/internal/observable/fromEvent';

import {useEventHandler} from './use-event-handler';

interface Props<T extends Event> {
    target: RefObject<FromEventTarget<T>>;
    skip?: boolean;
    debounce?: number;
}
/**
 * Use this hook to determine if an element has scrolled.
 *
 * @example
 * const target = useRef(MyComponentRef)
 * const didScroll = useDidScroll({taget: target})
 *
 * @example
 * const targets = useRef([window, MyComponentRef, document, document.getElementById('foo')])
 * const didScroll = useDidScroll({taget: targets})
 */
export const useDidScroll = <T extends Event>(props: Props<T>) => {
    const position = useScrollPosition(props);
    const [didScroll, setDidScroll] = useState(false);

    useEffect(() => {
        setDidScroll(position > 0);
    }, [position]);

    if (props.skip) {
        return false;
    }

    return didScroll;
};

export const useScrollPosition = <T extends Event>({target, skip, debounce = 100}: Props<T>) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = useCallback((event: Event) => {
        const target = event.target;
        if (target instanceof Element) {
            setScrollPosition(target.scrollTop);
        }
        if (target instanceof Window) {
            setScrollPosition(target.scrollY);
        }
        if (target instanceof Document) {
            setScrollPosition(window.scrollY);
        }
    }, []);

    useEventHandler({
        target,
        skip,
        debounce,
        handler: handleScroll,
        eventName: 'scroll',
    });

    if (skip) {
        return 0;
    }

    return scrollPosition;
};

export const useScrollXPosition = <T extends Event>({target, skip, debounce = 100}: Props<T>) => {
    const [scrollXPosition, setScrollXPosition] = useState(0);

    const handleScroll = useCallback((event: Event) => {
        const target = event.target;
        if (target instanceof Element) {
            setScrollXPosition(target.scrollLeft);
        }
        if (target instanceof Window) {
            setScrollXPosition(target.scrollX);
        }
        if (target instanceof Document) {
            setScrollXPosition(window.scrollX);
        }
    }, []);

    useEventHandler({
        target,
        skip,
        debounce,
        handler: handleScroll,
        eventName: 'scroll',
    });

    if (skip) {
        return 0;
    }

    return scrollXPosition;
};
