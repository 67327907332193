import React from 'react';

import {type IChild} from 'signin-app/entities/children/model';
import {type PinChild} from 'signin-app/pin/models';
import {getInitials} from 'signin-app/util/get-initials';
import {type RecordInstance} from 'web-app/react/entities/factory/reducer-factory';
import * as CheckOutSelectors from 'signin-app/child/check-out/selectors';

import {HeaderPerson} from './header-person';
import {useTypedSelector} from './hooks';

type HeaderChildProps = {
    child: PinChild | RecordInstance<IChild>;
};

export const HeaderChild = ({child}: HeaderChildProps) => {
    const isDataLimited = useTypedSelector(CheckOutSelectors.limitedData);

    const childName = React.useMemo(
        () => (isDataLimited ? child.name.firstName : child.name.fullName),
        [child.name.firstName, child.name.fullName, isDataLimited],
    );
    const initials = React.useMemo(() => getInitials(childName), [childName]);

    return <HeaderPerson image={child.image.small} initials={initials} name={childName} />;
};
