import React from 'react';
import Stack from '@mui/material/Stack';

import {
    Button,
    type ButtonProps,
    DropdownButton,
    type FavoriteButtonProps,
    FavoriteButton,
} from 'modern-famly/components/input';
import {extractDataProps, useDataProps, type DataProps} from 'modern-famly/components/util';

import {type IconName} from '../icon';
import {ListItem} from './list-item';
import {ClassNames} from './shared';

export type ListItemActionButtonProps = {
    label: string;
    icon: IconName;
    disabled?: boolean;
    variant?: Extract<ButtonProps['variant'], 'critical-transparent' | 'transparent'>;
    onClick: VoidFunction;
} & DataProps;

export type ListItemActionsProps = {
    buttons?: ListItemActionButtonProps[];
    onExpand?: VoidFunction;
    favoriteButton?: Pick<FavoriteButtonProps, 'onToggle' | 'toggled'> & DataProps;

    /**
     * If true, the buttons will always be displayed in a dropdown.
     *
     * This can be useful when there are an different number of buttons in the list across different rows,
     * which can cause the list to look cluttered.
     */
    alwaysDisplayButtonsInDropdown?: boolean;
} & DataProps;

const MAX_ICONS_IN_COLUMN = 3;

export const ListItemActions = (props: ListItemActionsProps) => {
    const dataProps = useDataProps(props);

    const buttons = React.useMemo(() => {
        if (!props.buttons) {
            return null;
        }

        if (props.alwaysDisplayButtonsInDropdown || props.buttons.length > MAX_ICONS_IN_COLUMN) {
            return (
                <DropdownButton
                    icon="more_horiz"
                    aria-label="Actions"
                    variant="transparent"
                    size="compact"
                    onClick={event => {
                        // Prevent the click event from bubbling up to the parent row which might contain a link
                        event.preventDefault();
                    }}
                    options={props.buttons.map(button => ({
                        label: button.label,
                        color: button.variant === 'critical-transparent' ? 'r400' : undefined,
                        icon: button.icon,
                        disabled: button.disabled,
                        onClick: button.onClick,
                        ...extractDataProps(button),
                    }))}
                />
            );
        }

        return (
            <>
                {props.buttons.map((button, index) => {
                    return (
                        <Button
                            key={index}
                            icon={button.icon}
                            isDisabled={button.disabled}
                            size="compact"
                            aria-label={button.label}
                            variant={button.variant === 'critical-transparent' ? 'critical-transparent' : 'transparent'}
                            onClick={button.onClick}
                            {...extractDataProps(button)}
                        />
                    );
                })}
            </>
        );
    }, [props.buttons, props.alwaysDisplayButtonsInDropdown]);

    const {onExpand} = props;

    const _onExpand = React.useMemo(() => {
        if (!onExpand) {
            return undefined;
        }

        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            // Prevent the click event from bubbling up to the parent row which might contain a link
            event.preventDefault();

            onExpand?.();
        };
    }, [onExpand]);

    return (
        <ListItem
            onClick={e => {
                e.stopPropagation();
                e.preventDefault();
            }}
            px={0}
            className={ClassNames.ListItemActions}
        >
            <Stack
                direction="row"
                spacing={1}
                height="100%"
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
                sx={theme => ({borderLeft: `1px solid ${theme.modernFamlyTheme.colorPalette.n75}`})}
                px={4}
                {...dataProps}
            >
                {buttons}
                {props.favoriteButton ? <FavoriteButton {...props.favoriteButton} size="compact" /> : null}
                {_onExpand ? (
                    <Button
                        icon="right_panel_open"
                        aria-label="open drawer"
                        variant="transparent"
                        size="compact"
                        onClick={_onExpand}
                    />
                ) : null}
            </Stack>
        </ListItem>
    );
};
