import React from 'react';
import {Box, Stack, Text} from 'modern-famly';

import {PersonImage} from 'signin-app/components/person-image';
import PersonSelect from 'signin-app/components/person-select';
import {useTypedSelector} from 'signin-app/components/hooks';
import * as GroupsSelectors from 'signin-app/groups/selectors';
import * as ChildSelectors from 'signin-app/child/selectors';
import translate from 'signin-app/helpers/translate';
import {getInitials} from 'signin-app/util/get-initials';
import * as PinSelectors from 'signin-app/pin/selectors';

import * as Selectors from './selectors';
import {CheckOutSubmit} from './checkout-submit';
import {useCheckOutChild} from './check-out-child-context';

const DetailsRow = ({start, end}: {start: string; end: React.ReactNode}) => {
    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                color="n400"
                p={6}
                minHeight="112px"
                boxSizing="border-box"
            >
                <Text variant="h6">{start}</Text>
                <Box>{end}</Box>
            </Stack>
            <Box height="1px" backgroundColor="n100" width="100%" />
        </Box>
    );
};

const PickupPerson = ({image, name}: {image?: string; name: string}) => {
    return (
        <Stack alignItems="center" gap={2}>
            {image && <PersonImage size="sm" image={image} initials={getInitials(name)} />}
            <Text variant="h5" emphasized>
                {name}
            </Text>
        </Stack>
    );
};

const PickupRoom = ({image, title}: {image: string; title: string}) => {
    const initials = React.useMemo(() => {
        const firstTwoWordsOfTitle = title.split(' ').slice(0, 2).join(' ');
        return getInitials(firstTwoWordsOfTitle);
    }, [title]);

    return (
        <Stack alignItems="center" gap={2}>
            <PersonImage size="sm" image={image} initials={initials} />
            <Text variant="h5" emphasized>
                {title}
            </Text>
        </Stack>
    );
};

type CheckOutDetailsProps = {
    showSwitchRoomView: VoidFunction;
};

export const CheckOutDetails = (props: CheckOutDetailsProps) => {
    const {child} = useCheckOutChild();
    const {showSwitchRoomView} = props;
    const pickupTime = useTypedSelector(state => Selectors.pickupTime(state, {child}));
    const groups = useTypedSelector(GroupsSelectors.groups);
    const goHomeWithChild = useTypedSelector(state => PinSelectors.goHomeWithChild(state, {childId: child.id}));
    const currentChildGroupId = useTypedSelector(state => ChildSelectors.currentChildGroupId(state, {id: child.id}));
    const room = React.useMemo(() => {
        const currentGroup =
            groups.find(g => g.groupId === currentChildGroupId) ?? groups.find(g => g.groupId === child.groupId);

        if (!currentGroup) {
            return null;
        }

        return {title: currentGroup.title, image: currentGroup.image.small};
    }, [groups, currentChildGroupId, child.groupId]);

    const {pickupName, pickupImage} = useTypedSelector(state => {
        const pickupName = Selectors.pickupFullName(state, {
            child,
        });

        const pickupImage = Selectors.pickupImage(state, {
            child,
        });

        return {pickupName, pickupImage};
    });

    return (
        <>
            <PersonSelect activeChildId={child.id} />
            <Stack direction="column" width="100%" mx="auto" maxWidth="568px" gap={1}>
                {pickupName && (
                    <DetailsRow
                        start={goHomeWithChild ? translate('goingHomeWith') : translate('checkoutPickedUpBy')}
                        end={<PickupPerson name={pickupName} image={pickupImage} />}
                    />
                )}
                {pickupTime && (
                    <DetailsRow
                        start={translate('checkoutPickupTime')}
                        end={
                            <Text variant="h5" emphasized>
                                {pickupTime}
                            </Text>
                        }
                    />
                )}
                {room && (
                    <DetailsRow
                        start={translate('checkoutPickupFrom')}
                        end={room && <PickupRoom image={room.image} title={room.title} />}
                    />
                )}
            </Stack>
            <CheckOutSubmit showSwitchRoomView={showSwitchRoomView} />
        </>
    );
};
