import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Text, Stack, createStack, Icon} from 'modern-famly';

import translate from 'signin-app/helpers/translate';
import {StyledTextContainer} from 'signin-app/child/check-in/add-pickup-person';

export const StyledStack = styled(
    createStack({
        boxSizing: 'border-box',
        width: {base: '80px', tabletPortrait: '96px'},
        height: {base: '80px', tabletPortrait: '96px'},
        borderRadius: {base: 5, tabletPortrait: 6},
        alignItems: 'center',
        justifyContent: 'center',
    }),
)`
    border: 2px dashed ${props => props.theme.mf.colorPalette.n200};
`;

export const GoingHomeWith = ({child}) => {
    return (
        <Stack px={{base: 3, tabletPortrait: 6}} py={{base: 2, tabletPortrait: 3}}>
            <Link to={`/go-home-with/${child.id}`}>
                <StyledStack>
                    <Icon name="child_care" color="n300" size={36} />
                </StyledStack>
                <StyledTextContainer shouldWrap={true}>
                    <Text variant="body-small" color="n400" emphasized>
                        {translate('goingHomeWith')}
                    </Text>
                </StyledTextContainer>
            </Link>
        </Stack>
    );
};
