import {List, Record} from 'immutable';

import {Group} from 'web-app/react/entities/groups/model';
import Behavior from 'web-app/behaviors/behavior';
import Name, {Name as NameRecord} from 'web-app/react/entities/common/name';
import Image, {Image as ImageRecord} from 'web-app/react/entities/common/image';
import {OpeningHours} from 'web-app/react/modals-container/child-pickup-modal/models/shared';

interface Institution {
    image: string;
    institutionId: string;
    timezone: string;
    title: string;
    usePincodeCheckin: boolean;
}

class InstitutionRecord extends Record<Institution>({
    image: '',
    institutionId: '',
    timezone: '',
    title: '',
    usePincodeCheckin: false,
}) {}

class Relation extends Record<{
    image: ImageRecord;
    name: NameRecord;
    relationId: string;
    roles: List<string>;
}>({
    image: Image.default,
    name: Name.default,
    relationId: '',
    roles: List(),
}) {}

interface RawChild {
    childId: string;
    firstName: string;
    image: string;
    name: string;
}

export class PinChild extends Record<{
    id: string;
    childId: string;
    image: ImageRecord;
    name: NameRecord;
    checkedIn: boolean;
    pickupRelationId: string | null;
    pickupTime: string | null;
    goHomeWithChildId?: string;
    groupId: string;
    statusRegistrations?: undefined;
    lastCheckIn?: undefined;
}>({
    id: '',
    childId: '',
    image: Image.default,
    name: Name.default,
    checkedIn: false,
    pickupRelationId: '',
    pickupTime: '',
    goHomeWithChildId: '',
    groupId: '',
}) {}

interface RawEmployee {
    employeeId: string;
    groupId: string;
    image: string;
    name: typeof Name.default;
}

export class PinEmployee extends Record<{
    id: string;
    employeeId: string;
    groupId: string;
    image: ImageRecord;
    name: typeof Name.default;
    estimatedCheckoutTime: string | null;
    checkedIn: boolean;
    tempGroupId?: string;
}>({
    id: '',
    employeeId: '',
    groupId: '',
    image: Image.default,
    name: Name.default,
    estimatedCheckoutTime: '',
    checkedIn: false,
    tempGroupId: '',
}) {}

interface RawResponse {
    institution: Institution;
}

interface RawChildResponse extends RawResponse {
    behaviors: Array<{id: string}>;
    child: RawChild;
    childCheckin: null | {
        pickupRelationId: string;
        pickupTime: string;
        groupId: string;
        goHomeWithChildId?: string;
    };
    childLeave: null | any;
    institution: Institution;
    openingHours: {
        day: number;
        fromHour: number;
        fromMinute: number;
        open: boolean;
        toHour: number;
        toMinute: number;
    };
    relations: Array<{
        image: string;
        name: string;
        relationId: string;
        roles: string[];
    }>;
}

interface RawEmployeeResponse extends RawResponse {
    employee: RawEmployee;
    employeeCheckin: null | {
        pickupRelationId: string;
        estimatedCheckoutTime: string;
        groupId: string;
    };
    groups: Array<{
        groupId: string;
        image: string;
        title: string;
    }>;
}

export class ChildCheckinOverview extends Record<{
    behaviors: List<Behavior>;
    child: PinChild;
    institution: InstitutionRecord;
    openingHours: OpeningHours;
    relations: List<Relation>;
}>({
    behaviors: List(),
    child: new PinChild(),
    institution: new InstitutionRecord(),
    openingHours: new OpeningHours(),
    relations: List(),
}) {}

class EmployeeCheckin extends Record<{
    pickupRelationId: string;
    estimatedCheckoutTime: string;
}>({
    pickupRelationId: '',
    estimatedCheckoutTime: '',
}) {}

export interface EmployeeCheckinOverview {
    employee: PinEmployee;
    employeeCheckin: EmployeeCheckin | null;
    groups: List<Group>;
}

export interface RawPinLoginResponse {
    children: RawChildResponse[];
    employees: RawEmployeeResponse[];
    loginId: string;
    canOpenDoor: boolean;
}

export interface PinLoginResponse {
    children: List<ChildCheckinOverview>;
    employees: List<EmployeeCheckinOverview>;
    loginId: string;
    canOpenDoor: boolean;
}

export const preparePinLoginResponse = (response: RawPinLoginResponse): PinLoginResponse => {
    return {
        children: List(
            response.children.map(childCheckinOverview => {
                return new ChildCheckinOverview({
                    institution: new InstitutionRecord(childCheckinOverview.institution),
                    openingHours: new OpeningHours(childCheckinOverview.openingHours),
                    behaviors: List(childCheckinOverview.behaviors.map(b => new Behavior(b))),
                    child: new PinChild({
                        name: new NameRecord({
                            fullName: childCheckinOverview.child.name,
                            firstName: childCheckinOverview.child.firstName,
                            lastName: childCheckinOverview.child.name.split(' ')[1],
                        }),
                        checkedIn: Boolean(childCheckinOverview.childCheckin),
                        pickupRelationId:
                            childCheckinOverview.childCheckin && childCheckinOverview.childCheckin.pickupRelationId,
                        pickupTime: childCheckinOverview.childCheckin && childCheckinOverview.childCheckin.pickupTime,
                        id: childCheckinOverview.child.childId,
                        image: Image.fromAPI(childCheckinOverview.child.image),
                        goHomeWithChildId: childCheckinOverview.childCheckin
                            ? childCheckinOverview.childCheckin.goHomeWithChildId
                            : '',
                        groupId: childCheckinOverview.childCheckin ? childCheckinOverview.childCheckin.groupId : '',
                    }),
                    relations: List(
                        childCheckinOverview.relations.map(
                            relation =>
                                new Relation({
                                    relationId: relation.relationId,
                                    roles: List(relation.roles),
                                    image: Image.fromAPI(relation.image),
                                    name: new NameRecord({
                                        firstName: relation.name,
                                    }),
                                }),
                        ),
                    ),
                });
            }),
        ),
        employees: List(
            response.employees.map(employeeCheckinOverview => ({
                ...employeeCheckinOverview,
                employeeCheckin:
                    employeeCheckinOverview.employeeCheckin &&
                    new EmployeeCheckin(employeeCheckinOverview.employeeCheckin),
                groups: List(
                    employeeCheckinOverview.groups.map(
                        group =>
                            new Group({
                                ...group,
                                image: new ImageRecord({
                                    large: group.image,
                                    small: group.image,
                                }),
                            }),
                    ),
                ),
                employee: new PinEmployee({
                    ...employeeCheckinOverview.employee,
                    image: new ImageRecord({
                        small: employeeCheckinOverview.employee.image,
                        large: employeeCheckinOverview.employee.image,
                    }),
                    id: employeeCheckinOverview.employee.employeeId,
                    estimatedCheckoutTime:
                        employeeCheckinOverview.employeeCheckin &&
                        employeeCheckinOverview.employeeCheckin.estimatedCheckoutTime,
                    checkedIn: Boolean(employeeCheckinOverview.employeeCheckin),
                    tempGroupId: employeeCheckinOverview.employeeCheckin
                        ? employeeCheckinOverview.employeeCheckin.groupId
                        : '',
                }),
            })),
        ),
        loginId: response.loginId,
        canOpenDoor: response.canOpenDoor,
    };
};
