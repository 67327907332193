import moment from 'moment-timezone';

import createSelector from 'web-app/util/custom-create-selector';
import RoutesMap from 'signin-app/routes/routes-map';
import {type RootState} from 'signin-app/redux/main-reducer';
import * as GroupsSelectors from 'signin-app/groups/selectors';
import SignInAppBehaviorIds from 'web-app/behaviors/signin-app-behavior-ids';
import type Name from 'web-app/react/entities/common/name';
import {isPinApp} from 'signin-app/login/selectors';
import * as PinSelectors from 'signin-app/pin/selectors';
import {hasValue} from 'web-app/util/typescript';

import {makeChildSelector, relations, getPinChild} from '../selectors';

interface PickupNameProps {
    child: {
        pickupRelationId: string | null;
        childId: string;
        id: string;
    };
}

const entityChildSelector = makeChildSelector(RoutesMap.childCheckOut);

export const getChild = createSelector(isPinApp, getPinChild, entityChildSelector, (pinApp, pinChild, entityChild) => {
    if (pinApp) {
        return pinChild;
    }
    return entityChild;
});

export const isCheckingOut = (state: RootState) => state.childCheckOut.isCheckingOut;
export const statusConfigs = (state: RootState) => state.childCheckOut.statusConfigs;

export const pickupName = createSelector(
    (_, props) => props.child && props.child.pickupRelationId,
    relations,
    (pickupRelationId, relations): typeof Name.default | null => {
        const pickupPerson = relations.find(relation => relation.relationId === pickupRelationId);
        return pickupPerson ? pickupPerson.name : null;
    },
);

export const pickupFullName = createSelector(
    pickupName,
    isPinApp,
    PinSelectors.goHomeWithChild,
    (name, pinApp, goHomeWithChild) => {
        if (pinApp && goHomeWithChild) {
            return goHomeWithChild.name.fullName;
        } else if (pinApp && name) {
            return name.firstName;
        } else {
            return name?.fullName ?? null;
        }
    },
);

export const pickupImage = createSelector(
    (_, props) => props.child && props.child.pickupRelationId,
    relations,
    (pickupRelationId, relations) => {
        const pickupPerson = relations.find(relation => relation.relationId === pickupRelationId);
        return pickupPerson?.image.small;
    },
);

export const pickupTime = createSelector(
    (_, props) => props.child,
    GroupsSelectors.timezone,
    (child, timezone) => {
        if (hasValue(child) && hasValue(child.pickupTime)) {
            return moment.tz(child.pickupTime, timezone).format('LT');
        }
        return null;
    },
);

export const pickupFirstName = createSelector<RootState, PickupNameProps, typeof Name.default | null, string>(
    pickupName,
    name => (name ? name.firstName : ''),
);

export const canSwitchRoom = createSelector(
    GroupsSelectors.institutionFeatures,
    isPinApp,
    (features, isPinApp) => !isPinApp && features.includes(SignInAppBehaviorIds.childrenSwitchRoom),
);

export const limitedData = createSelector(GroupsSelectors.institutionFeatures, features =>
    features.includes(SignInAppBehaviorIds.limitedData),
);
