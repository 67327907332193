import type {IconProps} from 'modern-famly';

import {LeaveType} from 'web-app/react/leave-types/leave-type';
import {exhaustiveCheck} from 'web-app/util/typescript';

export const getLeaveTypeIconProps = (leaveType: LeaveType, isDisabled?: boolean): IconProps | undefined => {
    switch (leaveType) {
        case LeaveType.Sick:
            return {
                name: 'device_thermostat',
                color: isDisabled ? 'r200' : 'r400',
                filled: true,
            };
        case LeaveType.ChildSick:
            return {
                name: 'stethoscope',
                color: isDisabled ? 'r200' : 'r400',
                filled: true,
            };
        case LeaveType.Vacation:
            return {
                name: 'clear_day',
                color: isDisabled ? 'y200' : 'y400',
                filled: true,
            };
        case LeaveType.Absent:
            return {
                name: 'event_busy',
                color: isDisabled ? 'n100' : 'n300',
                filled: true,
            };
        default:
            exhaustiveCheck(leaveType);
            return undefined;
    }
};
