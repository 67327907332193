import {List} from 'immutable';

import createSelector from 'web-app/util/custom-create-selector';
import {type RootState} from 'signin-app/redux/main-reducer';
import ChildrenEntity from 'signin-app/entities/children';
import StaffEntity from 'signin-app/entities/staff';
import SignInAppBehaviorIds from 'web-app/behaviors/signin-app-behavior-ids';

import {type Group, type Institution} from './reducer';

export const institution = (state: RootState): Institution | undefined => state.institutionOverview.institution;
export const groups = (state: RootState): List<Group> => List(state.institutionOverview.groups);
export const openingHours = (state: RootState) => state.institutionOverview.openingHours;
export const institutionFeatures = (state: RootState): string[] => state.institutionOverview.features;
export const employeeEstimatedCheckoutTimeEnabled = (state: RootState): boolean =>
    state.institutionOverview.employeeEstimatedCheckoutTimeEnabled;

export const hiddenGroupIds = (state: RootState) => state.institutionOverview.hiddenGroupIds;

export const displayedChildGroups = createSelector(groups, hiddenGroupIds, (childGroups, hiddenGroupIds) => {
    const groupsWithChildren = childGroups.filter(group => group.noChildren > 0);

    if (hiddenGroupIds.size === 0) {
        return groupsWithChildren;
    }

    return groupsWithChildren.filter(group => !hiddenGroupIds.includes(group.groupId));
});

export const timezone = createSelector(institution, institutionObject =>
    institutionObject ? institutionObject.timezone : '',
);

export const didLoadInstitution = createSelector(institution, institutionObject => Boolean(institutionObject));

export const institutionTitle = createSelector(
    institution,
    institutionObject => institutionObject && institutionObject.title,
);

export const getGroupById = createSelector(
    groups,
    (_: any, props: {id: string}) => props.id,
    (allGroups, groupId) => allGroups.find(group => group.groupId === groupId),
);

export const getGroupName = createSelector(getGroupById, group => group && group.title);

export const institutionId = createSelector(
    institution,
    institutionObject => institutionObject && institutionObject.institutionId,
);

export const institutionLocale = createSelector<RootState, Institution | undefined, string>(
    institution,
    institutionObject => (institutionObject ? institutionObject.locale : ''),
);

export const staffGroups = createSelector(StaffEntity.selectors.entityMap, staffEntityMap => staffEntityMap.toList());

export const displayedStaffGroups = createSelector(staffGroups, hiddenGroupIds, (staffGroups, hiddenGroupIds) => {
    const groupsWithStaff = staffGroups.filter(group => group.employees.size > 0);

    if (hiddenGroupIds.size === 0) {
        return groupsWithStaff;
    }

    return staffGroups.filter(group => !hiddenGroupIds.includes(group.groupId));
});

interface CheckedInCounts {
    [groupId: string]: number;
}

export const checkedInCounts = createSelector(
    ChildrenEntity.selectors.entityMap,
    StaffEntity.selectors.entityMap,
    (childrenEntityMap, staffEntityMap): CheckedInCounts => {
        const childrenCounts = childrenEntityMap.reduce((acc, child) => {
            if (child.checkedIn) {
                return {
                    ...acc,
                    [child.groupId]: acc[child.groupId] ? acc[child.groupId] + 1 : 1,
                };
            }
            return acc;
        }, {} as CheckedInCounts);

        const staffCounts = staffEntityMap.reduce((acc, staffGroup) => {
            const staffGroupCount = staffGroup.employees.reduce((acc, employee) => {
                return employee.checkedIn ? acc + 1 : acc;
            }, 0);

            return {
                ...acc,
                [staffGroup.groupId]: staffGroupCount,
            };
        }, {} as CheckedInCounts);

        return {...childrenCounts, ...staffCounts};
    },
);

export const canSwitchRoom = createSelector(groups, groupList => groupList.size > 1);

export const enableEmployeeCheckin = createSelector(institutionFeatures, features =>
    features.includes(SignInAppBehaviorIds.enableEmployeeCheckin),
);
