import {Box} from 'modern-famly';
import React, {type ComponentType, type ReactNode} from 'react';
import styled from 'styled-components';

import ListItem from 'web-app/react/components/lists/list-item/list-item';
import {Body, Caption} from 'web-app/react/components/text/text';
import {iOSKeyboardFix} from 'web-app/util/layout';

const StyledBody = styled(Body)`
    white-space: pre-line;
`;

interface DisabledStateProps {
    onClick?: () => void;
    value?: ReactNode | string;
    placeholder?: string;
    description?: string;
    disabled?: boolean;
    className?: string;
    renderValue?: (s: string) => React.ReactNode;
    renderPlaceholder?: (s: string) => React.ReactNode;
}

const DisabledState: React.FC<React.PropsWithChildren<DisabledStateProps>> = props => {
    const {value, placeholder, disabled, description, onClick, renderValue, renderPlaceholder, ...rest} = props;

    return (
        <ListItem onClick={!disabled ? onClick : undefined} noBorder={true} {...rest}>
            <Box>
                {!value && placeholder && renderPlaceholder ? renderPlaceholder(placeholder) : null}
                {value && typeof value === 'string' && renderValue ? renderValue(value) : null}
                {!renderValue && !renderPlaceholder ? (
                    <StyledBody secondary={!value}>{value || placeholder}</StyledBody>
                ) : null}
                {description ? <Caption disabled>{description}</Caption> : null}
            </Box>
        </ListItem>
    );
};

type EventFunc = (event: any) => any;

export interface TogglableInputFieldProps {
    disabled?: boolean;
    className?: string;
    value?: ReactNode;
    enabledComponent: ComponentType<React.PropsWithChildren<any>>;
    onKeyDown?: EventFunc;
    onBlur?: EventFunc;
    onChange?: EventFunc;
    placeholder?: string;
    renderValue?: (s: string) => React.ReactNode;
    renderPlaceholder?: (s: string) => React.ReactNode;
}

class TogglableInputField extends React.Component<TogglableInputFieldProps> {
    public state = {
        enabled: false,
    };

    constructor(props) {
        super(props);

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.enable = this.enable.bind(this);
        this.disable = this.disable.bind(this);
    }

    public render() {
        const {disabled, enabledComponent, className, renderValue, renderPlaceholder, ...rest} = this.props;

        const inputProps = {
            ...rest,
        };

        if (this.state.enabled) {
            return React.createElement(enabledComponent, {
                ...inputProps,
                onKeyDown: this.handleKeyDown,
                onBlur: this.disable,
                autoFocus: true,
            });
        } else {
            return (
                <DisabledState
                    {...inputProps}
                    className={className}
                    onClick={this.enable}
                    disabled={disabled}
                    renderValue={renderValue}
                    renderPlaceholder={renderPlaceholder}
                />
            );
        }
    }

    private handleKeyDown(e) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }

        if ((e.ctrlKey || e.metaKey) && e.keyCode === 13) {
            this.disable(e);
        }
    }

    private disable(e) {
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
        this.setState({enabled: false});
        iOSKeyboardFix();
    }

    private enable() {
        if (!this.props.disabled) {
            this.setState({enabled: true});
        }
    }
}

export default TogglableInputField;
